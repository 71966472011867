import filterApi from "@/api/filter";
import { UNINIT } from "../../sync-states";
import { filter as schema } from "@/store/schema";
import { normalize } from "normalizr";
import Vue from "vue";

const route = "api.filters.index";
const name = "filter";

// state
export const state = {
  filter: {},
  filterSync: UNINIT,
  filterIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchFilterDetails(context, { id }) {
    const { entities, result } = await filterApi.fetch(id);

    context.commit("updateEntities", {
      entities: entities,
      reactiveName: name,
      reactiveId: result
    });

    return result;
  },
  async fetchFilter(context, { id, includes, filters, cb }) {
    return await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes,
      filters,
      cb
    });
  },
  async updateFilter(context, { filter, includes, cb }) {
    return await context.dispatch("update", {
      route,
      name,
      schema,
      entity: filter,
      includes,
      cb
    });
  },
  async storeFilter(context, { filter, includes, cb }) {
    return await context.dispatch("store", {
      route,
      name,
      schema,
      entity: filter,
      includes,
      cb
    });
  },
  async destroyFilter(context, { id, cb }) {
    await context.dispatch("destroy", { route, name, id, cb });
  },
  async updateFilterSortOrder(context, { filters }) {
    const url =
      "/" +
      window.Ziggy.namedRoutes[
        "api.filters.filtersSortOrderUpdate"
      ].uri.replace("{filter}");
    try {
      const { data } = await Vue.$http.post(url, { filters });
      let normalizedData = normalize(data.data, [schema]);
      context.commit("updateEntities", { entities: normalizedData.entities });
      return normalizedData.result;
    } catch (error) {
      console.error(error);
    }
  }
};

// const detailIncludes = [
//   "company.deliveryMethods",
//   "deliveryMethods",
//   "filters.company",
//   "languages",
//   "filterCategories.category",
//   "filterCategories.specifiers.options",
//   "country",
//   "provinces",
//   "cities",
//   "includedPolygons",
//   "excludedPolygons",
//   "includedCircles",
//   "excludedCircles"
// ];
