export default [
  {
    path: "/refinement-rules",
    name: "refinement-rules",
    component: () =>
      import(
        /* webpackChunkName: "refinement-rules" */ "@/views/refinement-rules/RefinementRulesView.vue"
      ),
    meta: {
      title: "Refinement-rules"
    },
    children: [
      {
        path: "create",
        name: "refinement-rules.create",
        component: () =>
          import(
            /* webpackChunkName: "refinement-rules" */ "@/views/refinement-rules/RefinementRuleCreateView.vue"
          ),
        meta: {
          title: "Refinement-rules"
        }
      },
      {
        path: ":refinementRuleId",
        component: () =>
          import(
            /* webpackChunkName: "refinement-rules" */ "@/views/refinement-rules/RefinementRuleView.vue"
          ),
        meta: {
          title: "Refinement-rules"
        },
        children: [
          {
            path: "",
            name: "refinement-rules.show",
            props: route => ({ id: parseInt(route.params.refinementRuleId) }),
            component: () =>
              import(
                /* webpackChunkName: "refinement-rules" */ "@/views/refinement-rules/RefinementRuleDashboardView.vue"
              ),
            meta: {
              title: "Refinement-rules"
            }
          },
          {
            path: "applied",
            name: "refinement-rules.applied",
            component: () =>
              import(
                /* webpackChunkName: "refinement-rules" */ "@/views/refinement-rules/RefinementRuleAppliedView.vue"
              ),
            meta: {
              title: "Refinement-rules"
            }
          }
        ]
      }
    ]
  }
];
