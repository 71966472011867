import Vue from "vue";

async function fetch(q) {
  const { data } = await Vue.$http.get("/api/v1/geocode", {
    params: { q, includeUnacceptable: true }
  });
  return data.data;
}

export default {
  fetch
};
