import { isNil } from "lodash";

if (isNil(window.config)) {
  window.config = {};
}

window.config.google = {
  apiKey: process.env.VUE_APP_GOOGLE_API_KEY
};

if (isNil(window.config.enums)) {
  window.config.enums = {};
}

window.config.enums.offers = {
  status: {
    CLAIMED: "Claimed",
    ACCEPTED: "Accepted",
    DISPUTED: "Disputed",
    REJECTED: "Rejected"
  }
};
window.config.enums.submissionRuleValidationTypes = {};
window.config.enums.validationRules = {
  actions: {
    REJECT: "Reject",
    VERIFY: "Flag for manual verification"
  }
};
window.config.enums.submission_triggers = {
  types: {
    ALWAYS: "Always",
    INCLUDES: "Includes",
    EXACT_MATCH: "Matches exactly",
    STARTS_WITH: "Starts with",
    ENDS_WITH: "Ends with",
    CHAR_COUNT: "Character count",
    CONTAINS: "Contains",
    IN_DB: "Already in database"
  },
  targets: {
    DESCRIPTION: "Description",
    EMAIL: "Email",
    EMAIL_SUBSECTOR: "Email & Subsector (IN DB only!)",
    EMAIL_SUBSECTOR_LAST_2_MONTHS:
      "Email & Subsector in last 2 months (IN DB only!)",
    TELEPHONE: "Telephone",
    TELEPHONE_LAST_2_MONTHS: "Telephone in last 2 months (IN DB only!)",
    ADDRESS_STREET: "Address - Street",
    ADDRESS_STREET_NUMBER: "Address - Street Number",
    ADDRESS_BOX: "Address - Box",
    ADDRESS_POSTAL_CODE: "Address - Postal Code",
    ADDRESS_CITY: "Address - City",
    NAME_FIRST: "Name - First",
    NAME_LAST: "Name - Last"
  },
  specifiers: {
    CHAR_COUNT: {
      EQ: "Equals",
      GT: "Greater than",
      LT: "Lower than",
      GTEQ: "Greater than or equals",
      LTEQ: "Lower than or equals"
    },
    CONTAINS: {
      NUMBERS: "Numbers",
      LETTERS: "Letters",
      ONLY_NUMBERS: "Only numbers",
      ONLY_LETTERS: "Only letters"
    }
  }
};
window.config.enums.submissionRuleRefinementTypes = {
  REPLACE: "Replace",
  TRIM: "Trim",
  LOWERCASE: "lower case",
  UPPERCASE: "UPPERCASE",
  CAPITALIZE: "Capitalize",
  TITLECASE: "Title Case"
};
window.config.enums.collection_question_types = {
  INPUT_TEXT: "Text",
  TEXTAREA: "Textarea",
  SELECT: "Dropdown",
  INPUT_RADIO: "Radio",
  INPUT_CHECKBOX: "Checkbox",
  INPUT_EMAIL: "Email",
  CUSTOM_NAME: "Name",
  CUSTOM_ADDRESS: "Address",
  CUSTOM_TELEPHONE: "Telephone"
};
window.config.enums.context_classes = {
  SUCCESS: "Success",
  INFO: "Info",
  WARNING: "Warning",
  DANGER: "Danger"
};
window.config.enums.company_status = {
  NOT_CONTACTED: "Not contacted",
  NEGOTIATING: "Negotiating",
  TRIAL: "Trial",
  PARTNER: "Partner",
  NOT_INTERESTED: "Not interested",
  REALLY_NOT_INTERESTED: "REALLY not interested"
};
window.config.enums.company_states = {
  UNAFFILIATED: "Unaffiliated",
  AFFILIATED: "Affiliated",
  PAUSED: "Paused",
  BLACKLISTED: "Blacklisted",
  EX_AFFILIATED: "Ex Affiliated"
};
