import { FAILURE, IN_SYNC, UNINIT, UPDATING } from "../../sync-states";
import { keyword as schema } from "@/store/schema";
import { createUrlFromRoute } from "@/store/url";
import { normalize } from "normalizr";
import Vue from "vue";
import api from "@/api";
import { forOwn } from "lodash";
import { createApiUrl } from "@/api/url";

// state
export const state = {
  keyword: {},
  keywordSync: UNINIT,
  keywordIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchKeyword(context, { id, includes, filters, joins, cb }) {
    return await context.dispatch("fetch", {
      route: "api.keywords.index",
      name: "keyword",
      schema,
      id,
      includes,
      filters,
      joins,
      cb
    });
  },
  async fetchKeywordRight(
    context,
    { id, includes, filters, joins, pagination }
  ) {
    const { data } = await Vue.$http.get(
      createUrlFromRoute(
        "api.keywords-right.index",
        id,
        includes,
        filters,
        pagination,
        joins
      )
    );
    return data.data;
  },
  async updateKeyword(context, { entity, includes, cb }) {
    const result = context.dispatch("update", {
      route: "api.keywords.index",
      name: "keyword",
      schema,
      entity,
      includes,
      cb
    });
    context.dispatch(
      "snackbar/addItem",
      { text: "successfully updated keyword", color: "success" },
      { root: true }
    );
    return result;
  },
  // async storeKeyword(context, { entity, includes, cb }) {
  //     return await context.dispatch('store', { route: 'api.keywords.index', name: 'keyword', schema, entity, includes, cb });
  // },
  async storeKeywords(context, { keywords }) {
    const url = "/" + window.Ziggy.namedRoutes["api.keywords.index"].uri;
    try {
      const { data } = await Vue.$http.post(url, { keywords });
      context.dispatch(
        "snackbar/addItem",
        { text: "successfully created keywords", color: "success" },
        { root: true }
      );
      return data;
    } catch (errors) {
      errors.errors.Keyword.forEach(e => {
        context.dispatch(
          "snackbar/addItem",
          { text: e, color: "error" },
          { root: true }
        );
      });
      console.error(errors);
    }
  },
  async destroyKeyword(context, { id, cb }) {
    await context.dispatch("destroy", {
      route: "api.keywords.index",
      name: "keyword",
      id,
      cb
    });
  },
  async addRemarkToKeyword(context, { id, remark }) {
    const url =
      "/" +
      window.Ziggy.namedRoutes["api.content-plans.remark"].uri.replace(
        "{contentPlan}",
        id
      );
    try {
      context.commit("setSync", { name: "keyword", id, sync: UPDATING });
      const { data } = await Vue.$http.put(url, { remark });
      let normalizedData = normalize(data.data, schema);
      context.commit("updateEntities", { entities: normalizedData.entities });
      context.commit("setSync", { name: "keyword", id, sync: IN_SYNC });
      return normalizedData.result;
    } catch (error) {
      context.commit("setSync", { name: "keyword", id, sync: FAILURE });
      console.error(error);
    }
  },
  async getSearchResult(context, { searchQuery, locale }) {
    const url = createApiUrl(`api/v1/content-plans/${locale}?q=${searchQuery}`);
    try {
      const { data } = await Vue.$http.get(url);
      let normalizedData = normalize(data.data, [schema]);
      const keywordIds = [];
      const keywordClusterIds = [];

      forOwn(normalizedData.entities.keywordCluster, keywordCluster => {
        keywordClusterIds.push(keywordCluster.id);
      });
      forOwn(normalizedData.entities.keyword, keyword => {
        keywordIds.push(keyword.id);
      });
      context.commit("updateEntities", { entities: normalizedData.entities });
      return {
        keywordIds,
        keywordClusterIds
      };
    } catch (error) {
      console.error(error);
    }
  },

  async detachKeyword({ state, commit }, { keywordId }) {
    const keywordClusterId = state.keyword[keywordId].keywordClusterId;
    const { entities, result } = await api.keyword.detach(keywordId);
    commit("updateEntities", { entities });
    commit("detachKeywordClusterKeyword", { keywordClusterId, keywordId });
    return result;
  }
};
