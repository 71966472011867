import userNotificationsModule from "./user-notifications";
import layoutModule from "./layout";
import notificationsModule from "./notifications";
import snackbarModule from "./snackbar";
import localesModule from "./locales";
import subjectsModule from "./subjects";
import incidentsModule from "./incidents";
import {
  actions as entityActions,
  getters as entityGetters,
  mutations as entityMutations,
  state as entityState
} from "./entities";
import store from "@/store";

const modules = {
  "user-notifications": userNotificationsModule,
  layout: layoutModule,
  notifications: notificationsModule,
  snackbar: snackbarModule,
  locales: localesModule,
  subjects: subjectsModule,
  incidents: incidentsModule,
  entities: {
    //@TODO get rid of this fucker
    namespaced: true,
    state: entityState,
    getters: entityGetters,
    actions: entityActions,
    mutations: entityMutations
  }
};

Object.entries(modules).forEach(([moduleName, module]) => {
  store.registerModule(moduleName, module);
});

localesModule.init(store);

console.debug(`[VUEX] Modules loaded: ${Object.keys(modules).join(", ")}`);
