import Vue from "vue";
import { form as schema, normalize as internalNormalize } from "@/store/schema";
import { createApiUrl } from "../url";
import annotation from "./annotation";

const resourceUrl = createApiUrl("api/v1/backoffice/collection/forms");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function publish(formId, form) {
  const resourcePublishUrl = createApiUrl("api/v1/backoffice/collection/form");
  await Vue.$http.put(`${resourcePublishUrl}/${formId}/publish`, form);
}

async function create(form) {
  const { data } = await Vue.$http.post(resourceUrl, form);
  return normalize(data.data, schema);
}

async function update(formId, form) {
  const { data } = await Vue.$http.put(`${resourceUrl}/${formId}`, form);
  return normalize(data.data, schema);
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = ["locales"];
const fetchAllIncludes = [];

export default {
  normalize,
  fetch,
  fetchAll,
  create,
  update,
  publish,
  annotation
};
