export default [
  {
    path: "/backoffice/buddies",
    name: "buddies",
    component: () =>
      import(/* webpackChunkName: "buddies" */ "@/views/buddies/BuddiesView"),
    meta: {
      title: "Buddies"
    }
  }
];
