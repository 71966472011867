import authRoutes from "./auth/routes";
import dashboardRoutes from "./dashboard/routes";
import distributionRoutes from "./distribution/routes";
import submissionRoutes from "@/views/submissions/routes";
import errorRoutes from "./errors/routes";
import tagRoutes from "./tags/routes";
import tagSubTypesRoutes from "./tag-sub-types/routes";
import refinementRuleRoutes from "./refinement-rules/routes";
import validationRuleRoutes from "./validation-rules/routes";
import separationRuleRoutes from "./separation-rules/routes";
import deliveryRuleRoutes from "./delivery-rules/routes";
import rejectReasonRoutes from "./reject-reason/routes";
import questionRoutes from "./question/routes";
import annotationRoutes from "./annotations/routes";
import articleRoutes from "./articles/routes";
import employeeRoutes from "./employees/routes";
import roleRoutes from "./roles/routes";
import permissionRoutes from "./permissions/routes";
import formRoutes from "./forms/routes";
import subjectRoutes from "./subjects/routes";
import contactMessageRoutes from "./contact-messages/routes";
import keywordRoutes from "./keywords/routes";
import contentPlanRoutes from "./content-plans/routes";
import websiteRoutes from "./websites/routes";
import localizationRoutes from "./localizations/routes";
import buddyRoutes from "./buddies/routes";
import largeLocalizationRoutes from "./large-localizations/routes";
import companyRoutes from "./companies/routes";
import telephoneNumberRoutes from "./telephone-numbers/routes";
import annotationSubTypesRoutes from "./annotationSubTypes/routes";
import annotationTypeRoutes from "./annotationTypes/routes";
import offerRejectionReasonRoutes from "./offer-rejection-reasons/routes";
import categoryRoutes from "./categories/routes";
import notificationsRoutes from "./notifications/routes";
import subscriptionsRoutes from "./subscriptions/routes";
import statisticsRoutes from "./statistics/routes";
import invoicesRoutes from "./invoices/routes";
import developmentRoutes from "./development/routes";

export default [
  { path: "/", redirect: "/backoffice/dashboard" },
  { path: "/backoffice/", redirect: "/backoffice/dashboard" },
  ...authRoutes,
  ...dashboardRoutes,
  ...submissionRoutes,
  ...categoryRoutes,
  ...annotationSubTypesRoutes,
  ...companyRoutes,
  ...largeLocalizationRoutes,
  ...buddyRoutes,
  ...buddyRoutes,
  ...keywordRoutes,
  ...contentPlanRoutes,
  ...websiteRoutes,
  ...localizationRoutes,
  ...contactMessageRoutes,
  ...formRoutes,
  ...roleRoutes,
  ...permissionRoutes,
  ...employeeRoutes,
  ...articleRoutes,
  ...tagRoutes,
  ...tagSubTypesRoutes,
  ...refinementRuleRoutes,
  ...validationRuleRoutes,
  ...separationRuleRoutes,
  ...deliveryRuleRoutes,
  ...rejectReasonRoutes,
  ...distributionRoutes,
  ...questionRoutes,
  ...annotationRoutes,
  ...annotationTypeRoutes,
  ...subjectRoutes,
  ...telephoneNumberRoutes,
  ...offerRejectionReasonRoutes,
  ...notificationsRoutes,
  ...subscriptionsRoutes,
  ...statisticsRoutes,
  ...invoicesRoutes,
  ...(["production", "staging"].includes(process.env.NODE_ENV)
    ? []
    : developmentRoutes),
  //Always keep errors at the bottom
  ...errorRoutes
];
