<template>
  <div>
    <button
      @click="expanded = !expanded"
      :class="[
        ...[
          'tw-mt-1',
          'tw-group',
          'tw-w-full',
          'tw-flex',
          'tw-items-center',
          'tw-pl-2',
          'tw-pr-1',
          'tw-py-2',
          'tw-text-sm',
          'tw-leading-5',
          'tw-font-medium',
          'tw-rounded-md',
          'focus:tw-outline-none',
          'tw-transition',
          'tw-ease-in-out',
          'tw-duration-150'
        ],
        ...textClass,
        ...backgroundClass
      ]"
    >
      <!-- Heroicon name: folder -->
      <font-awesome-icon
        :icon="group.icon"
        class="tw-mr-3 tw-h-6 tw-w-6 tw-text-gray-400 group-hover:tw-text-gray-700 group-focus:tw-text-gray-700 tw-transition tw-ease-in-out tw-duration-150 tw-text-xl"
        fixed-width
      />
      {{ group.name }}
      <!-- Expanded: "tw-text-gray-400 tw-rotate-90", Collapsed: "tw-text-gray-300" -->
      <svg
        class="tw-ml-auto tw-h-5 tw-w-5 tw-transform group-hover:tw-text-gray-700 group-focus:tw-text-gray-700 tw-transition-colors tw-ease-in-out tw-duration-150"
        :class="expanded ? 'tw-text-gray-500 tw-rotate-90' : 'tw-text-gray-400'"
        viewBox="0 0 20 20"
      >
        <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
      </svg>
    </button>
    <!-- Expandable link section, show/hide based on state. -->
    <div class="tw-mt-1 tw-space-y-1" v-show="expanded">
      <router-link
        v-for="(item, index) in group.items"
        :key="index"
        :to="item.to"
        :class="[
          ...[
            'tw-group',
            'tw-w-full',
            'tw-flex',
            'tw-items-center',
            'tw-pl-11',
            'tw-pr-2',
            'tw-py-2',
            'tw-text-sm',
            'tw-leading-5',
            'tw-font-medium',
            'tw-rounded-md',
            'focus:tw-outline-none',
            'tw-transition',
            'tw-ease-in-out',
            'tw-duration-150'
          ],
          ...textClass,
          ...backgroundClass
        ]"
      >
        {{ item.name }}
      </router-link>
    </div>
  </div>
</template>

<script type="text/babel">
export default {
  name: "sidebar-group",
  props: {
    group: {
      type: Object,
      required: true
    },
    textClass: {
      type: String,
      default:
        "tw-text-gray-600 hover:tw-text-gray-900 active:tw-text-gray-900 focus:tw-text-gray-900"
    },
    backgroundClass: {
      type: String,
      default: "hover:tw-bg-gray-50 active:tw-bg-gray-50 focus:tw-bg-gray-200"
    }
  },
  data: () => ({
    expanded: false
  })
};
</script>
