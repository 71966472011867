import Vue from "vue";
import { normalize } from "normalizr";
import { UNINIT } from "@/store/sync-states";
import { filter as filterSchema, lead as schema } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/leads");
const route = "api.leads.index";
const name = "lead";

// state
export const state = {
  lead: {},
  leadSync: UNINIT,
  leadIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchLead(context, { id, includes, cb }) {
    await context.dispatch("fetch", { route, name, schema, id, includes, cb });
  },
  async updateLead(context, { entity, includes, cb }) {
    await context.dispatch("update", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async storeLead(context, { entity, includes, cb }) {
    await context.dispatch("store", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async destroyLead(context, { id, cb }) {
    await context.dispatch("destroy", { route, name, id, cb });
  },
  async fetchLeadMatchSuggestions(context, { id }) {
    const url = `${resourceUrl}/${id}/filters/suggest`;
    const { data } = await Vue.$http.get(url);

    const bulditPriorityFilters = normalize(data.bulditPriority, [
      filterSchema
    ]);
    const partnerFilters = normalize(data.partner, [filterSchema]);
    const resellerFilters = normalize(data.reseller, [filterSchema]);
    const bulditFilters = normalize(data.buldit, [filterSchema]);

    context.commit("updateEntities", {
      entities: bulditPriorityFilters.entities
    });
    context.commit("updateEntities", { entities: partnerFilters.entities });
    context.commit("updateEntities", { entities: resellerFilters.entities });
    context.commit("updateEntities", { entities: bulditFilters.entities });

    return {
      bulditPriority: bulditPriorityFilters.result,
      partner: partnerFilters.result,
      reseller: resellerFilters.result,
      buldit: bulditFilters.result,
      priceMap: data.priceMap
    };
  }
};
