export default [
  {
    path: "/backoffice/separation-rules",
    name: "separation-rules",
    component: () =>
      import(
        /* webpackChunkName: "separation-rules" */ "@/views/separation-rules/SeparationRulesView"
      ),
    meta: {
      title: "Separation-rules"
    }
  },
  {
    path: "/backoffice/separation-rules/create",
    name: "separation-rules.create",
    component: () =>
      import(
        /* webpackChunkName: "separation-rules" */ "@/views/separation-rules/SeparationRuleCreateView"
      ),
    meta: {
      title: "Separation-rules"
    }
  },
  {
    path: "/backoffice/separation-rules/:separationRuleId",
    name: "separation-rules.show",
    component: () =>
      import(
        /* webpackChunkName: "separation-rules" */ "@/views/separation-rules/SeparationRuleView"
      ),
    props: route => ({
      separationRuleId: parseInt(route.params.separationRuleId)
    }),
    meta: {
      title: "Separation-rules"
    }
  }
];
