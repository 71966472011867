import Vue from "vue";
import { articleCluster as schema, normalize } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/articles");

async function fetchAll(websiteId, { filters, pagination } = {}) {
  filters.website = websiteId;
  filters.hasCluster = 0;
  const url = createApiUrl(resourceUrl, {
    filters,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

export default {
  fetchAll
};
