export default [
  {
    path: "/backoffice/reject-reasons",
    name: "reject-reasons",
    component: () =>
      import(
        /* webpackChunkName: "reject-reason" */ "@/views/reject-reason/RejectReasonsView"
      ),
    meta: {
      title: "Reject-reason"
    }
  },
  {
    path: "/backoffice/reject-reasons/create",
    name: "reject-reasons.create",
    component: () =>
      import(
        /* webpackChunkName: "reject-reason" */ "@/views/reject-reason/RejectReasonCreateView"
      ),
    meta: {
      title: "Reject-reason"
    }
  },
  {
    path: "/backoffice/reject-reasons/:rejectReasonId",
    name: "reject-reasons.show",
    component: () =>
      import(
        /* webpackChunkName: "reject-reason" */ "@/views/reject-reason/RejectReasonView"
      ),
    props: route => ({
      rejectReasonId: parseInt(route.params.rejectReasonId)
    }),
    meta: {
      title: "Reject-reason"
    }
  }
];
