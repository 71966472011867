import Vue from "vue";

export default {
  addItem: function(state, { text, color }) {
    state.items.push({
      text,
      color
    });
  },
  removeCurrentItem(state) {
    Vue.set(state, "items", state.items.splice(1));
  }
};
