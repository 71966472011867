import { isArray, isNil } from "lodash";
import {
  FILTERS,
  FIND_BY_ID,
  FIND_BY_IDS,
  HAS_ACTIVE_FILTERS,
  IDS,
  IS_ERROR,
  IS_LOADING,
  ITEM,
  ITEMS,
  PAGINATION
} from "./getter-types";

export default {
  [IS_LOADING]: state => state.isLoading,
  [IS_ERROR]: state => state.isError,
  [IDS]: state => state.ids,
  [ITEMS]: state => state.ids.map(id => state.entity[id]),
  [ITEM]: state => id => state.entity[id],
  [FIND_BY_ID]: state => id => state.entity[id],
  [FIND_BY_IDS]: state => ids => (ids || []).map(id => state.entity[id]),
  [FILTERS]: state => state.filters,
  [HAS_ACTIVE_FILTERS]: state =>
    Object.values(state.filters).filter(value =>
      isArray(value) ? value.length > 0 : !isNil(value)
    ).length > 0,
  [PAGINATION]: state => state.pagination
};
