export default [
  {
    path: "/backoffice/sub-type-tags",
    name: "tag-sub-types",
    component: () =>
      import(
        /* webpackChunkName: "tag-sub-types" */ "@/views/tag-sub-types/TagSubTypesView"
      )
  },
  {
    path: "/backoffice/sub-type-tags/create",
    name: "tag-sub-types.create",
    component: () =>
      import(
        /* webpackChunkName: "tag-sub-types" */ "@/views/tag-sub-types/TagSubTypeCreateView"
      )
  },
  {
    path: "/backoffice/sub-type-tags/:tagSubTypeId",
    name: "tag-sub-types.show",
    component: () =>
      import(
        /* webpackChunkName: "tag-sub-types" */ "@/views/tag-sub-types/TagSubTypeView"
      ),
    props: route => ({
      id: parseInt(route.params.tagSubTypeId)
    })
  }
];
