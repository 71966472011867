import Vue from "vue";
import { normalize } from "normalizr";
import { filter as schema } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const leadResourceUrl = createApiUrl("api/v1/leads");

async function find(leadId, filters) {
  const url = createApiUrl(`${leadResourceUrl}/${leadId}/filters`, {
    filters,
    includes: searchIncludes
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, [schema]);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

const searchIncludes = ["company", "filters.company"];

export default {
  find
};
