import { isArray, isNil, isPlainObject } from "lodash";
import { isChanged as isChangedExtracted } from "./change";
import store from "@/store";
import { FILTERS } from "@/store/templates/table/getter-types";
import { SET_FILTER } from "@/store/templates/table/mutation-types";

/**
 * Helper method that compares two state objects to see if they have changed.
 *
 * @param state
 * @param initialState
 * @returns {boolean}
 */
export const isChanged = isChangedExtracted;

export const createEntityPlaceholders = entityNames => {
  const entities = { entity: {} };
  if (!isArray(entityNames)) {
    entityNames = [entityNames];
  }
  entityNames.forEach(entityName => {
    entities[entityName] = {};
  });
  return entities;
};

export const createSelectEntityPlaceholders = entityNames => {
  return { entities: createEntityPlaceholders(entityNames) };
};

/**
 * This method takes a namespace and created a version of mapComputed and mapComputedFilters where the namespace need
 * not be defined.
 * Based on Vuex's createNamespaceHelpers method.
 *
 * @param namespace
 * @returns {mapComputed, mapComputedFilters}
 */
export const createNamespacedHelpers = namespace => ({
  mapComputed: mapComputed.bind(null, namespace),
  mapComputedFilters: mapComputedFilters.bind(null, namespace)
});

/**
 * This method takes a namespace and properties, either as a single string, an array of string or an object for
 * further control.
 * Based on vuex's mapping helpers.
 *
 * @param namespace
 * @param properties
 */
export const mapComputed = (namespace, properties) => {
  const propertyStateMappings = createPropertyStateMappings(properties);
  const computed = {};

  propertyStateMappings.forEach(([computedProperty, vuexProperty]) => {
    const capitalizedVuexProperty =
      vuexProperty.charAt(0).toUpperCase() + vuexProperty.slice(1);
    const namespacedGetter = `${namespace}/${vuexProperty}`;
    const namespacedSetter = `${namespace}/set${capitalizedVuexProperty}`;
    computed[computedProperty] = {
      get: () => {
        return store.getters[namespacedGetter];
      },
      set: value => {
        if (!isNil(store._actions[namespacedSetter])) {
          store.dispatch(namespacedSetter, value);
        } else {
          store.commit(namespacedSetter, value);
        }
      }
    };
  });

  return computed;
};

/**
 * Much like the plain mapComputed, this method takes a namespace and properties, either as a single string, an array
 * of string or an object for further control. However this method should only be used for created computed properties
 * for a filter.
 * Based on vuex's mapping helpers.
 *
 * @param namespace
 * @param properties
 */
export const mapComputedFilters = (namespace, properties) => {
  const propertyStateMappings = createPropertyStateMappings(properties);
  const computedFilters = {};
  propertyStateMappings.forEach(([computedProperty, vuexProperty]) => {
    computedFilters[computedProperty] = {
      get() {
        return store.getters[`${namespace}/${FILTERS}`][vuexProperty];
      },
      set(value) {
        store.commit(`${namespace}/${SET_FILTER}`, {
          prop: vuexProperty,
          value
        });
      }
    };
  });
  return computedFilters;
};

function createPropertyStateMappings(properties) {
  if (isPlainObject(properties)) {
    // If properties is an object, split it into entries property / state entries.
    return Object.entries(properties);
  } else if (isArray(properties)) {
    return properties.map(property => [property, property]);
  } else if (!isArray(properties)) {
    return [properties].map(property => [property, property]);
  }
}
