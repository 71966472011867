import Vue from "vue";
import {
  creditNote as schema,
  normalize as internalNormalize
} from "@/store/schema";
import { createApiUrl } from "../url";

const fileNameRegex = /attachment; filename=([^.]*).(\w*)/;
const resourceUrl = createApiUrl("api/v1/invoices");

async function fetch(invoiceId, id) {
  const url = createApiUrl(`${resourceUrl}/${invoiceId}/credit-notes/${id}`, {
    includes: fetchIncludes
  });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function create(creditNote, invoiceId) {
  const { data } = await Vue.$http.post(
    `${resourceUrl}/${invoiceId}/credit-notes`,
    creditNote
  );
  return normalize(data.data, schema);
}

async function finalize(creditNoteId, invoiceId) {
  const { data } = await Vue.$http.post(
    `${resourceUrl}/${invoiceId}/credit-notes/${creditNoteId}/finalize`
  );
  return normalize(data.data, schema);
}

async function pdf(payload, invoiceId) {
  const response = await Vue.$http({
    url: `${resourceUrl}/${invoiceId}/credit-notes/${payload.creditNoteId}/download/${payload.locale}`,
    method: "GET",
    responseType: "blob"
  });
  /*eslint-disable*/
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const matches = response.headers["content-disposition"].match(fileNameRegex);
  const [contentDispositionHeader, fileName, extension] = matches;
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.${extension}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return true;
}

async function destroy(creditNoteId, invoiceId) {
  const url = `${resourceUrl}/${invoiceId}/credit-notes/${creditNoteId}`;
  return await Vue.$http.delete(url);
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = [
  "invoice.company.billingData.country",
  "items",
  "invoice.creditNotes",
  "draftedBy.employee",
  "finalizedBy.employee"
];

export default {
  normalize,
  fetch,
  create,
  finalize,
  pdf,
  destroy
};
