// import FiltersOverview from "./FiltersOverview.vue";

export default [
  {
    path: "/filters",
    name: "filters",
    component: () =>
      import(/* webpackChunkName: "filters" */ "@/views/filters/FiltersView"),
    props(route) {
      return {
        create: route.name === "filters.create",
        order: route.name === "filters.order",
        details: route.name === "filters.show",
        focused: route.name === "filters"
      };
    },
    meta: {
      title: "Filters"
    },
    children: [
      {
        path: "order",
        name: "filters.order",
        component: () =>
          import(
            /* webpackChunkName: "filters" */ "@/views/filters/FilterOrderView"
          ),
        props: {
          order: true
        },
        meta: {
          title: "Filters"
        }
      }
    ]
  },
  {
    path: "/filters/create",
    name: "filters.create",
    component: () =>
      import(
        /* webpackChunkName: "filters" */ "@/views/filters/FilterCreateView"
      ),
    meta: {
      title: "Filters"
    }
  },
  {
    path: "/filters/:filterId",
    name: "filters.show",
    component: () =>
      import(/* webpackChunkName: "filters" */ "@/views/filters/FilterView"),
    meta: {
      title: "Filters"
    },
    props(route) {
      return { id: parseInt(route.params.filterId) };
    }
  }
];
