import Vue from "vue";
import { normalize } from "normalizr";
import { IN_SYNC, LOADING, UNINIT } from "../../sync-states";
import { province as schema } from "@/store/schema";

const route = "api.provinces.index";
const name = "province";

// state
export const state = {
  province: {},
  provinceSync: UNINIT,
  provinceIndividualSync: {},
  provinceByCountry: {},
  provinceByCountrySync: {}
};

// getters
export const getters = {
  getProvincesByCountry: (state, getters) => ({ country }) => {
    return getters.getEntities({
      name: "province",
      ids: state.provinceByCountry[country]
    });
  },
  isProvincesByCountryInitialized: state => ({ country }) => {
    return state.provinceByCountrySync[country] !== UNINIT;
  },
  isProvincesByCountryLoading: state => ({ country }) => {
    return state.provinceByCountrySync[country] === LOADING;
  }
};

// mutations
export const mutations = {
  setProvinceByCountrySync(state, { country, sync }) {
    Vue.set(state.provinceByCountrySync, country, sync);
  },
  setProvincesByCountry(state, { country, provinces }) {
    Vue.set(state.provinceByCountry, country, provinces);
  }
};

// actions
export const actions = {
  async fetchProvince(context, { id, includes, cb }) {
    await context.dispatch("fetch", { route, name, schema, id, includes, cb });
  },
  async updateProvince(context, { entity, includes, cb }) {
    await context.dispatch("update", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async storeProvince(context, { entity, includes, cb }) {
    await context.dispatch("store", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async destroyProvince(context, { id, cb }) {
    await context.dispatch("destroy", { route, name, id, cb });
  },
  async fetchProvincesByCountry(context, { country }) {
    try {
      context.commit("setProvinceByCountrySync", { country, sync: LOADING });
      const { data } = await Vue.$http.get(
        `/${window.Ziggy.namedRoutes[
          "api.countries.provinces.index"
        ].uri.replace("{country}", country)}`
      );
      let normalizedData = normalize(data.data, [schema]);
      context.commit("updateEntities", { entities: normalizedData.entities });
      context.commit("setProvincesByCountry", {
        country,
        provinces: normalizedData.result
      });
      context.commit("setProvinceByCountrySync", { country, sync: IN_SYNC });
      return normalizedData.result;
    } catch (error) {
      console.error(error);
    }
  }
};
