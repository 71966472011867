export default {
  getMessage() {
    return "This field is not a valid phone number.";
  },
  validate(value) {
    if (value.indexOf("1234567") !== -1) {
      return false;
    }
    if (value.indexOf("0000000") !== -1) {
      return false;
    }
    return new RegExp(/^[\d ()+-]+$/).test(value);
  }
};
