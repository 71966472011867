<template>
  <div class="tw-ml-3 tw-relative">
    <div>
      <button
        class="tw-max-w-xs tw-flex tw-items-center tw-text-sm tw-rounded-full focus:tw-outline-none focus:tw-shadow-outline"
        id="user-menu"
        aria-label="User menu"
        aria-haspopup="true"
        :aria-expanded="isOpen.toString()"
        @click="() => (isOpen = !isOpen)"
      >
        <img
          class="tw-h-8 tw-w-8 tw-rounded-full"
          :src="pictureUrl ? pictureUrl : require('@/assets/avatar.png')"
          alt=""
        />
      </button>
    </div>
    <!--
      Profile dropdown panel, show/hide based on dropdown state.

      Entering: "tw-transition tw-ease-out tw-duration-100"
        From: "transform opacity-0 scale-95"
        To: "transform opacity-100 scale-100"
      Leaving: "tw-transition tw-ease-in tw-duration-75"
        From: "transform opacity-100 scale-100"
        To: "transform opacity-0 scale-95"
    -->
    <transition
      enter-active-class="tw-transition tw-ease-out tw-duration-100"
      enter-class="tw-transform tw-opacity-0 tw-scale-95"
      enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-active-class="tw-transition tw-ease-in tw-duration-75"
      leave-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
    >
      <div
        v-show="isOpen"
        class="tw-origin-top-right tw-absolute tw-right-0 tw-mt-2 tw-w-48 tw-rounded-md tw-shadow-lg"
      >
        <div
          class="tw-rounded-md tw-bg-white tw-shadow-xs"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <template v-for="(group, groupIndex) in items">
            <div
              v-if="groupIndex !== 0"
              :key="`${groupIndex}-divider`"
              class="tw-border-t tw-border-gray-100"
            ></div>
            <div class="tw-py-1" :key="groupIndex">
              <template v-for="(item, index) in group">
                <form
                  v-if="item.form"
                  :method="item.form.method"
                  :action="item.form.action"
                  :key="`${groupIndex}-${index}`"
                >
                  <button
                    class="tw-w-full tw-text-left tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-900"
                    role="menuitem"
                  >
                    {{ item.name }}
                  </button>
                </form>
                <a
                  v-else
                  :key="`${groupIndex}-${index}`"
                  href="#"
                  class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-900"
                  role="menuitem"
                  >{{ item.name }}</a
                >
              </template>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("auth");

export default {
  name: "b-profile-dropdown-mobile",
  components: {},
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    isOpen: false
  }),
  computed: {
    ...mapGetters(["firstName", "lastName", "roles", "pictureUrl"]),
    role() {
      return this.roles.map(role => role.name)[0] ?? "Guest";
    }
  }
};
</script>
