import { FETCH as DISPATCH_FETCH } from "@buldit/fe-common/src/store/templates/table/action-types";
import tableActions from "@buldit/fe-common/src/store/templates/table/actions";
import store from "@/store";
import api from "@/api/notification";
import { UPDATE_ITEM as COMMIT_UPDATE_ITEM } from "../../templates/table/mutation-types";

export default {
  ...tableActions,
  async [DISPATCH_FETCH](context) {
    return await tableActions.fetch(context, { fetchCall: api.fetchAll });
  },
  async markAsRead({ commit }, notificationId) {
    const { entities, result } = await api.markAsRead(notificationId);
    commit(COMMIT_UPDATE_ITEM, { item: result, entities });
    store.dispatch("user-notifications/removeNotification", notificationId);
  },
  async markAllAsRead({ dispatch }) {
    await api.markAllAsRead();
    dispatch(DISPATCH_FETCH);
    store.dispatch("user-notifications/removeNotifications");
  }
};
