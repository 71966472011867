import { UNINIT } from "../../sync-states";
import { form as schema } from "@/store/schema";

// state
export const state = {
  form: {},
  formSync: UNINIT,
  formIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchForm(context, { id, includes, cb }) {
    await context.dispatch("fetch", {
      route: "api.forms.index",
      name: "form",
      schema: schema,
      id: id,
      includes: includes,
      cb: cb
    });
  },
  async updateForm(context, { entity, includes, cb }) {
    await context.dispatch("update", {
      route: "api.forms.index",
      name: "form",
      schema: schema,
      entity: entity,
      includes: includes,
      cb: cb
    });
  },
  async storeForm(context, { entity, includes, cb }) {
    await context.dispatch("store", {
      route: "api.forms.index",
      name: "form",
      schema: schema,
      entity: entity,
      includes: includes,
      cb: cb
    });
  },
  async destroyForm(context, { id, cb }) {
    await context.dispatch("destroy", {
      route: "api.forms.index",
      name: "form",
      id: id,
      cb: cb
    });
  }
};
