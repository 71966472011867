import { isArray, isBoolean, isNil, isPlainObject } from "lodash";
import axios from "axios";
import Vue from "vue";

export function url(
  url,
  { filters, pagination, includes, joins, queryParams } = {}
) {
  let params = null;
  try {
    url = new URL(url);
    params = new URLSearchParams(url.search);
  } catch (exception) {
    params = new URLSearchParams("");
  }

  // const params = new URLSearchParams(url.search);
  applyIncludes(params, includes);
  applyJoins(params, joins);
  applyFilters(params, filters);
  applyPagination(params, pagination);
  applySort(params, pagination);
  applyQueryParams(params, queryParams);

  return Array.from(params).length === 0 ? url : `${url}?${params}`;
}

export function createApiUrl(path, params) {
  if (!path.startsWith("/")) {
    path = `/${path}`;
  }
  if (!isNil(params)) {
    path = url(path, params);
  }
  return path;
}

export function applyFilters(params, filters) {
  if (!isNil(filters)) {
    Object.entries(filters)
      .filter(isValidFilter)
      .forEach(([property, value]) => applyFilter(params, property, value));
  }
}

function isValidFilter(entry) {
  const value = entry[1];
  if (isNil(value)) {
    return false;
  }
  if (isArray(value) && value.length === 0) {
    return false;
  }
  if (isBoolean(value) && value === false) {
    return false;
  }

  return true;
}

function applyFilter(params, property, value) {
  if (isPlainObject(value)) {
    Object.entries(value)
      .filter(isValidFilter)
      .forEach(([innerProperty, value]) =>
        applyFilter(params, `${property}][${innerProperty}`, value)
      );
  } else {
    params.set(`filter[${property}]`, value);
  }
}

export function applyPagination(params, pagination) {
  if (!isNil(pagination)) {
    if (!isNil(pagination.page) && pagination.page !== 1) {
      params.set("page", pagination.page);
    }
    params.set("perPage", pagination.rowsPerPage);
  }
}

export function applySort(params, pagination) {
  if (!isNil(pagination)) {
    if (!isNil(pagination.sortBy)) {
      params.set(
        "sort",
        `${pagination.descending ? "-" : ""}${pagination.sortBy}`
      );
    }
  }
}

function applyIncludes(params, includes) {
  if (!isNil(includes)) {
    if (!isArray(includes)) {
      // If not an array, wrap it.
      includes = [includes];
    }
    includes.forEach(include => {
      params.append("includes[]", include);
    });
  }
}

function applyJoins(params, joins) {
  if (!isNil(joins)) {
    if (!isArray(joins)) {
      // If not an array, wrap it.
      joins = [joins];
    }
    joins.forEach(join => {
      params.append("joins[]", join);
    });
  }
}

function applyQueryParams(params, queryParams) {
  if (!isNil(queryParams)) {
    Object.entries(queryParams)
      .filter(
        ([, value]) => !isNil(value) || (isArray(value) && value.length > 0)
      )
      .forEach(([property, value]) => {
        if (isArray(value)) {
          value.forEach(innerValue => {
            params.append(`${property}[]`, innerValue);
          });
        } else {
          params.set(property, value);
        }
      });
  }
}

/**
 * Returns the string contents of the current url's hash. Boolean cutOffHash determines whether the hash stays or goes.
 * Default: it goes
 *
 * @param cutOffHash
 * @returns {string}
 */
export function getHash(cutOffHash = true) {
  const hash = window.location.hash;
  return cutOffHash && hash.indexOf("#") === 0 ? hash.substr(1) : hash;
}

export function getValuesFromURLSearchParams(urlSearchParams) {
  const paramObject = {};
  const paramMap = Array.from(urlSearchParams.entries());
  paramMap.forEach(([property, value]) => {
    // Use regex to determine if it is a nested property. (Like filter[property]
    const result = property.match(/^(\w+)\[(\w+)\]/);

    // Use regex to determine if it is a nested property with 2 levels of nesting. (Like filter[property][property])
    const secondLevelResult = property.match(/^(\w+)\[(\w+)\]\[(\w+)\]/);
    if (!isNil(result) && isNil(secondLevelResult)) {
      // Full match should not be needed unless we have properties with 2 levels of nesting.
      // const fullMatch = result[0];
      const wrapperProperty = result[1];
      const innerProperty = result[2];

      if (isNil(paramObject[wrapperProperty])) {
        paramObject[wrapperProperty] = {};
      }

      if (isNil(paramObject[wrapperProperty][innerProperty])) {
        paramObject[wrapperProperty][innerProperty] = {};
      }
      paramObject[wrapperProperty][innerProperty] = castValue(value);
    }
    if (!isNil(secondLevelResult)) {
      const wrapperProperty = secondLevelResult[1];
      const innerProperty = secondLevelResult[2];
      const innerInnerProperty = secondLevelResult[3];

      if (isNil(paramObject[wrapperProperty])) {
        paramObject[wrapperProperty] = {};
      }

      if (isNil(paramObject[wrapperProperty][innerProperty])) {
        paramObject[wrapperProperty][innerProperty] = {};
      }
      paramObject[wrapperProperty][innerProperty][
        innerInnerProperty
      ] = castValue(value);
    } else {
      paramObject[property] = castValue(value);
    }
  });

  return paramObject;
}

function castValue(value) {
  if (value === "true") {
    return true;
  } else if (value === "false") {
    return false;
  } else if (!isNaN(value)) {
    return +value;
  } else {
    return value;
  }
}

let cancelTokens = [];

export function cancellableRequest(method, url, call = url) {
  if (cancelTokens[call]) {
    cancelTokens[call].cancel("Only one request allowed at a time.");
  }
  cancelTokens[call] = axios.CancelToken.source();

  const cancelToken = cancelTokens[call].token;
  return Vue.$http({ method: method, url: url, cancelToken: cancelToken })
    .then(response => {
      return response;
    })
    .catch(function(thrown) {
      if (axios.isCancel(thrown)) {
        console.debug("Request canceled", thrown.message);
        throw thrown;
      } else {
        // handle error
      }
    });
}
