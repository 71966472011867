import { UNINIT } from "@/store//sync-states";
import { keywordCluster as schema } from "@/store/schema";

// state
export const state = {
  keywordCluster: {},
  keywordClusterSync: UNINIT,
  keywordClusterIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {
  detachKeywordClusterKeyword(state, { keywordClusterId, keywordId }) {
    const keywordCluster = state.keywordCluster[keywordClusterId];
    keywordCluster.keywords.splice(
      keywordCluster.keywords.indexOf(keywordId),
      1
    );
  }
};

// actions
export const actions = {
  async fetchKeywordCluster(context, { id, includes, filters, cb }) {
    return await context.dispatch("fetch", {
      route: "api.keyword-clusters.index",
      name: "keywordCluster",
      schema,
      id,
      includes,
      filters,
      cb
    });
  },
  async updateKeywordCluster(context, { entity, includes, cb }) {
    return await context.dispatch("update", {
      route: "api.keyword-clusters.index",
      name: "keywordCluster",
      schema,
      entity,
      includes,
      cb
    });
  },
  async storeKeywordCluster(context, { entity, includes, cb }) {
    return await context.dispatch("store", {
      route: "api.keyword-clusters.index",
      name: "keywordCluster",
      schema,
      entity,
      includes,
      cb
    });
  },
  async destroyKeywordCluster(context, { id, cb }) {
    await context.dispatch("destroy", {
      route: "api.keyword-clusters.index",
      name: "keywordCluster",
      id,
      cb
    });
  }
};
