import Vue from "vue";
import { UNINIT } from "../../sync-states";
import { category as schema } from "@/store/schema";
import { filter, isNil, isEmpty, isArray } from "lodash";
import { normalize } from "normalizr";
import { createUrlFromRoute } from "@/store/url";

const route = "api.categories.index";
const name = "category";

// state
export const state = {
  category: {},
  categorySync: UNINIT,
  categoryIndividualSync: {},
  categoryRootSync: UNINIT
};

// getters
export const getters = {
  getCategoryOrphans: (state, getters) => {
    if (getters.isLoaded({ name: "category" })) {
      return filter(
        state.category,
        category =>
          !category.isRoot &&
          !isNil(category.parents) &&
          isEmpty(category.parents)
      );
    }
    return [];
  },
  getRootCategories: (state, getters) => {
    if (getters.isLoaded({ name: "category" })) {
      return filter(state.category, category => category.isRoot);
    }
    return [];
  },
  getLeafCategories: state => {
    return filter(
      state.category,
      category =>
        isArray(category.children) &&
        isEmpty(category.children) &&
        isArray(category.parents) &&
        !isEmpty(category.parents)
    );
  },
  isRootCategoriesInitialized: (state, getters) => {
    return (
      getters.isInitialized({ name: "category" }) ||
      state.categoryRootSync !== UNINIT
    );
  }
};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchCategory(context, { id, includes, filters, cb }) {
    return await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes,
      filters,
      cb
    });
  },
  async updateCategory(context, { entity, includes, cb }) {
    await context.dispatch("update", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async storeCategory(context, { entity, includes, cb }) {
    await context.dispatch("store", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async destroyCategory(context, { id, cb }) {
    await context.dispatch("destroy", { route, name, id, cb });
  },
  async fetchRootCategories(context) {
    try {
      const { data } = await Vue.$http.get(`${createUrlFromRoute(route)}/root`);
      let normalizedData = normalize(data.data, [schema]);
      context.commit("updateEntities", { entities: normalizedData.entities });
      return normalizedData.result;
    } catch (error) {
      return null;
    }
  }
};
