<script>
/**
 * @deprecated in favor of using vuex auth module getters !!!
 */

import { isNil, isArray } from "lodash";
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("auth");

export default {
  name: "roles-and-permissions-mixin",
  computed: {
    ...mapGetters(["user", "permissions", "roles", "hasPermission", "hasRole"]),
    authenticatedUser() {
      return this.user;
    }
  },
  methods: {
    userNeededRoles(roles) {
      if (isNil(roles)) {
        return false;
      }
      if (isArray(roles)) {
        roles.forEach(role => {
          if (!this.hasRole(role)) {
            return false;
          }
        });
        return true;
      } else {
        this.hasRole(roles);
      }
    },
    userNeededPermissions(permissions) {
      if (isNil(permissions)) {
        return false;
      }
      if (isArray(permissions)) {
        permissions.forEach(permission => {
          if (!this.hasPermission(permission)) {
            return false;
          }
        });
        return true;
      } else {
        this.hasPermission(permissions);
      }
    },
    userIsAdminOrDev() {
      let isAdminOrDeveloper = false;
      this.roles.forEach(role => {
        if (["Developer", "Admin"].includes(role)) {
          isAdminOrDeveloper = true;
        }
      });

      return isAdminOrDeveloper;
    }
  }
};
</script>
