import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import state from "./state";

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  init
};

function init(store) {
  console.debug("[VUEX] Init locales module.");
  store.dispatch("locales/fetch");
}
