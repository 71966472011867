<template>
  <v-snackbar
    v-model="isSnackBarShowing"
    :timeout="6000"
    :top="false"
    :bottom="true"
    :right="true"
    :left="false"
    :color="snackBarColor"
  >
    {{ snackBarText }}
    <v-btn
      flat
      :color="snackBarButtonColor"
      @click.native="isSnackBarShowing = false"
    >
      Close
    </v-btn>
  </v-snackbar>
</template>
<script>
export default {
  name: "main-snackbar",
  data: () => ({
    isSnackBarOnCooldown: false,
    snackBarCooldownTimeout: undefined
  }),
  computed: {
    isSnackBarShowing: {
      get() {
        return (
          !this.isSnackBarOnCooldown && this.$store.getters["snackbar/hasItems"]
        );
      },
      set(value) {
        if (!value) {
          this.$store.dispatch("snackbar/removeCurrentItem");
          this.triggerSnackBarCooldown();
        }
      }
    },
    snackBarColor() {
      return this.$store.getters["snackbar/currentItemColor"];
    },
    snackBarText() {
      return this.$store.getters["snackbar/currentItemText"];
    },
    snackBarButtonColor() {
      switch (this.snackBarColor) {
        case "success":
          return "white";
        case "error":
          return "white";
        case "info":
          return "white";
        default:
          return "pink";
      }
    }
  },
  methods: {
    triggerSnackBarCooldown() {
      this.isSnackBarOnCooldown = true;
      clearTimeout(this.snackBarCooldownTimeout);
      this.snackBarCooldownTimeout = setTimeout(() => {
        this.isSnackBarOnCooldown = false;
      }, 200);
    }
  }
};
</script>
