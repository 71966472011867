import Vue from "vue";
import { createApiUrl } from "@/api/url";
import {
  contentPlan as schema,
  normalize as internalNormalize
} from "@/store/schema";

const fileNameRegex = /attachment; filename=([^.]*).(\w*)/;
const resourceUrl = createApiUrl("api/v1/content-plans");

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function uploadImport({ file, progressCb }) {
  const formData = new FormData();
  formData.append("upload", file, file.name);
  const data = await Vue.$http.post(`${resourceUrl}/import`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: progressCb
  });

  return { success: data.success };
}

async function template() {
  const { data, headers } = await Vue.$http.get(`${resourceUrl}/template`);
  const matches = headers["content-disposition"].match(fileNameRegex);
  /*eslint-disable*/
  const [contentDispositionHeader, fileName, extension] = matches;

  return {
    data: data,
    fileName: fileName,
    extension: extension
  };
}

async function remove(contentPlanId) {
  const { data } = await Vue.$http.delete(`${resourceUrl}/${contentPlanId}`);
  return data.success;
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchAllIncludes = [];

export default {
  uploadImport,
  fetchAll,
  template,
  remove
};
