import Vue from "vue";
import { locale as schema, normalize } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/locales");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, {
    includes: fetchIncludes
  });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

const fetchIncludes = [];
const fetchAllIncludes = [];

export default {
  fetch,
  fetchAll
};
