export default [
  {
    path: "/subjects",
    name: "subjects",
    // route level code-splitting
    // this generates a separate chunk (websites.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "subjects" */ "@/views/subjects/SubjectsView.vue"
      ),
    children: [
      {
        path: "create",
        name: "subjects.create",
        component: () =>
          import(
            /* webpackChunkName: "subjects" */ "@/views/subjects/SubjectCreateView.vue"
          )
      },
      {
        path: ":subjectId",
        component: () =>
          import(
            /* webpackChunkName: "subjects" */ "@/views/subjects/SubjectView.vue"
          ),
        meta: {
          title: "Websites"
        },
        children: [
          {
            path: "",
            name: "subjects.show",
            props: route => ({ id: parseInt(route.params.websiteId) }),
            component: () =>
              import(
                /* webpackChunkName: "subjects" */ "@/views/subjects/SubjectDetailTabView.vue"
              )
          }
        ]
      }
    ]
  }
];
