import Vue from "vue";
import {
  normalize as internalNormalize,
  subject as schema
} from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/subjects");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function fetchCategorySubject(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, {
    includes: fetchCategorySubjectIncludes
  });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function search({ filters } = {}) {
  const url = createApiUrl(resourceUrl, { filters });
  const data = await Vue.$http.get(url);
  return data.data.data;
}

async function create(subject) {
  const url = createApiUrl(resourceUrl, { includes: fetchIncludes });
  const { data } = await Vue.$http.post(url, subject);
  return normalize(data.data, schema);
}

async function update(subjectId, subject) {
  const url = createApiUrl(`${resourceUrl}/${subjectId}`, {
    includes: fetchIncludes
  });
  const { data } = await Vue.$http.put(url, subject);
  return normalize(data.data, schema);
}

async function remove(subjectId) {
  const { data } = await Vue.$http.delete(`${resourceUrl}/${subjectId}`);
  return data.status === 200;
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = ["parent.parent", "children", "label", "countries"];
const fetchAllIncludes = ["children"];
const fetchCategorySubjectIncludes = [
  "questions.options.parentOption.parentOption.parentOption",
  "questions.parentQuestion.parentQuestion.parentQuestion"
];

export default {
  normalize,
  fetch,
  fetchAll,
  create,
  search,
  fetchCategorySubject,
  update,
  remove
};
