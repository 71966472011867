import { isArray } from "lodash";

export default {
  firstName: (state, getters) => getters.user.employee.firstName,
  lastName: (state, getters) => getters.user.employee.lastName,
  pictureUrl: (state, getters) => getters.user.employee.picture,
  employee: state => state.employee,
  isEmployee: state => state.isEmployee,
  isBuddy: state => state.isBuddy,
  notifications: state => state.notifications,
  isImpersonation: state => state.impersonation,
  isCustomer: state => state.isCustomer,
  customerName: state =>
    state.company ? state.company.name : state.customer.name,
  hasRole: (state, getters) => role => {
    const roleNames = getters.roles.map(o => o.name);

    if (roleNames.includes("admin") || roleNames.includes("developer")) {
      return true;
    }

    return roleNames.includes(role);
  },
  hasRolesOrPermissions: (state, getters) => rolesOrPermissions => {
    if (rolesOrPermissions === undefined) {
      return true;
    }
    if (!isArray(rolesOrPermissions)) {
      rolesOrPermissions = [rolesOrPermissions];
    }
    return (
      rolesOrPermissions.find(
        roleOrPermission =>
          getters.hasRole(roleOrPermission) ||
          getters.hasPermission(roleOrPermission)
      ) !== undefined
    );
  },
  loginQuery: state => {
    return (
      "?accessToken=" + state.accessToken + "&expireDate=" + state.expireDate
    );
  }
};
