import Vue from "vue";
import { annotation as schema, normalize } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/annotations");

async function fetchAll(articleId, { filters, pagination } = {}) {
  // Hardcode website filter
  filters.article = articleId;
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

const fetchAllIncludes = [
  "author",
  "edit",
  "type",
  "subTypes",
  "websites",
  "articles",
  "subjects",
  "keywords",
  "files"
];

export default {
  fetchAll
};
