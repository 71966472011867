<template>
  <v-app>
    <loading ref="loading" />
    <layout-manager ref="layout-manager" />
  </v-app>
</template>
<script>
import Loading from "./components/generic/loader/Loading.vue";
import { LayoutManager } from "@buldit/common-fe";

export default {
  name: "backoffice-app",
  components: {
    Loading,
    LayoutManager
  },
  metaInfo() {
    let titleTemplate = "%s";
    const env = process.env.VUE_APP_ENV;
    const appName = process.env.VUE_APP_TITLE;
    if (env !== "production") {
      titleTemplate = `[${env.toUpperCase()}] ${titleTemplate}`;
    }

    return {
      title: appName,
      titleTemplate
    };
  },

  mounted() {
    this.$loading = this.$refs.loading;
    // this.setLayout();
  },
  methods: {}
};
</script>
<style lang="scss">
.pos-relative {
  position: relative !important;
}

.primary--border,
.secondary--border,
.accent--border,
.error--border,
.warning--border,
.info--border,
.success--border {
  border-width: 1px;
  border-style: solid;
}

.primary--border {
  border-color: var(--v-primary-base) !important;
}

.secondary--border {
  border-color: var(--v-secondary-base) !important;
}

.accent--border {
  border-color: var(--v-accent-base) !important;
}

.error--border {
  border-color: var(--v-error-base) !important;
}

.warning--border {
  border-color: var(--v-warning-base) !important;
}

.success--border {
  border-color: var(--v-success-base) !important;
}

.info--border {
  border-color: var(--v-info-base) !important;
}

.border--dotted {
  border-style: dotted !important;
}

.border--dashed {
  border-style: dashed !important;
}

.border--width-2 {
  border-width: 2px;
}

.border--width-3 {
  border-width: 3rem;
}

.white-smoke {
  background-color: #f7f7f7;
}

/** TRANSITION GROUP FOR CONTAINER ROWS */
.v-container-list-enter-active,
.v-container-list-leave-active,
.v-container-list-move {
  /*transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);*/
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.v-container-list-move {
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
}

.v-container-list-enter {
  opacity: 0;
  transform: translateX(50px) scaleY(0.5);
}

.v-container-list-enter-to {
  opacity: 1;
  transform: translateX(0) scaleY(1);
}

.v-container-list-leave-active {
  /*position: absolute;*/
}

.v-container-list-leave-to {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;
}

/** END - TRANSITION GROUP FOR CONTAINER ROWS **/

/** V-DATATABLE OVERRIDES */
.v-table-th--icon {
  width: 48px;
  padding: 0 !important;
}

.v-table-th--icon:last-child {
  padding-right: 16px !important;
}

.v-table-td--icon {
  padding: 0 !important;
}

.v-table-td--icon:last-child {
  padding-right: 16px !important;
}

/** END - V-DATATABLE OVERRIDES */
</style>
<style src="./assets/css/style.css"></style>
