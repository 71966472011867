import Vue from "vue";
import {
  localization as schema,
  normalize as internalNormalize
} from "@/store/schema";
import { createApiUrl } from "../url";

const resourceUrl = createApiUrl("api/v1/localizations");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function create(localization) {
  const { data } = await Vue.$http.post(resourceUrl, localization);
  return normalize(data.data, schema);
}

async function update(localizationId, localization) {
  const url = `${resourceUrl}/${localizationId}`;
  const { data } = await Vue.$http.put(url, localization);
  return normalize(data.data, schema);
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = [
  "collectionSubject",
  "questions",
  "questionHoverForm",
  "option",
  "optionHover",
  "optionRemark",
  "label",
  "placeholder",
  "infoHover",
  "questionLabel",
  "questionRemark",
  "questionHover"
];
const fetchAllIncludes = [
  "collectionSubject",
  "questions",
  "questionHoverForm",
  "option",
  "optionHover",
  "optionRemark",
  "label",
  "placeholder",
  "infoHover",
  "questionLabel",
  "questionRemark",
  "questionHover"
];

export default {
  normalize,
  fetch,
  fetchAll,
  create,
  update
};
