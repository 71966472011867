export default [
  {
    path: "/backoffice/forms",
    name: "forms",
    // route level code-splitting
    // this generates a separate chunk (forms.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "forms" */ "@/views/forms/FormsView.vue"),
    meta: {
      title: "Forms"
    },
    children: [
      {
        path: "create",
        name: "forms.create",
        // route level code-splitting
        // this generates a separate chunk (forms.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "forms" */ "@/views/forms/FormCreateView.vue"
          ),
        meta: {
          title: "Forms"
        }
      },
      {
        path: "/backoffice/forms/:formId",
        component: () =>
          import(/* webpackChunkName: "forms" */ "@/views/forms/FormView.vue"),
        meta: {
          title: "Forms"
        },
        children: [
          {
            path: "",
            name: "forms.show",
            props: route => ({ id: parseInt(route.params.formId) }),
            component: () =>
              import(
                /* webpackChunkName: "forms" */ "@/views/forms/FormDashboardView.vue"
              ),
            meta: {
              title: "Forms"
            }
          }
        ]
      }
    ]
  }
];
