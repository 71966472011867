import Vue from "vue";
import { normalize } from "normalizr";
import { user as userSchema } from "@/store/schema";
import { createApiUrl } from "../url";

async function fetchUser(includes = fetchIncludes) {
  const url = createApiUrl(`/api/v1/auth/user`, { includes: includes });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, userSchema);
  const user = entities.user[result];
  const employee = entities.employee ? entities.employee[user.employee] : null;
  const customer = entities.customer ? entities.customer[user.customer] : null;

  //Assuming just one company per customer
  const company = entities.company
    ? entities.company[customer.companies[0]]
    : null;

  //TODO notifications and permissions are plural, standard is singular.
  //maybe check all uses and change this
  return {
    user,
    employee,
    company,
    customer,
    notifications: entities.notification,
    permissions: entities.permission,
    isEmployee: data.data.isEmployee,
    isBuddy: data.data.isBuddy,
    isCustomer: data.data.isCustomer,
    roles: entities.role
  };
}

async function requestPasswordReset(payload) {
  return await Vue.$http.post("/api/v1/password/request", payload);
}

async function passwordReset(payload) {
  return await Vue.$http.post("/api/v1/password/reset", payload);
}

const fetchIncludes = [
  "employee",
  "customer",
  "buddy",
  "latest10UnreadNotifications",
  "roles.permissions",
  "permissions"
];

export default {
  fetchUser,
  requestPasswordReset,
  passwordReset
};
