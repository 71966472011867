export default [
  {
    path: "/backoffice/statistics/websites-timetable",
    name: "statistics.websites-timetable",
    // route level code-splitting
    // this generates a separate chunk (websites.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "statistics-websites-timetable" */ "@/views/statistics/websites/WebsitesStatsTimetableView.vue"
      ),
    meta: {
      title: "Websites Submissions stats"
    }
  },
  {
    path: "/backoffice/statistics/websites-monthly-metrics",
    name: "statistics.websites-monthly-metrics",
    // route level code-splitting
    // this generates a separate chunk (websites.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "websites-monthly-metrics" */ "@/views/statistics/websites/WebsitesMonthlyMetricsView.vue"
      ),
    meta: {
      title: "Websites Monthly Metrics"
    }
  },
  {
    path: "/backoffice/statistics/articles-timetable",
    name: "statistics.articles-timetable",
    // route level code-splitting
    // this generates a separate chunk (websites.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "statistics-articles-timetable" */ "@/views/statistics/articles/ArticlesStatsTimetableView.vue"
      ),
    meta: {
      title: "Articles Stats Timetable"
    }
  },
  {
    path: "/backoffice/statistics/articles-conversions-report",
    name: "statistics.articles-conversions-report",
    // route level code-splitting
    // this generates a separate chunk (websites.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "statistics-articles-submissions" */ "@/views/statistics/articles/ArticlesConversionsReportView.vue"
      ),
    meta: {
      title: "Articles conversions report"
    }
  },
  {
    path: "/backoffice/statistics/subject-performance-report",
    name: "statistics.subject-performance-report",
    // route level code-splitting
    // this generates a separate chunk (websites.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "statistics-articles-submissions" */ "@/views/statistics/subjects/SubjectPerformanceReportView.vue"
      ),
    meta: {
      title: "Subject performance report"
    }
  },
  {
    path: "/backoffice/statistics/subject-sales-performance-report",
    name: "statistics.subject-sales-performance-report",
    // route level code-splitting
    // this generates a separate chunk (websites.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "statistics-articles-submissions" */ "@/views/statistics/subjects/SubjectSalesPerformanceReportView.vue"
      ),
    meta: {
      title: "Subject sales performance report"
    }
  }
];
