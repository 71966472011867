import { UNINIT } from "@/store/sync-states";
import { tag as schema } from "@/store/schema";
import { filter } from "lodash";

// state
export const state = {
  tag: {},
  tagSync: UNINIT,
  tagIndividualSync: {},
  tagByType: {},
  tagByTypeSync: {}
};

// getters
export const getters = {
  getTagsByType: state => ({ type }) => {
    return filter(state.tag, tag => tag.type === type);
  }
};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchTag(context, { id, includes, cb, filters }) {
    await context.dispatch("fetch", {
      route: "api.tags.index",
      name: "tag",
      schema: schema,
      id: id,
      includes: includes,
      filters: filters,
      cb: cb
    });
  },
  async updateTag(context, { entity, includes, cb }) {
    const result = await context.dispatch("update", {
      route: "api.tags.index",
      name: "tag",
      schema: schema,
      entity: entity,
      includes: includes,
      cb: cb
    });
    context.dispatch(
      "snackbar/addItem",
      { text: "successfully updated tag", color: "success" },
      { root: true }
    );
    return result;
  },
  async storeTag(context, { entity, includes, cb }) {
    await context.dispatch("store", {
      route: "api.tags.index",
      name: "tag",
      schema: schema,
      entity: entity,
      includes: includes,
      cb: cb
    });
  },
  async destroyTag(context, { id, cb }) {
    await context.dispatch("destroy", {
      route: "api.tags.index",
      name: "tag",
      id: id,
      cb: cb
    });
  }
};
