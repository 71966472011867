import Vue from "vue";
import { articleCluster as schema, normalize } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/article-clusters");

async function fetchAll(websiteClusterId, { filters, pagination } = {}) {
  if (!filters.websiteCluster) {
    filters.websiteCluster = websiteClusterId;
  }
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

const fetchAllIncludes = ["articleSiblings.article"];

export default {
  fetchAll
};
