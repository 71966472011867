var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{class:[
        'tw-mt-1',
        'tw-group',
        'tw-w-full',
        'tw-flex',
        'tw-items-center',
        'tw-pl-2',
        'tw-pr-1',
        'tw-py-2',
        'tw-text-sm',
        'tw-leading-5',
        'tw-font-medium',
        'tw-rounded-md',
        'focus:tw-outline-none',
        'tw-transition',
        'tw-ease-in-out',
        'tw-duration-150'
      ].concat( _vm.textClass,
      _vm.backgroundClass
    ),on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('font-awesome-icon',{staticClass:"tw-mr-3 tw-h-6 tw-w-6 tw-text-gray-400 group-hover:tw-text-gray-700 group-focus:tw-text-gray-700 tw-transition tw-ease-in-out tw-duration-150 tw-text-xl",attrs:{"icon":_vm.group.icon,"fixed-width":""}}),_vm._v(" "+_vm._s(_vm.group.name)+" "),_c('svg',{staticClass:"tw-ml-auto tw-h-5 tw-w-5 tw-transform group-hover:tw-text-gray-700 group-focus:tw-text-gray-700 tw-transition-colors tw-ease-in-out tw-duration-150",class:_vm.expanded ? 'tw-text-gray-500 tw-rotate-90' : 'tw-text-gray-400',attrs:{"viewBox":"0 0 20 20"}},[_c('path',{attrs:{"d":"M6 6L14 10L6 14V6Z","fill":"currentColor"}})])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.expanded),expression:"expanded"}],staticClass:"tw-mt-1 tw-space-y-1"},_vm._l((_vm.group.items),function(item,index){return _c('router-link',{key:index,class:[
          'tw-group',
          'tw-w-full',
          'tw-flex',
          'tw-items-center',
          'tw-pl-11',
          'tw-pr-2',
          'tw-py-2',
          'tw-text-sm',
          'tw-leading-5',
          'tw-font-medium',
          'tw-rounded-md',
          'focus:tw-outline-none',
          'tw-transition',
          'tw-ease-in-out',
          'tw-duration-150'
        ].concat( _vm.textClass,
        _vm.backgroundClass
      ),attrs:{"to":item.to}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }