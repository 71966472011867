import Vue from "vue";
import { companySubject as schema, normalize } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const companyResourceUrl = createApiUrl("api/v1/companies");

async function fetchAll(companyId, { filters, pagination } = {}) {
  const resourceUrl = createApiUrl(`api/v1/companies/${companyId}/subjects`);
  filters.company = companyId;
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function create({ companyId, subject }) {
  const { data } = await Vue.$http.post(
    `${companyResourceUrl}/${companyId}/subject`,
    subject
  );
  return normalize(data.data, schema);
}

async function addSubject({ companyId, subject }) {
  const { data } = await Vue.$http.post(
    `${companyResourceUrl}/${companyId}/addSubject`,
    {
      subject: subject
    }
  );
  return normalize(data.data, schema);
}

async function updatePrice({ companyId, companySubjectId, price }) {
  const { data } = await Vue.$http.post(
    `${companyResourceUrl}/${companyId}/updatePrice`,
    {
      companySubjectId: companySubjectId,
      price: price
    }
  );
  return normalize(data.data, schema);
}

async function updateCountryPrice({
  companyId,
  companySubjectId,
  countryCode,
  price
}) {
  const { data } = await Vue.$http.post(
    `${companyResourceUrl}/${companyId}/updateCountryPrice`,
    {
      companySubjectId: companySubjectId,
      countryCode: countryCode,
      price: price
    }
  );
  return normalize(data.data, schema);
}

async function remove({ companyId, companySubjectId }) {
  const { data } = await Vue.$http.delete(
    `${companyResourceUrl}/${companyId}/subjects/${companySubjectId}`
  );
  return data.status === 200;
}

const fetchAllIncludes = ["subject.countries", "countries.country"];

export default {
  fetchAll,
  create,
  remove,
  addSubject,
  updateCountryPrice,
  updatePrice
};
