import Vue from "vue";
import { normalize } from "normalizr";
import { leadField as schema } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/lead-fields");

async function update(leadFieldId, leadField) {
  const url = `${resourceUrl}/${leadFieldId}`;
  const { data } = await Vue.$http.put(url, leadField);
  return normalize(data.data, schema);
}

export default {
  update
};
