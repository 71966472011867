import Vue from "vue";
import { UNINIT } from "@/store/sync-states";
import { article as schema } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/articles");
const route = "api.articles.index";
const name = "article";

// state
export const state = {
  article: {},
  articleSync: UNINIT,
  articleIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchArticleDetails(context, { id }) {
    return await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes: [
        "annotations.types",
        "annotations.subTypes",
        "annotations.author",
        "website.annotations.types",
        "website.annotations.subTypes",
        "website.annotations.author"
      ]
    });
  },
  async fetchArticle(context, { id, includes, filters, cb }) {
    return await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes,
      filters,
      cb
    });
  },
  async updateArticle(context, { entity, includes, cb }) {
    return await context.dispatch("update", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async storeArticle(context, { entity, includes, cb }) {
    return await context.dispatch("store", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async destroyArticle(context, { id, cb }) {
    await context.dispatch("destroy", { route, name, id, cb });
  },
  async articleInfo(context, { input }) {
    const url = `${resourceUrl}/info`;
    const { data } = await Vue.$http.get(url, { params: input });
    return data;
  }
};
