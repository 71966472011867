import { IN_SYNC, LOADING, UNINIT } from "@/store/sync-states";
import { separationRule as schema } from "@/store/schema";
import api from "@/api";

// state
export const state = {
  separationRule: {},
  separationRuleSync: UNINIT,
  separationRuleIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchSeparationRuleDetails(context, { id }) {
    context.commit("setSync", { name, id, sync: LOADING });
    const { entities, result } = await api.separationRule.fetch(id);
    context.commit("updateEntities", {
      entities,
      reactiveName: name,
      reactiveId: result
    });
    context.commit("setSync", { name: name, id: id, sync: IN_SYNC });
  },
  async fetchSeparationRule(context, { id, includes, cb }) {
    return await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes,
      cb
    });
  },
  async updateSeparationRule(context, { separationRule, includes }) {
    return await context.dispatch("update", {
      route,
      name,
      schema,
      entity: separationRule,
      includes
    });
  },
  async storeSeparationRule(context, { separationRule, includes }) {
    return await context.dispatch("store", {
      route,
      name,
      schema,
      entity: separationRule,
      includes
    });
  },
  async destroySeparationRule(context, { id }) {
    return await context.dispatch("destroy", { route, name, id });
  }
};

const name = "separationRule";
const route = "api.separation-rules.index";
