<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    nudge-top="-20"
    min-width="400"
    max-width="500"
    offset-y
  >
    <v-btn slot="activator" color="secondary" :loading="isLoading" flat>
      {{ localeName }}
    </v-btn>
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Select locales
      </v-card-title>
      <v-card-text>
        <v-select-locale v-model="localeId" />
        <span>{{ localeId }}</span>
        <v-select-locale
          v-model="displayedLocales"
          multiple
          label="Locales to display"
        />
        <span>{{ displayedLocales }}</span>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" flat @click="menu = false">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import { createNamespacedHelpers } from "vuex";
import { createNamespacedHelpers as createNamespacedComputedHelpers } from "@/store/helpers";
import VSelectLocale from "@/components/locale/VSelectLocale";

const { mapComputed } = createNamespacedComputedHelpers("locales");
const { mapState } = createNamespacedHelpers("locales");

export default {
  name: "locales-menu",
  components: { VSelectLocale },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({}),
  computed: {
    ...mapState(["isLoading", "entity"]),
    ...mapComputed({
      localeId: "userLocale",
      displayedLocales: "selectedLocales"
    }),
    localeName() {
      const localeName = isNil(this.entity[this.localeId])
        ? undefined
        : this.entity[this.localeId].name;
      return localeName;
    },
    menu: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>
