import Vue from "vue";
import { normalize, pageTypeMetric as schema } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/page-type-metrics");

async function fetchAll({ filters } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

export default {
  fetchAll
};
