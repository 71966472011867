export default [
  {
    path: "/backoffice/annotations",
    name: "annotations",
    component: () =>
      import(
        /* webpackChunkName: "annotations" */ "@/views/annotations/AnnotationsView"
      ),
    meta: {
      title: "Annotations"
    }
  },
  {
    path: "/backoffice/annotations/create",
    name: "annotations.create",
    component: () =>
      import(
        /* webpackChunkName: "annotations" */ "@/views/annotations/AnnotationCreateView"
      ),
    meta: {
      title: "Annotations"
    }
  },
  {
    path: "/backoffice/annotations/:annotationId",
    name: "annotations.show",
    component: () =>
      import(
        /* webpackChunkName: "annotations" */ "@/views/annotations/AnnotationView"
      ),
    props: route => ({
      annotationId: parseInt(route.params.annotationId)
    }),
    meta: {
      title: "Annotation"
    }
  }
];
