import Vue from "vue";
import { normalize, offerField as schema } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/offer-fields");

async function update(offerFieldId, value) {
  const url = createApiUrl(`${resourceUrl}/${offerFieldId}`);
  const { data } = await Vue.$http.put(url, { value });
  return normalize(data.data, schema);
}

export default {
  update
};
