import { UNINIT } from "../../sync-states";
import { language as schema } from "@/store/schema";

// state
export const state = {
  language: {},
  languageSync: UNINIT,
  languageIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchLanguage(context, { id, includes, cb }) {
    await context.dispatch("fetch", {
      route: "api.languages.index",
      name: "language",
      schema: schema,
      id: id,
      includes: includes,
      cb: cb
    });
  },
  async updateLanguage(context, { entity, includes, cb }) {
    await context.dispatch("update", {
      route: "api.languages.index",
      name: "language",
      schema: schema,
      entity: entity,
      includes: includes,
      cb: cb
    });
  },
  async storeLanguage(context, { entity, includes, cb }) {
    await context.dispatch("store", {
      route: "api.languages.index",
      name: "language",
      schema: schema,
      entity: entity,
      includes: includes,
      cb: cb
    });
  },
  async destroyLanguage(context, { id, cb }) {
    await context.dispatch("destroy", {
      route: "api.languages.index",
      name: "language",
      id: id,
      cb: cb
    });
  }
};
