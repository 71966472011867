import { UNINIT } from "../../sync-states";
import { address as schema } from "@/store/schema";

const route = "api.addresses.index";
const name = "address";

// state
export const state = {
  address: {},
  addressSync: UNINIT,
  addressIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchAddress(context, { id, includes, filters, cb }) {
    return await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes,
      filters,
      cb
    });
  },
  async updateAddress(context, { entity, includes, cb }) {
    return await context.dispatch("update", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async storeAddress(context, { entity, includes, cb }) {
    return await context.dispatch("store", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async destroyAddress(context, { id, cb }) {
    await context.dispatch("destroy", { route, name, id, cb });
  }
};
