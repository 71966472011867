<script>
import EntityDataProvider from "@/components/legacy/generic/data/EntityDataProvider";

export default {
  name: "select-mixin",
  components: { EntityDataProvider },
  inject: ["$validator"],
  props: {
    value: {
      type: [Number, String, Array],
      required: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    itemText: {
      type: String,
      default: "name"
    },
    itemValue: {
      type: String,
      default: "id"
    },

    dataVvRules: {
      type: String,
      required: false
    },
    dataVvName: {
      type: String,
      required: false
    },
    errorMessages: {
      type: Array,
      required: false
    }
  },
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", Object.is(newValue, undefined) ? null : newValue);
      }
    },
    internalLabel() {
      return this.label;
    }
  }
};
</script>
