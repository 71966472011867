export default [
  {
    path: "/backoffice/employees",
    name: "employees",
    component: () =>
      import(
        /* webpackChunkName: "employees" */ "@/views/employees/EmployeesView"
      ),
    meta: {
      title: "Employees"
    }
  },
  {
    path: "/backoffice/employees/create",
    name: "employees.create",
    component: () =>
      import(
        /* webpackChunkName: "employees" */ "@/views/employees/EmployeeCreateView"
      ),
    meta: {
      title: "Employees"
    }
  },
  {
    path: "/backoffice/employees/:employeeId",
    name: "employees.show",
    component: () =>
      import(
        /* webpackChunkName: "employees" */ "@/views/employees/EmployeeView"
      ),
    props: route => ({
      employeeId: parseInt(route.params.employeeId)
    }),
    meta: {
      title: "Employees"
    }
  }
];
