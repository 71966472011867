import Vue from "vue";
import { createApiUrl } from "@/api/url";

async function search(searchString) {
  const url = createApiUrl(`api/v1/backoffice/search?q=${searchString}`);
  const { data } = await Vue.$http.get(url);
  return data.data;
}

export default {
  search
};
