import {
  normalize as internalNormalize,
  website as schema
} from "@/store/schema";
import { createApiUrl, cancellableRequest } from "../../url";

const resourceUrl = createApiUrl("api/v1/stats/articles/timetable/");

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    pagination
  });
  const { data } = await cancellableRequest("get", url, resourceUrl);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function fetchConversionsReport({ filters, pagination } = {}) {
  const conversionsUrl = createApiUrl("api/v1/stats/article-statistics/");
  const url = createApiUrl(conversionsUrl, {
    filters,
    pagination
  });

  const { data } = await cancellableRequest("GET", url, conversionsUrl);
  const { entities, result } = normalize(data.data, schema);
  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

export default {
  normalize,
  fetchAll,
  fetchConversionsReport
  // fetchConversionsReportQualityLabels
};
