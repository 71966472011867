<template>
  <v-select
    v-model="internalValue"
    v-validate="dataVvRules"
    :items="items"
    :loading="isLoading"
    :label="internalLabel"
    :item-text="itemText"
    :item-value="itemValue"
    :clearable="clearable"
    :disabled="disabled"
    :readonly="readonly"
    :multiple="multiple"
    :data-vv-name="dataVvName"
    :error-messages="errorMessages"
    @change="changed"
  />
</template>

<script type="text/babel">
import SelectMixin from "@/components/mixins/SelectMixin";
import store from "@/store";
import localeModule from "@/store/modules/locales";
import { createNamespacedHelpers } from "vuex";
import {
  IS_ERROR as GETTER_IS_ERROR,
  IS_LOADING as GETTER_IS_LOADING,
  ITEMS as GETTER_ITEMS
} from "@/store/templates/table/getter-types";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";

const { mapGetters } = createNamespacedHelpers("locales");

export default {
  name: "v-select-locale",
  mixins: [SelectMixin],
  created() {
    if (!store.state.locales) {
      store.registerModule("locales", localeModule);
      this.$store.dispatch(`locales/${DISPATCH_FETCH}`);
    }
  },
  computed: {
    ...mapGetters([GETTER_IS_LOADING, GETTER_IS_ERROR, GETTER_ITEMS]),
    internalLabel() {
      return this.label || (this.multiple ? "Locales" : "Locale");
    }
  },
  methods: {
    changed(val) {
      this.$emit("on-change", val);
    }
  }
};
</script>
