import store from "@/store";

export default async (to, from, next) => {
  if (to.matched.some(record => record.meta.noAuth)) {
    next(); // make sure to always call next()!
  } else {
    const isAuth = store.getters["auth/isAuthenticated"];
    if (!isAuth) {
      store
        .dispatch("auth/fetchUser")
        .then(() => {
          return store.dispatch("user-notifications/updateUser");
        })
        .then(() => {
          next();
        })
        .catch(e => {
          console.error(e);
          next("login");
        });
    } else {
      next();
    }
  }
};
