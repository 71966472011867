import Vue from "vue";
import {
  category as schema,
  normalize as internalNormalize
} from "@/store/schema";
import { createApiUrl } from "../url";

const resourceUrl = createApiUrl("api/v1/categories");

async function fetch(id, { includes = fetchIncludes } = {}) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, includes = fetchAllIncludes } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function fetchTreeData() {
  const t0 = performance.now();

  const url = createApiUrl(`${resourceUrl}/treeData`);
  const { data } = await Vue.$http.get(url);
  const t1 = performance.now();
  const { entities, result } = normalize(data.data, schema);

  const t2 = performance.now();

  console.debug("Data fetched in " + (t1 - t0) / 1000 + " seconds.");
  console.debug("Data normalized in " + (t2 - t1) / 1000 + " seconds.");

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function search() {
  const data = await Vue.$http.get(resourceUrl);
  return data.data.data;
}

async function create(category) {
  const { data } = await Vue.$http.post(resourceUrl, category);
  return normalize(data.data, schema);
}

async function update(categoryId, category) {
  const url = `${resourceUrl}/${categoryId}`;
  const { data } = await Vue.$http.put(url, category);
  return normalize(data.data, schema);
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = [
  "children",
  "parents",
  "categorySubjects.filterQuestionOptions",
  "categorySubjects.specifiers.options",
  "categorySubjects.subject.questions.options.parentOption.parentOption.parentOption",
  "categorySubjects.subject.questions.parentQuestion.parentQuestion.parentQuestion"
];
const fetchAllIncludes = ["parents", "children", "categorySubjects.subject"];

export default {
  normalize,
  fetch,
  fetchAll,
  create,
  update,
  search,
  fetchTreeData
};
