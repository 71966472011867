import Vue from "vue";
import { lead as leadSchema, normalize, offer as schema } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/leads");

async function create(lead, filter, subject, price = null, includes = null) {
  const url = createApiUrl(`${resourceUrl}/${lead}/offers`, { includes });
  const { data } = await Vue.$http.post(url, {
    lead: lead,
    filter: filter,
    subject: subject,
    price
  });
  return normalize(data.data, schema);
}

async function createGroup(lead, group) {
  const { data } = await Vue.$http.post(`${resourceUrl}/${lead}/offers/group`, {
    group
  });
  return normalize(data.data, leadSchema);
}

export default {
  create,
  createGroup
};
