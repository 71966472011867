import {
  normalize as internalNormalize,
  website as schema
} from "@/store/schema";
import { createApiUrl, cancellableRequest } from "../../url";

async function fetchInterestMap(filters) {
  const cancelUrl = createApiUrl("api/v1/stats/subjects/interest-map");
  const url = createApiUrl(`api/v1/stats/subjects/interest-map`, {
    filters
  });
  const { data } = await cancellableRequest("get", url, cancelUrl);
  return data.data;
}

async function fetchExclusiveInterest(filters) {
  const cancelUrl = createApiUrl("api/v1/stats/subjects/exclusive-interest");
  const url = createApiUrl(`api/v1/stats/subjects/exclusive-interest`, {
    filters
  });
  const { data } = await cancellableRequest("get", url, cancelUrl);
  return data.data;
}

async function fetchWebsiteMap(filters) {
  const cancelUrl = createApiUrl("api/v1/stats/subjects/website-map");
  const url = createApiUrl(`api/v1/stats/subjects/website-map`, {
    filters
  });
  const { data } = await cancellableRequest("get", url, cancelUrl);
  return data.data;
}

async function fetchArticleMap(filters) {
  const cancelUrl = createApiUrl("api/v1/stats/subjects/article-map");
  const url = createApiUrl(`api/v1/stats/subjects/article-map`, {
    filters
  });
  const { data } = await cancellableRequest("get", url, cancelUrl);
  return data.data;
}

async function fetchSalesReportMap(filters) {
  const cancelUrl = createApiUrl("api/v1/stats/subjects/sales-report");
  const url = createApiUrl(`api/v1/stats/subjects/sales-report`, {
    filters
  });
  const { data } = await cancellableRequest("get", url, cancelUrl);
  return data.data;
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

export default {
  normalize,
  fetchInterestMap,
  fetchExclusiveInterest,
  fetchWebsiteMap,
  fetchArticleMap,
  fetchSalesReportMap
};
