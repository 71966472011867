import { find, uniq } from "lodash";
import tableGetters from "../../templates/table/getters";
import {
  AUTH_USER_LOCALE,
  AUTH_USER_LOCALE_CODE,
  AUTH_USER_LOCALE_CODE_JAVA,
  FIND_BY_CODE,
  GET_EMPTY_LOCALIZATION,
  GET_LOCALIZED_CURRENCY,
  GET_VISIBLE_LOCALE_CODES,
  GET_VISIBLE_LOCALES,
  SELECTED_LOCALES,
  USER_LOCALE
} from "./getter-types";

export default {
  ...tableGetters,
  [GET_VISIBLE_LOCALES]: state =>
    state.isLoading
      ? []
      : uniq([state[USER_LOCALE], ...state[SELECTED_LOCALES]]),
  [GET_VISIBLE_LOCALE_CODES]: (state, getters) =>
    getters[GET_VISIBLE_LOCALES].map(localeId => state.entity[localeId].code),
  [FIND_BY_CODE]: state => code => {
    return find(state.entity, locale => locale.code === code);
  },
  [SELECTED_LOCALES]: state => state.selectedLocales,
  [USER_LOCALE]: state => state.userLocale,
  [AUTH_USER_LOCALE]: state => state.entity[state.userLocale],
  [AUTH_USER_LOCALE_CODE]: (state, getters) =>
    getters[AUTH_USER_LOCALE]?.code ?? "nl_be",
  [AUTH_USER_LOCALE_CODE_JAVA]: (state, getters) => {
    const splitLocaleCode = getters[AUTH_USER_LOCALE_CODE].split("_");
    return `${splitLocaleCode[0]}-${splitLocaleCode[1].toUpperCase()}`;
  },
  [GET_EMPTY_LOCALIZATION]: state => {
    const localization = {
      id: null
    };
    Object.values(state.entity).forEach(({ code }) => {
      localization[code] = null;
    });
    return localization;
  },
  [GET_LOCALIZED_CURRENCY]: (state, getters) => amount => {
    const formatter = new Intl.NumberFormat(
      getters[AUTH_USER_LOCALE_CODE_JAVA],
      {
        style: "currency",
        currency: "EUR"
      }
    );
    return formatter.format(amount);
  }
};
