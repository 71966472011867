import { UNINIT } from "../../sync-states";
import { offerRejectionReason as schema } from "@/store/schema";

const name = "offerRejectionReason";
const route = "api.offer-rejection-reasons.index";

// state
export const state = {
  offerRejectionReason: {},
  offerRejectionReasonSync: UNINIT,
  offerRejectionReasonIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchOfferRejectionReason(context, { id, includes, cb }) {
    await context.dispatch("fetch", {
      route,
      name,
      schema: schema,
      id: id,
      includes: includes,
      cb: cb
    });
  }
};
