import { capitalize } from "lodash";

export default {
  addCrudSuccess({ commit }, { entityName = "entity", isCreate }) {
    entityName = capitalize(entityName);
    commit("addItem", {
      text: `${entityName} ${isCreate ? "created" : "updated"} successfully.`,
      color: "success"
    });
  },
  addItem({ commit }, { text, color }) {
    commit("addItem", { text, color });
  },
  addError({ commit }, { text }) {
    commit("addItem", { text, color: "error" });
  },
  addInfo({ commit }, { text }) {
    commit("addItem", { text, color: "info" });
  },
  removeCurrentItem({ commit }) {
    commit("removeCurrentItem");
  }
};
