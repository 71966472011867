export default [
  {
    path: "/delivery-rules",
    name: "delivery-rules",
    component: () =>
      import(
        /* webpackChunkName: "delivery-rules" */ "@/views/delivery-rules/DeliveryRulesView.vue"
      ),
    meta: {
      title: "Delivery-rules"
    },
    children: [
      {
        path: "create",
        name: "delivery-rules.create",
        component: () =>
          import(
            /* webpackChunkName: "delivery-rules" */ "@/views/delivery-rules/DeliveryRuleCreateView.vue"
          ),
        meta: {
          title: "Delivery-rules"
        }
      },
      {
        path: ":deliveryRuleId",
        component: () =>
          import(
            /* webpackChunkName: "delivery-rules" */ "@/views/delivery-rules/DeliveryRuleView.vue"
          ),
        meta: {
          title: "Delivery-rules"
        },
        children: [
          {
            path: "",
            name: "delivery-rules.show",
            props: route => ({ id: parseInt(route.params.deliveryRuleId) }),
            component: () =>
              import(
                /* webpackChunkName: "delivery-rules" */ "@/views/delivery-rules/DeliveryRuleDashboardView.vue"
              ),
            meta: {
              title: "Delivery-rules"
            }
          }
        ]
      }
    ]
  }
];
