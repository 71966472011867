<template>
  <div
    class="tw-flex tw-flex-col tw-w-64 tw-border-r tw-border-gray-200 tw-pt-5 tw-pb-4 tw-bg-gray-100"
  >
    <div class="tw-flex tw-items-center tw-flex-shrink-0 tw-px-6">
      <!-- :src="require('../assets/logo/blue.svg')" -->
      <!-- src="https://tailwindui.com/img/logos/workflow-logo-purple-on-white.svg" -->
      <img
        class="tw-h-8 tw-w-auto pr-3"
        :src="require('@/assets/logo/icon.svg')"
        alt="Buldit"
      />
      <span
        class="tw-text-primary tw-font-bold tw-uppercase tw-text-xl tw-leading-1"
        >Backoffice</span
      >
    </div>
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="tw-h-0 tw-flex-1 tw-flex tw-flex-col tw-overflow-y-auto">
      <!-- User account dropdown -->
      <b-profile-dropdown :items="profileItems" />
      <!-- Sidebar Search -->
      <b-sidebar-autocomplete />
      <!-- Navigation -->
      <nav class="tw-px-3 tw-mt-6">
        <div class="tw-space-y-1">
          <template v-for="(item, index) in items">
            <sidebar-group
              v-if="item.items && item.items.length > 0"
              :key="`${index}-group`"
              :group="item"
            />
            <sidebar-item v-else :key="`${index}`" :item="item" />
          </template>
        </div>
      </nav>
    </div>
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import SidebarItem from "./SidebarItem.vue";
import SidebarGroup from "./SidebarGroup.vue";
import RolesAndPermissionsMixin from "@/components/mixins/RolesAndPermissionsMixin";
import BProfileDropdown from "@/components/sidebar/profile/BProfileDropdown";
import BSidebarAutocomplete from "@/components/sidebar/search/BSidebarAutocomplete";

const { mapGetters } = createNamespacedHelpers("auth");

export default {
  name: "tailwind-sidebar",
  components: {
    BSidebarAutocomplete,
    BProfileDropdown,
    SidebarItem,
    SidebarGroup
  },
  mixins: [RolesAndPermissionsMixin],
  props: {
    items: {
      type: Array,
      required: true
    },
    profileItems: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    profile: false
  }),
  computed: {
    ...mapGetters(["hasRolesOrPermissions"])
  },
  methods: {
    filterAllowedRoutes(items) {
      return items.filter(item => {
        return (
          item.rolesOrPermissions === undefined ||
          this.hasRolesOrPermissions(item.rolesOrPermissions)
        );
      });
    }
  }
};
</script>
