import { isArray } from "lodash";
import { normalize as baseNormalize, schema } from "normalizr";

export const normalize = (data, schema) => {
  if (isArray(data) && !isArray(schema)) {
    schema = [schema];
  }
  const normalizedData = baseNormalize(data, schema);
  const schemaKey = isArray(schema) ? schema[0].key : schema.key;
  normalizedData.entities.entity = normalizedData.entities[schemaKey];

  return normalizedData;
};

export const address = new schema.Entity("address");

export const buddy = new schema.Entity("buddy");

export const file = new schema.Entity("file");

export const invoice = new schema.Entity("invoice");
export const creditNote = new schema.Entity("creditNote");

export const annotation = new schema.Entity("annotation");
export const annotationType = new schema.Entity("annotationType");
export const annotationSubType = new schema.Entity("annotationSubType");

export const category = new schema.Entity("category");
export const categorySubject = new schema.Entity("categorySubject");

export const company = new schema.Entity("company");
export const deliveryMethod = new schema.Entity("deliveryMethod");
export const contactPerson = new schema.Entity("contactPerson");
export const filter = new schema.Entity("filter");
export const filterCategory = new schema.Entity("filterCategory");
export const url = new schema.Entity("url");

export const question = new schema.Entity("question");
export const questionOption = new schema.Entity("questionOption");
export const subject = new schema.Entity("subject");
export const form = new schema.Entity("form");
export const submission = new schema.Entity("submission");
export const submissionOriginalData = new schema.Entity(
  "submissionOriginalData"
);
export const submissionField = new schema.Entity("submissionField");
export const submissionFieldOption = new schema.Entity("submissionFieldOption");
export const submissionRefinement = new schema.Entity("submissionRefinement");
export const submissionValidation = new schema.Entity("submissionValidation");

export const website = new schema.Entity("website");
export const article = new schema.Entity("article");
export const articleCluster = new schema.Entity("articleCluster");
export const articleSibling = new schema.Entity("articleSibling");
export const categoryCompany = new schema.Entity("categoryCompany");
export const companySubject = new schema.Entity("companySubject");
export const billingData = new schema.Entity("billingData");
export const companySubjectCountry = new schema.Entity("companySubjectCountry");
export const sibling = new schema.Entity("sibling");
export const timeframeMetric = new schema.Entity("timeframeMetric");
export const articleAnalyticsMetric = new schema.Entity(
  "articleAnalyticsMetric"
);
export const pageTypeMetric = new schema.Entity("pageTypeMetric");
export const role = new schema.Entity("role");
export const tag = new schema.Entity("tag");
export const tagType = new schema.Entity("tagType");
export const tagSubType = new schema.Entity("tagSubType");
export const permission = new schema.Entity("permission");

export const country = new schema.Entity("country");
export const province = new schema.Entity("province");
export const city = new schema.Entity("city");
export const language = new schema.Entity("language");
export const locale = new schema.Entity("locale");
export const log = new schema.Entity("log");
export const filterLog = new schema.Entity("filterLog");

export const user = new schema.Entity("user");
export const employee = new schema.Entity("employee");
export const customer = new schema.Entity("customer");
export const potentialOffer = new schema.Entity("potentialOffer");

export const lead = new schema.Entity("lead");
export const leadField = new schema.Entity("leadField");
export const leadFieldOption = new schema.Entity("leadFieldOption");

export const offer = new schema.Entity("offer");
export const offerField = new schema.Entity("offerField");
export const offerFieldOption = new schema.Entity("offerFieldOption");
export const offerRejectionReason = new schema.Entity("offerRejectionReason");

export const contentPlan = new schema.Entity("contentPlan");
export const subjectTopic = new schema.Entity("subjectTopic");
export const keywordCluster = new schema.Entity("keywordCluster");
export const keyword = new schema.Entity("keyword");
export const localization = new schema.Entity("localization");
export const keywordLocale = new schema.Entity("keywordLocale");

export const refinementRule = new schema.Entity("refinementRule");
export const validationRule = new schema.Entity("validationRule");
export const deliveryRule = new schema.Entity("deliveryRule");
export const separationRule = new schema.Entity("separationRule");
export const separationRuleGroup = new schema.Entity("separationRuleGroup");
export const ruleTriggerGroup = new schema.Entity("ruleTriggerGroup");
export const submissionTrigger = new schema.Entity("submissionTrigger");
export const ruleTriggerQuestion = new schema.Entity("ruleTriggerQuestion");

export const contactMessage = new schema.Entity("contactMessage");
export const largeLocalization = new schema.Entity("largeLocalization");
export const rejectReason = new schema.Entity("rejectReason");

export const notification = new schema.Entity("notification");
export const subscription = new schema.Entity("subscription");
export const incident = new schema.Entity("incident");

export const telephoneNumber = new schema.Entity("telephoneNumber");
export const specifier = new schema.Entity("specifier");

user.define({
  employee: employee,
  roles: [role],
  permissions: [permission],
  notifications: [notification]
});

role.define({
  permissions: [permission]
});

invoice.define({
  company: company,
  createdBy: user,
  openedBy: user,
  paidBy: user
});

creditNote.define({
  invoice,
  draftedBy: user,
  finalizedBy: user
});

address.define({
  city,
  province,
  country
});

annotation.define({
  subTypes: [annotationSubType],
  type: annotationType,
  websites: [website],
  articles: [article],
  subjects: [subject],
  keywords: [keyword],
  files: [file]
});

annotationSubType.define({
  type: annotationType
});

article.define({
  website: website,
  timeframeMetric: timeframeMetric,
  pageTypeMetric: pageTypeMetric,
  locales: [locale],
  subjects: [subject],
  annotations: [annotation],
  siblings: [sibling],
  tags: [tag],
  cluster: articleCluster
});

articleCluster.define({
  articleSiblings: [articleSibling]
});

articleSibling.define({
  article: article,
  articleCluster: articleCluster
});

refinementRule.define({
  triggers: [ruleTriggerGroup]
});

contentPlan.define({
  subjectTopics: [subjectTopic]
});

subjectTopic.define({
  keywordClusters: [keywordCluster]
});

keywordCluster.define({
  keywords: [keyword]
});

keyword.define({
  keywordCluster: keywordCluster,
  locales: [keywordLocale],
  tags: [tag]
});

contactPerson.define({
  company
});

telephoneNumber.define({
  buddies: [buddy]
});

log.define({
  company,
  filters: [filter]
});
filterLog.define({
  company
});

url.define({
  company
});

validationRule.define({
  triggers: [ruleTriggerGroup],
  rejectReason: rejectReason
});

deliveryRule.define({
  triggers: [ruleTriggerGroup]
});

separationRule.define({
  groups: [separationRuleGroup],
  triggers: [ruleTriggerGroup]
});

separationRuleGroup.define({
  rule: separationRule,
  subjects: [subject]
});

submissionTrigger.define({
  group: ruleTriggerGroup,
  subjects: [subject],
  languages: [language],
  countries: [country],
  triggerQuestions: [ruleTriggerQuestion]
});

ruleTriggerQuestion.define({
  trigger: submissionTrigger,
  question: question,
  options: [questionOption]
});

employee.define({
  user: user,
  evaluator: employee
});

buddy.define({
  user: user,
  address,
  submissions: [submission]
});

customer.define({
  user,
  companies: [company]
});

company.define({
  country,
  categoryCompanies: [categoryCompany],
  companySubject: [companySubject],
  categories: [category],
  fiscalCountry: country,
  language,
  city,
  contactPersons: [contactPerson],
  customers: [customer],
  logs: [log],
  filterLogs: [filterLog],
  urls: [url],
  filters: [filter],
  deliveryMethods: [deliveryMethod],
  offers: [offer],
  address: address,
  fiscalAddress: address
});

companySubject.define({
  company: company,
  subject: subject,
  countries: [companySubjectCountry]
});

billingData.define({
  country: country
});

categoryCompany.define({
  company: company,
  category: category
});

deliveryMethod.define({
  filter,
  recipients: [contactPerson],
  company: company
});

filter.define({
  company,
  original: filter,
  filterCategories: [{ category }],
  filters: [{ filter }],
  parentFilters: [{ filter }],
  languages: [language],
  country: country,
  logs: [log],
  provinces: [province],
  cities: [city],
  deliveryMethods: [deliveryMethod]
});

website.define({
  siblings: [website],
  locales: [locale],
  articles: [article],
  annotations: [annotation],
  timeframeMetric: timeframeMetric,
  articleSiblings: [articleSibling]
});

category.define({
  filter: filter,
  children: [category],
  parents: [category],
  categorySubjects: [categorySubject]
});

categorySubject.define({
  subject
});

subject.define({
  parent: subject,
  children: [subject],
  label: localization,
  countries: [country],
  questions: [question]
});

question.define({
  label: localization,
  parent: question,
  root: question,
  options: [questionOption]
});

questionOption.define({
  parent: questionOption,
  root: questionOption,
  label: localization
});

offerRejectionReason.define({
  label: localization,
  info: localization
});

submission.define({
  originalData: submissionOriginalData,
  address,
  telephoneNumbers: [telephoneNumber],
  form,
  buddy,
  website,
  article,
  country,
  province,
  city,
  language,
  subjects: [subject],
  originalSubjects: [subject],
  similars: [submission],
  leads: [lead],
  validations: [submissionValidation],
  refinements: [submissionRefinement],
  rejectReason: rejectReason,
  fields: [submissionField]
});

submissionOriginalData.define({
  submission
});

submissionRefinement.define({
  submission,
  refinementRule
});

submissionValidation.define({
  submission,
  validationRule
});

form.define({
  locales: [locale]
});

submissionField.define({
  options: [submissionFieldOption],
  submission
});

country.define({
  provinces: [province],
  cities: [city]
});

province.define({
  country,
  cities: [city]
});

city.define({
  country,
  province,
  municipality: city
});

lead.define({
  submission,
  language,
  country,
  fields: [leadField],
  defaultSubject: subject,
  subjects: [subject],
  categories: [category],
  offers: [offer],
  potentialOffers: [potentialOffer]
});

leadField.define({
  submissionField,
  options: [leadFieldOption]
});

leadFieldOption.define({
  option: questionOption,
  inheritedOptions: questionOption
});

potentialOffer.define({
  lead,
  filter,
  subject,
  offer
});

offer.define({
  lead,
  subject,
  company,
  customer,
  filter,
  potentialOffer,
  bulditFilter: filter,
  fields: [offerField],
  rejectionReason: offerRejectionReason
});

offerField.define({
  offer,
  lead,
  leadField,
  submission,
  submissionField,
  question,
  inheritedQuestion: question,
  options: [offerFieldOption]
});

offerFieldOption.define({
  offer,
  offerField,
  submissionFieldOption,
  lead,
  leadFieldOption,
  submission,
  option: questionOption,
  inheritedOption: questionOption
});

tag.define({
  subType: tagSubType,
  type: tagType
});

tagSubType.define({
  type: tagType
});

incident.define({});
subscription.define({});
