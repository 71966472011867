import Vue from "vue";
import {
  company as schema,
  normalize as internalNormalize
} from "@/store/schema";
import { createApiUrl } from "../url";
import log from "./log";
import offer from "./offer";
import category from "./category";
import subject from "./subject";
import deliveryMethod from "./delivery-method";
import contactPerson from "./contact-person";
import filter from "./filter";
import filterLog from "./filter-log";
import invoice from "./invoice";

const resourceUrl = createApiUrl("api/v1/companies");
const resourcePasswordUrl = createApiUrl("api/v1/password");
const resourceEmailUrl = createApiUrl("api/v1/email");
const resourceValidate = createApiUrl("api/v1/validate/vat");

async function fetch(id, { includes = fetchIncludes } = {}) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({
  filters,
  pagination,
  includes = fetchAllIncludes
} = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function create(company) {
  const { data } = await Vue.$http.post(resourceUrl, company);
  return normalize(data.data, schema);
}

async function update(companyId, company) {
  const { data } = await Vue.$http.put(`${resourceUrl}/${companyId}`, company);
  return normalize(data.data, schema);
}

async function remove(companyId) {
  const { data } = await Vue.$http.delete(`${resourceUrl}/${companyId}`);
  return normalize(data.data, schema);
}

async function updateNotes(companyId, notes) {
  const { data } = await Vue.$http.put(
    `${resourceUrl}/${companyId}/updateNotes`,
    {
      notes
    }
  );
  return normalize(data.data, schema);
}

async function updateSalesData(companyId) {
  const { data } = await Vue.$http.get(`${resourceUrl}/${companyId}/salesData`);
  return normalize(data.data, schema);
}

async function isValidVat(vatNumber) {
  const data = await Vue.$http.get(
    `${resourceValidate}/${vatNumber.vatNumber}/validate`
  );
  return data.data;
}

async function isVerifiedVat(vatNumber) {
  const data = await Vue.$http.get(
    `${resourceValidate}/${vatNumber.vatNumber}/verify`
  );
  return data.data;
}

async function makeCustomer(companyId, customerData) {
  const { data } = await Vue.$http.post(
    `${resourceUrl}/${companyId}/customer`,
    customerData
  );
  return normalize(data.data, schema);
}

async function updateContactPersons(companyId, contactPersons) {
  const { data } = await Vue.$http.put(
    `${resourceUrl}/${companyId}/update-contact-persons`,
    {
      contactPersons
    }
  );
  return normalize(data.data, schema);
}

async function updateCustomers(companyId, customers) {
  const { data } = await Vue.$http.put(
    `${resourceUrl}/${companyId}/update-customers`,
    {
      customers
    }
  );
  return normalize(data.data, schema);
}

async function fetchStats({ filters }) {
  const url = createApiUrl(`${resourceUrl}/${filters.id}/stats`, {
    filters: filters
  });
  const { data } = await Vue.$http.get(url);
  return data.data;
}

async function fetchSubjectsStats({ filters }) {
  const url = createApiUrl(`${resourceUrl}/subjects-stats`, {
    filters: filters
  });
  const { data } = await Vue.$http.get(url);
  return data.data;
}

async function passwordReset(payload) {
  try {
    return await Vue.$http.post(
      `${resourcePasswordUrl}/request-backoffice`,
      payload
    );
  } catch (e) {
    return e;
  }
}

async function newEmailRequest(payload) {
  try {
    return await Vue.$http.post(`${resourceEmailUrl}/request`, payload);
  } catch (e) {
    return e;
  }
}

async function savePausedSettings(companyId, payload) {
  try {
    return await Vue.$http.post(
      `${resourceUrl}/${companyId}/save-paused-settings`,
      payload
    );
  } catch (e) {
    return e;
  }
}

async function saveGeneralSettings(companyId, payload) {
  try {
    return await Vue.$http.post(
      `${resourceUrl}/${companyId}/save-general-settings`,
      payload
    );
  } catch (e) {
    return e;
  }
}

async function saveFilterComment(companyId, comment) {
  try {
    return await Vue.$http.put(
      `${resourceUrl}/${companyId}/save-filter-comment`,
      comment
    );
  } catch (e) {
    return e;
  }
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = [
  "country",
  "customers",
  "fiscalCountry",
  "contactPersons",
  "urls",
  "address.country",
  "fiscalAddress.country",
  "language",
  "billingData.country"
];
const fetchAllIncludes = ["country", "customers", "language", "categories"];

export default {
  normalize,
  fetch,
  fetchAll,
  update,
  remove,
  create,
  updateNotes,
  updateSalesData,
  log,
  offer,
  category,
  subject,
  deliveryMethod,
  contactPerson,
  makeCustomer,
  filter,
  filterLog,
  fetchStats,
  fetchSubjectsStats,
  passwordReset,
  newEmailRequest,
  savePausedSettings,
  saveGeneralSettings,
  saveFilterComment,
  updateContactPersons,
  isValidVat,
  isVerifiedVat,
  invoice,
  updateCustomers
};
