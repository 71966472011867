import Vue from "vue";
import { normalize } from "normalizr";
import { separationRule as schema } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/separation-rules");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function create(separationRule) {
  const { data } = await Vue.$http.post(resourceUrl, separationRule);
  return normalize(data.data, schema);
}

async function update(separationRuleId, separationRule) {
  const url = `${resourceUrl}/${separationRuleId}`;
  const { data } = await Vue.$http.put(url, separationRule);
  return normalize(data.data, schema);
}

const fetchIncludes = [
  "triggerGroups.triggers.subjects",
  "triggerGroups.triggers.countries",
  "triggerGroups.triggers.languages",
  "triggerGroups.triggers.triggerQuestions.question.label",
  "triggerGroups.triggers.triggerQuestions.question.options.label",
  "triggerGroups.triggers.triggerQuestions.options"
];

const fetchAllIncludes = [];

export default {
  fetch,
  fetchAll,
  create,
  update
};
