import Vue from "vue";
import { normalize as internalNormalize, role as schema } from "@/store/schema";
import { createApiUrl } from "../url";

const resourceUrl = createApiUrl("api/v1/roles");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function search() {
  const data = await Vue.$http.get(resourceUrl);
  return data.data.data;
}

async function create(role) {
  const { data } = await Vue.$http.post(resourceUrl, role);
  return normalize(data.data, schema);
}

async function update(roleId, role) {
  const url = `${resourceUrl}/${roleId}`;
  const { data } = await Vue.$http.put(url, role);
  return normalize(data.data, schema);
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = ["permissions"];
const fetchAllIncludes = ["permissions"];

export default {
  normalize,
  fetch,
  fetchAll,
  create,
  update,
  search
};
