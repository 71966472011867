import { FAILURE, IN_SYNC, LOADING, UNINIT } from "@/store/sync-states";
import {
  localization as schemaLocalization,
  subjectTopic as schema
} from "@/store/schema";
import { normalize } from "normalizr";
import { createApiUrl } from "@/api/url";
import Vue from "vue";
import { isNil } from "lodash";

const resourceUrl = createApiUrl("api/v1/subject-topics");
const name = "subjectTopic";

// state
export const state = {
  subjectTopic: {},
  subjectTopicSync: UNINIT,
  subjectTopicIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchSubjectTopic(context, { id, includes, filters, cb }) {
    try {
      const url = createApiUrl(`${resourceUrl}/${id}`, { includes, filters });
      context.commit("setSync", { name: name, id: id, sync: LOADING });
      const { data } = await Vue.$http.get(url);
      let normalizedData = normalize(data.data, isNil(id) ? [schema] : schema);
      context.commit("updateEntities", {
        entities: normalizedData.entities,
        reactiveName: name,
        reactiveId: normalizedData.result
      });
      context.commit("setSync", { name: name, id: id, sync: IN_SYNC });

      if (cb) cb();
      return normalizedData.result;
    } catch (error) {
      context.commit("setSync", { name: name, id: id, sync: FAILURE });
      console.error(error);
    }
  },
  async destroySubjectTopic(context, { id, cb }) {
    await context.dispatch("destroy", {
      route: "api.subject-topics.index",
      name: "subjectTopic",
      id,
      cb
    });
  },
  async storeSubjectTopic(context, { subjectTopId, entity }) {
    try {
      const { data } = await Vue.$http.post(resourceUrl, {
        entity,
        subjectTopId
      });
      let normalizedData = normalize(data.data, schema);
      context.commit("updateEntities", { entities: normalizedData.entities });
      context.commit("setSync", {
        name: name,
        id: normalizedData.result,
        sync: IN_SYNC
      });
      return normalizedData.result;
    } catch (error) {
      console.error(error);
    }
  },
  async updateSubjectTopic(context, { subjectTopicId, entity }) {
    const url = `${resourceUrl}/${subjectTopicId}`;
    try {
      const { data } = await Vue.$http.put(url, entity);
      let normalizedData = normalize(data.data, schema);
      let normalizedDataLocalization = normalize(
        data.data.localization,
        schemaLocalization
      );
      context.commit("updateEntities", { entities: normalizedData.entities });
      context.commit("updateEntities", {
        entities: normalizedDataLocalization.entities
      });
      context.commit("setSync", {
        name: "subjectTopic",
        id: normalizedData.result,
        sync: IN_SYNC
      });
      return normalizedData.result;
    } catch (error) {
      console.error(error);
    }
  }
};
