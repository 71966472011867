import Vue from "vue";
import { normalize, submission as schema } from "@/store/schema";
import { createApiUrl } from "@/api/url";

/**
 * Sends a PUT request to the submission field endpoint to update it's value. and returns a suv
 *
 * @param submissionId
 * @param submissionFieldId
 * @param value
 * @returns {Promise<{entities: any; result: any}>}
 */
async function update(submissionId, submissionFieldId, value) {
  const url = createApiUrl(
    `api/v1/submissions/${submissionId}/fields/${submissionFieldId}/`
  );
  const { data } = await Vue.$http.put(url, { value });
  return normalize(data.data, schema);
}

export default {
  update
};
