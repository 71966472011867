import Vue from "vue";
import store from "./../../store";
import router from "./../../router";
import { plugin } from "@buldit/common-fe";
import getterOverrides from "../../store/modules/auth/getters";
import generateGenericResponseInterceptor from "@/plugins/axios/interceptors/response/generic";

Vue.use(plugin, {
  store,
  router,
  getterOverrides,
  axiosResponseInterceptorGenerators: [generateGenericResponseInterceptor]
});
