// locales.js
export const GET_VISIBLE_LOCALES = "getVisibleLocales";
export const GET_VISIBLE_LOCALE_CODES = "getVisibleLocaleCodes";

export const SELECTED_LOCALES = "selectedLocales";
export const USER_LOCALE = "userLocale";
export const AUTH_USER_LOCALE = "authUserLocale";
export const AUTH_USER_LOCALE_CODE = "authUserLocaleCode";
export const AUTH_USER_LOCALE_CODE_JAVA = "authUserLocaleCodeJava";

export const FIND_BY_CODE = "findByCode";

export const GET_EMPTY_LOCALIZATION = "getEmptyLocalization";
export const GET_LOCALIZED_CURRENCY = "getLocalizedCurrency";
