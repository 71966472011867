import Vue from "vue";
import { url as createUrl } from "@buldit/common-fe";

const resourceUrl = createUrl("api/v2/affiliates");

export async function findAll({ filters, fields, include, pagination } = {}) {
  const url = createUrl(resourceUrl, {
    searchParams: {
      filter: filters,
      fields,
      include,
      pagination
    }
  });

  return await Vue.$http.get(url, { cache: true });
}

export default {
  findAll
};
