import { UNINIT } from "../../sync-states";

// state
export const state = {
  separationRuleGroup: {},
  separationRuleGroupSync: UNINIT,
  separationRuleGroupIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {};
