import { UNINIT } from "../../sync-states";
import { rejectReason as schema } from "@/store/schema";

// state
export const state = {
  rejectReason: {},
  rejectReasonSync: UNINIT,
  rejectReasonIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchRejectReason(context, { id, includes }) {
    return await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes
    });
  },
  async updateRejectReason(context, { rejectReason, includes }) {
    return await context.dispatch("update", {
      route,
      name,
      schema,
      entity: rejectReason,
      includes
    });
  },
  async storeRejectReason(context, { rejectReason, includes }) {
    return await context.dispatch("store", {
      route,
      name,
      schema,
      entity: rejectReason,
      includes
    });
  },
  async destroyRejectReason(context, { id }) {
    return await context.dispatch("destroy", { route, name, id });
  }
};

const name = "rejectReason";
const route = "api.reject-reasons.index";
