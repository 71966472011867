export default [
  {
    path: "/backoffice/articles",
    name: "articles",
    component: () =>
      import(
        /* webpackChunkName: "articles" */ "@/views/articles/ArticlesView"
      ),
    meta: {
      title: "Articles"
    },
    children: [
      {
        path: "/backoffice/articles/:articleId",
        component: () =>
          import(
            /* webpackChunkName: "articles" */ "@/views/articles/ArticleView"
          ),
        meta: {
          title: "Article Details"
        },
        children: [
          {
            path: "",
            name: "articles.show",
            props: route => ({ id: parseInt(route.params.articleId) }),
            component: () =>
              import(
                /* webpackChunkName: "articles" */ "@/views/articles/ArticleDashboardView"
              ),
            meta: {
              title: "Article Details - dash"
            }
          },
          {
            path: "submissions",
            name: "articles.submissions",
            props: route => ({ id: parseInt(route.params.articleId) }),
            component: () =>
              import(
                /* webpackChunkName: "articles" */ "@/views/articles/ArticleSubmissionsView"
              ),
            meta: {
              title: "Article submissions"
            }
          },
          {
            path: "research",
            name: "articles.research",
            props: route => ({ id: parseInt(route.params.articleId) }),
            component: () =>
              import(
                /* webpackChunkName: "articles" */ "@/views/articles/ArticleResearchView"
              ),
            meta: {
              title: "Article Research"
            }
          },
          {
            path: "settings",
            name: "articles.settings",
            props: route => ({ id: parseInt(route.params.articleId) }),
            component: () =>
              import(
                /* webpackChunkName: "articles" */ "@/views/articles/ArticleSettingsView"
              ),
            meta: {
              title: "Article Settings"
            }
          },
          {
            path: "siblings",
            name: "articles.siblings",
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "@/views/articles/ArticleSiblingsView.vue"
              ),
            meta: {
              title: "Siblings"
            }
          }
        ]
      }
    ]
  }
];
