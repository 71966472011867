export default [
  {
    path: "/backoffice/tags",
    name: "tags",
    component: () =>
      import(/* webpackChunkName: "tags" */ "@/views/tags/TagsView"),
    meta: {
      title: "Tags"
    }
  },
  {
    path: "/backoffice/tags/create",
    name: "tags.create",
    component: () =>
      import(/* webpackChunkName: "tags" */ "@/views/tags/TagCreateView"),
    meta: {
      title: "Tags"
    }
  },
  {
    path: "/backoffice/tags/:tagId",
    name: "tags.show",
    component: () =>
      import(/* webpackChunkName: "tags" */ "@/views/tags/TagView"),
    props: route => ({
      tagId: parseInt(route.params.tagId)
    }),
    meta: {
      title: "Tags"
    }
  }
];
