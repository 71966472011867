export default [
  {
    path: "/backoffice/companies",
    name: "companies",
    component: () =>
      import(
        /* webpackChunkName: "companies" */ "@/views/companies/CompaniesView"
      ),
    //props: overviewPropsFn,
    meta: {
      title: "Companies"
    }
  },
  {
    path: "/backoffice/companies/create",
    name: "companies.create",
    component: () =>
      import(
        /* webpackChunkName: "companies" */ "@/views/companies/CompanyCreateView"
      ),
    props: {
      create: true
    },
    meta: {
      title: "Companies"
    }
  },
  {
    path: "/backoffice/companies/:companyId",
    component: () =>
      import(
        /* webpackChunkName: "companies" */ "@/views/companies/CompanyView"
      ),
    meta: {
      title: "Company"
    },
    children: [
      {
        path: "",
        name: "company.show",
        props: route => ({ id: parseInt(route.params.companyId) }),
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "@/views/companies/CompanyDetailsView.vue"
          ),
        meta: {
          title: "Company Details"
        }
      },
      {
        path: "settings",
        name: "company.settings",
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "@/views/companies/settings/CompanySettingsView.vue"
          ),
        meta: {
          title: "Company settings"
        }
      },
      {
        path: "filters",
        name: "company.filters",
        component: () =>
          import(
            /* webpackChunkName: "companies-filters" */ "@/views/companies/filters/CompanyFiltersView.vue"
          ),
        meta: {
          title: "Company filters"
        }
      },
      {
        path: "filter-logs",
        name: "company.filter-logs",
        component: () =>
          import(
            /* webpackChunkName: "companies-filters" */ "@/views/companies/CompanyFilterLogsView.vue"
          ),
        meta: {
          title: "Company filter logs"
        }
      },
      {
        path: "filters/create",
        name: "company.filters.create",
        component: () =>
          import(
            /* webpackChunkName: "companies-filters" */ "@/views/companies/CompanyFilterCreateView.vue"
          ),
        meta: {
          title: "Create company filter"
        }
      },
      {
        path: "filters/:filterId",
        name: "company.filters.show",
        component: () =>
          import(
            /* webpackChunkName: "companies-filters" */ "@/views/companies/CompanyFilterView.vue"
          ),
        meta: {
          title: "Filter details"
        },
        props(route) {
          return {
            id: parseInt(route.params.id),
            filterId: parseInt(route.params.filterId)
          };
        }
      },
      {
        path: "filters/create",
        name: "company.filters.create",
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "@/views/companies/filters/CompanyFilterCreateView.vue"
          ),
        meta: {
          title: "Company filters"
        }
      },
      {
        path: "filters/:filterId",
        name: "company.filters.show",
        props: route => ({ id: parseInt(route.params.filterId) }),
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "@/views/companies/filters/CompanyFilterView.vue"
          ),
        meta: {
          title: "Company filters"
        }
      },
      {
        path: "categories",
        name: "company.categories",
        props: route => ({ id: parseInt(route.params.companyId) }),
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "@/views/companies/CompanyCategoriesView.vue"
          ),
        meta: {
          title: "Company categories"
        }
      },
      {
        path: "subjects",
        name: "company.subjects",
        props: route => ({ id: parseInt(route.params.companyId) }),
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "@/views/companies/CompanySubjectsView.vue"
          ),
        meta: {
          title: "Company subjects"
        }
      },
      {
        path: "subjects/create",
        name: "company.subjects.create",
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "@/views/companies/subjects/CompanySubjectsCreateView.vue"
          ),
        meta: {
          title: "Company subjects"
        }
      },
      {
        path: "delivery-methods",
        name: "company.delivery-methods",
        props: route => ({ id: parseInt(route.params.companyId) }),
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "@/views/companies/delivery-methods/CompanyDeliveryMethodsView.vue"
          ),
        meta: {
          title: "Company delivery methods"
        }
      },
      {
        path: "delivery-methods/create",
        name: "company.delivery-method.create",
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "@/views/companies/delivery-methods/CompanyDeliveryMethodCreateView.vue"
          ),
        meta: {
          title: "Company delivery methods"
        }
      },
      {
        path: "delivery-methods/:deliveryMethodId",
        name: "company.delivery-method.show",
        props: route => ({ id: parseInt(route.params.deliveryMethodId) }),
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "@/views/companies/delivery-methods/CompanyDeliveryMethodView.vue"
          ),
        meta: {
          title: "Company delivery methods"
        }
      },
      {
        path: "leads",
        name: "company.leads",
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "@/views/companies/CompanyOffersView.vue"
          ),
        meta: {
          title: "Company leads"
        }
      },
      {
        path: "statistics",
        name: "company.statistics",
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "@/views/companies/statistics/CompanyStatisticsView.vue"
          ),
        meta: {
          title: "Company statistics"
        }
      },
      {
        path: "invoices",
        name: "company.invoices",
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "@/views/invoices/InvoicesView.vue"
          ),
        props: route => ({ companyId: parseInt(route.params.companyId) }),
        meta: {
          title: "Company invoices"
        }
      },
      {
        path: "invoices/create",
        name: "company.invoices.create",
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "@/views/invoices/InvoiceCreateView.vue"
          ),
        props: route => ({ companyId: parseInt(route.params.companyId) }),
        meta: {
          title: "Company invoices"
        }
      },
      {
        path: "invoices/:invoiceId",
        name: "company.invoices.show",
        props: route => ({
          invoiceId: parseInt(route.params.invoiceId),
          companyId: parseInt(route.params.companyId)
        }),
        component: () =>
          import(
            /* webpackChunkName: "companies" */ "@/views/invoices/InvoiceView.vue"
          ),
        meta: {
          title: "Invoice"
        },
        children: [
          {
            path: "credit-notes/create",
            name: "company.invoices.credit-notes.create",
            component: () =>
              import(
                /* webpackChunkName: "invoices" */ "@/views/invoices/credit-notes/CreditNoteCreateView.vue"
              ),
            props: route => ({
              companyId: parseInt(route.params.companyId)
            }),
            meta: {
              title: "Credit note"
            }
          },
          {
            path: "credit-notes/:creditNoteId",
            name: "company.invoices.credit-notes.show",
            component: () =>
              import(
                /* webpackChunkName: "invoices" */ "@/views/invoices/credit-notes/CreditNoteView.vue"
              ),
            props: route => ({
              creditNoteId: parseInt(route.params.creditNoteId),
              companyId: parseInt(route.params.companyId)
            }),
            meta: {
              title: "Credit note"
            }
          }
        ]
      }
    ]
  }
];
