export default [
  {
    path: "/validation-rules",
    name: "validation-rules",
    component: () =>
      import(
        /* webpackChunkName: "validation-rules" */ "@/views/validation-rules/ValidationRulesView.vue"
      ),
    meta: {
      title: "Validation-rules"
    },
    children: [
      {
        path: "create",
        name: "validation-rules.create",
        component: () =>
          import(
            /* webpackChunkName: "validation-rules" */ "@/views/validation-rules/ValidationRuleCreateView.vue"
          ),
        meta: {
          title: "Validation-rules"
        }
      },
      {
        path: ":validationRuleId",
        component: () =>
          import(
            /* webpackChunkName: "validation-rules" */ "@/views/validation-rules/ValidationRuleView.vue"
          ),
        meta: {
          title: "Validation-rules"
        },
        children: [
          {
            path: "",
            name: "validation-rules.show",
            props: route => ({ id: parseInt(route.params.validationRuleId) }),
            component: () =>
              import(
                /* webpackChunkName: "validation-rules" */ "@/views/validation-rules/ValidationRuleDashboardView.vue"
              ),
            meta: {
              title: "Validation-rules"
            }
          },
          {
            path: "applied",
            name: "validation-rules.applied",
            component: () =>
              import(
                /* webpackChunkName: "validation-rules" */ "@/views/validation-rules/ValidationRuleAppliedView.vue"
              ),
            meta: {
              title: "Validation-rules"
            }
          }
        ]
      }
    ]
  }
];
