import { filter, isNil } from "lodash";
import { FAILURE, IN_SYNC, LOADING, UNINIT } from "../../sync-states";
import { subject as schema } from "@/store/schema";
import Vue from "vue";
import { normalize } from "normalizr";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/subjects");
const name = "subject";

// state
export const state = {
  subject: {},
  subjectSync: UNINIT,
  subjectIndividualSync: {}
};

// getters
export const getters = {
  getSubjectSectors: state => {
    return filter(state.subject, subject => subject.type === "SECTOR");
  },
  getSubjectSubsectors: state => {
    return filter(state.subject, subject => subject.type === "SUBSECTOR");
  },
  getSubjectJobs: state => {
    return filter(state.subject, subject => subject.type === "JOB");
  }
};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchSubject(context, { id, includes, cb }) {
    try {
      const url = createApiUrl(`${resourceUrl}/${id}`, { includes });
      context.commit("setSync", { name: name, id: id, sync: LOADING });
      const { data } = await Vue.$http.get(url);
      let normalizedData = normalize(data.data, isNil(id) ? [schema] : schema);
      context.commit("updateEntities", {
        entities: normalizedData.entities,
        reactiveName: name,
        reactiveId: normalizedData.result
      });
      context.commit("setSync", { name: name, id: id, sync: IN_SYNC });

      if (cb) cb();
      return normalizedData.result;
    } catch (error) {
      context.commit("setSync", { name: name, id: id, sync: FAILURE });
      console.error(error);
    }
  },
  async updateSubject(context, { entity, includes, cb }) {
    await context.dispatch("update", {
      route: "api.subjects.index",
      name: "subject",
      schema: schema,
      entity: entity,
      includes: includes,
      cb: cb
    });
  },
  async storeSubject(context, { entity, includes, cb }) {
    await context.dispatch("store", {
      route: "api.subjects.index",
      name: "subject",
      schema: schema,
      entity: entity,
      includes: includes,
      cb: cb
    });
  },
  async destroySubject(context, { id, cb }) {
    await context.dispatch("destroy", {
      route: "api.subjects.index",
      name: "subject",
      id: id,
      cb: cb
    });
  }
};
