<template>
  <v-autocomplete
    v-model="select"
    :items="items"
    :loading="loading"
    :search-input.sync="search"
    hide-details
    hide-selected
    no-filter
    hide-no-data
    item-text="name"
    item-value="id"
    label="Search"
    prepend-icon="search"
    class="hidden-sm-and-down"
    flat
    solo-inverted
    return-object
  >
    <template slot="item" slot-scope="{ item }">
      <v-list-tile-avatar>
        <v-icon>{{ getIcon(item) }}</v-icon>
      </v-list-tile-avatar>
      <v-list-tile-content>
        <v-list-tile-title v-html="item.name" />
        <v-list-tile-sub-title
          style="text-transform: capitalize;"
          v-html="item.type"
        />
      </v-list-tile-content>
    </template>
  </v-autocomplete>
</template>
<script>
import api from "@/api";
import { isNil } from "lodash";

export default {
  data() {
    return {
      loading: false,
      items: [],
      search: null,
      select: null,
      queryTimeout: undefined
    };
  },
  watch: {
    search(newValue) {
      if (!isNil(newValue) && newValue !== "") {
        clearTimeout(this.queryTimeout);
        this.loading = true;
        this.queryTimeout = setTimeout(() => {
          this.getDataFromApi();
        }, 500);
      }
    },
    select({ id, type }) {
      switch (type) {
        case "ARTICLE":
          this.$router.push({
            name: "articles.show",
            params: { articleId: id }
          });
          break;
        case "WEBSITE":
          this.$router.push({
            name: "websites.show",
            params: { websiteId: id }
          });
          break;
        case "COMPANY":
          this.$router.push({
            name: "company.show",
            params: { companyId: id }
          });
          break;
        case "FORM":
          window.location.href = `/backoffice/collection/form/${id}`;
          break;
        case "BUDDY":
          this.$router.push({
            name: "management.buddies.show",
            params: { id }
          });
          break;
      }
    }
  },
  methods: {
    async getDataFromApi() {
      clearTimeout(this.queryTimeout);
      try {
        this.items = await api.search.search(this.search);
        this.loading = false;
      } catch (exception) {
        this.loading = false;
        console.error("Failed to get data for searchbar:", exception);
      }
    },
    getIcon({ type }) {
      switch (type) {
        case "COMPANY":
          return "far fa-fw fa-building";
        case "WEBSITE":
          return "far fa-fw fa-globe";
        case "ARTICLE":
          return "far fa-fw fa-globe";
        case "BUDDY":
          return "far fa-fw fa-user-friends";
        case "LEAD":
          return "far fa-fw fa-clipboard-check";
      }
    }
  }
};
</script>
