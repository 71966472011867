<template>
  <div class="tw-px-3 tw-mt-5">
    <label for="search" class="tw-sr-only">Search</label>
    <div class="tw-mt-1 tw-relative tw-rounded-md tw-shadow-sm">
      <div
        class="tw-absolute tw-inset-y-0 tw-left-0 tw-pl-3 tw-flex tw-items-center tw-pointer-events-none"
      >
        <!-- Heroicon name: search -->
        <svg
          class="tw-mr-3 tw-h-4 tw-w-4 tw-text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <input
        id="search"
        class="tw-form-input tw-block tw-w-full tw-pl-9 sm:tw-text-sm sm:tw-leading-5"
        placeholder="Search"
      />
    </div>
  </div>
</template>
<script type="text/babel">
export default {
  name: "b-sidebar-autocomplete",
  components: {}
};
</script>
