import Vue from "vue";
import { deliveryMethod as schema, normalize } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const DeliveryMethodResourceUrl = createApiUrl("api/v1/delivery-methods");

async function fetchAll(companyId, { filters, pagination } = {}) {
  const resourceUrl = createApiUrl(`${DeliveryMethodResourceUrl}`);
  filters.company = companyId;
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function fetch(id) {
  const url = createApiUrl(`${DeliveryMethodResourceUrl}/${id}`, {
    includes: fetchIncludes
  });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function create(deliveryMethod) {
  const { data } = await Vue.$http.post(
    `${DeliveryMethodResourceUrl}`,
    deliveryMethod
  );
  return normalize(data.data, schema);
}

async function update(deliveryMethodId, deliveryMethod) {
  const { data } = await Vue.$http.put(
    `${DeliveryMethodResourceUrl}/${deliveryMethodId}`,
    deliveryMethod
  );
  return normalize(data.data, schema);
}

async function remove(deliveryMethodId) {
  const { data } = await Vue.$http.delete(
    `${DeliveryMethodResourceUrl}/${deliveryMethodId}`
  );
  return data.status === 200;
}

const fetchAllIncludes = ["recipients", "filters"];
const fetchIncludes = ["recipients"];

export default {
  fetchAll,
  fetch,
  create,
  update,
  remove
};
