import Vue from "vue";
import { lead as schema, normalize } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const submissionResourceUrl = createApiUrl("api/v1/submissions");

async function create(submissionId) {
  const url = `${submissionResourceUrl}/${submissionId}/leads`;
  const { data } = await Vue.$http.post(url);
  return normalize(data.data, schema);
}

export default {
  create
};
