export default [
  {
    path: "/backoffice/large-localizations",
    name: "large-localizations",
    component: () =>
      import(
        /* webpackChunkName: "large-localizations" */ "@/views/large-localizations/LargeLocalizationsView"
      ),
    meta: {
      title: "Large-localizations"
    }
  },
  {
    path: "/backoffice/large-localizations/create",
    name: "large-localizations.create",
    component: () =>
      import(
        /* webpackChunkName: "large-localizations" */ "@/views/large-localizations/LargeLocalizationCreateView"
      ),
    meta: {
      title: "Large-localizations"
    }
  },
  {
    path: "/backoffice/large-localizations/:largeLocalizationId",
    name: "large-localizations.show",
    component: () =>
      import(
        /* webpackChunkName: "large-localizations" */ "@/views/large-localizations/LargeLocalizationView"
      ),
    props: route => ({
      largeLocalizationId: parseInt(route.params.largeLocalizationId)
    }),
    meta: {
      title: "large-localization"
    }
  }
];
