import Vue from "vue";
import {
  filter as schema,
  normalize as internalNormalize
} from "@/store/schema";
import { createApiUrl } from "../url";

const resourceUrl = createApiUrl("api/v1/filters");

async function fetchAll({
  filters,
  pagination,
  includes = fetchAllIncludes
} = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function fetch(filterId, { includes = fetchIncludes } = {}) {
  const url = createApiUrl(`${resourceUrl}/${filterId}`, { includes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function create(filter, { includes = fetchIncludes } = {}) {
  const url = createApiUrl(resourceUrl, { includes });
  const { data } = await Vue.$http.post(url, filter);
  return normalize(data.data, schema);
}

async function update(subjectId, filter, { includes = fetchIncludes } = {}) {
  const url = createApiUrl(`${resourceUrl}/${subjectId}`, {
    includes
  });
  const { data } = await Vue.$http.put(url, filter);
  return normalize(data.data, schema);
}

async function updateFilterSortOrder({ filters }) {
  const url = createApiUrl(`${resourceUrl}/update-filters-sort-order`);
  const { data } = await Vue.$http.post(url, filters);
  return normalize(data.data, schema);
}

async function remove(filterId) {
  const { status } = await Vue.$http.delete(`${resourceUrl}/${filterId}`);
  return status === 200;
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchAllIncludes = [
  "company",
  "country",
  "filters.company",
  "filterCategories.category",
  "parentFilters"
];

const fetchIncludes = [
  "original",
  "company.deliveryMethods",
  "company.customers",
  "filters.company.deliveryMethods",
  "deliveryMethods",
  "languages",
  "filterCategories.category",
  "filterCategories.specifiers.options",
  "country",
  "provinces",
  "cities",
  "includedPolygons",
  "excludedPolygons",
  "includedCircles.city",
  "excludedCircles.city",
  "logs",
  "filters",
  "parentFilters"
];

export default {
  normalize,
  fetchAll,
  fetch,
  create,
  update,
  remove,
  updateFilterSortOrder
};
