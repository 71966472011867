import store from "@/store";

export default async (to, from, next) => {
  let isAuthenticated = store.getters["auth/isAuthenticated"];
  // By default, authentication is required, so unless a matching route has both a meta
  // object and it has a requiresAuth property set to false, authentication is required.
  const requiresAuthentication = to.matched.some(
    record => !record.meta || (record.meta && record.meta.auth !== false)
  );

  // If not authenticated, but has an accessToken, use it to authenticate by fetching the
  // user.
  if (!isAuthenticated && store.getters["auth/hasAccessToken"]) {
    // Fetch the user
    await store.dispatch("auth/fetchUser");
    // Update isAuthenticated variable.
    isAuthenticated = store.getters["auth/isAuthenticated"];
  }

  if (requiresAuthentication && !isAuthenticated) {
    // Store the intended Route in the store so we can navigate there after login in.
    store.commit("auth/setIntended", to.fullPath);
    // Redirect to login
    next("login");
  } else {
    // Continue
    next();
  }
};
