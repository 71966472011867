import Vue from "vue";
import { log as schema, normalize } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const companyResourceUrl = createApiUrl("api/v1/companies");

async function fetchAll(companyId, { filters, pagination } = {}) {
  const resourceUrl = createApiUrl(`${companyResourceUrl}/${companyId}/logs`);

  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });

  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function create(companyId, log) {
  const resourceUrl = createApiUrl(`${companyResourceUrl}/${companyId}/logs`);
  const { data } = await Vue.$http.post(resourceUrl, log);
  return normalize(data.data, schema);
}

const fetchAllIncludes = [];

export default {
  create,
  fetchAll
};
