export default [
  {
    path: "/backoffice/telephone-numbers",
    name: "telephone-numbers",
    component: () =>
      import(
        /* webpackChunkName: "telephone-numbers" */ "@/views/telephone-numbers/TelephoneNumbersView"
      ),
    meta: {
      title: "Telephone numbers"
    }
  },
  {
    path: "/backoffice/telephone-numbers/:telephoneNumberId",
    name: "telephone-numbers.show",
    component: () =>
      import(
        /* webpackChunkName: "telephone-numbers" */ "@/views/telephone-numbers/TelephoneNumberView"
      ),
    props: route => ({
      telephoneNumberId: parseInt(route.params.telephoneNumberId)
    }),
    meta: {
      title: "telephone-number"
    }
  }
];
