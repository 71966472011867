<template>
  <!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
  <div class="tw-h-screen tw-flex tw-overflow-hidden tw-bg-white">
    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
    <tailwind-sidebar-mobile
      v-model="isMobileSidebarOpen"
      :items="sidebarItems"
    />
    <!-- Static sidebar for desktop -->
    <div class="tw-hidden lg:tw-flex lg:tw-flex-shrink-0">
      <tailwind-sidebar :items="sidebarItems" :profile-items="profileItems" />
    </div>
    <!-- Main column -->
    <div class="tw-flex tw-flex-col tw-w-0 tw-flex-1 tw-overflow-hidden">
      <!-- Search header -->
      <div
        class="tw-relative tw-z-10 tw-flex-shrink-0 tw-flex tw-h-16 tw-bg-white tw-border-b tw-border-gray-200 lg:tw-hidden"
      >
        <!-- Sidebar toggle, controls the 'sidebarOpen' sidebar state. -->
        <button
          class="tw-px-4 tw-border-r tw-border-gray-200 tw-text-gray-500 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-600 lg:tw-hidden"
          aria-label="Open sidebar"
          @click="isMobileSidebarOpen = !isMobileSidebarOpen"
        >
          <svg
            class="tw-h-6 tw-w-6"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M4 6h16M4 12h8m-8 6h16" />
          </svg>
        </button>
        <div
          class="tw-flex-1 tw-flex tw-justify-between tw-px-4 sm:tw-px-6 lg:tw-px-8"
        >
          <div class="tw-flex-1 tw-flex">
            <form class="tw-w-full tw-flex md:tw-ml-0" action="#" method="GET">
              <label for="search_field" class="tw-sr-only">Search</label>
              <div
                class="tw-relative tw-w-full tw-text-gray-400 focus-within:tw-text-gray-600"
              >
                <div
                  class="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pointer-events-none"
                >
                  <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    />
                  </svg>
                </div>
                <input
                  id="search_field"
                  class="tw-block tw-w-full tw-h-full tw-pl-8 tw-pr-3 tw-py-2 tw-rounded-md tw-text-gray-900 tw-placeholder-gray-500 focus:tw-outline-none focus:tw-placeholder-gray-400 sm:tw-text-sm"
                  placeholder="Search"
                  type="search"
                />
              </div>
            </form>
          </div>
          <div class="tw-flex tw-items-center">
            <!-- Profile dropdown -->
            <b-profile-dropdown-mobile :items="profileItems" />
          </div>
        </div>
      </div>
      <main
        class="tw-flex-1 tw-relative tw-z-0 tw-overflow-y-auto focus:tw-outline-none"
        tabindex="0"
      >
        <div class="pt-2 pb-6 md:py-6">
          <router-view :key="$route.path" />
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import TailwindSidebar from "@/components/sidebar/TailwindSidebar";
import BProfileDropdownMobile from "@/components/sidebar/profile/BProfileDropdownMobile";
import TailwindSidebarMobile from "@/components/sidebar/TailwindSidebarMobile";

const { mapGetters } = createNamespacedHelpers("auth");

export default {
  name: "layouts-tailwind",
  components: {
    TailwindSidebarMobile,
    BProfileDropdownMobile,
    TailwindSidebar
  },
  data: () => ({
    drawer: true,
    locales: false,
    notifications: false,
    profile: false,
    constrained: true,
    sidebarItems: [
      ...[
        {
          name: "Dashboard",
          icon: ["far", "tachometer-alt-fast"],
          to: { name: "dashboard" },
          rolesOrPermissions: ["dashboard_view"]
        },
        {
          name: "My tasks",
          icon: ["far", "line-columns"],
          to: { name: "dashboard" },
          rolesOrPermissions: ["dashboard_view"]
        },
        {
          name: "Submissions",
          icon: ["far", "clipboard-check"],
          to: { name: "submissions" },
          rolesOrPermissions: ["submissions_view"]
        },
        {
          name: "Filters",
          icon: ["far", "filter"],
          to: { name: "filters" },
          rolesOrPermissions: ["filters_view"]
        },
        {
          name: "Sales",
          icon: ["far", "layer-group"],
          items: [
            {
              name: "Companies",
              to: { name: "companies" },
              rolesOrPermissions: ["companies_view"]
            },
            {
              name: "Buddies",
              to: { name: "buddies" },
              rolesOrPermissions: ["buddies_view"]
            },
            {
              name: "Contact Messages",
              to: { name: "contact-messages" },
              rolesOrPermissions: ["contact_messages_view"]
            }
          ]
        },
        {
          name: "Content",
          icon: ["far", "typewriter"],
          items: [
            {
              name: "Websites",
              to: { name: "websites" },
              rolesOrPermissions: ["websites_view"]
            },
            {
              name: "Articles",
              to: { name: "articles" },
              rolesOrPermissions: ["articles_view"]
            },
            {
              name: "Content Plans",
              to: { name: "content.content-plans" },
              rolesOrPermissions: ["content_plans_view"]
            },
            {
              name: "Keywords",
              to: { name: "content.keywords" },
              rolesOrPermissions: ["keywords_view"]
            },
            {
              name: "Localizations",
              to: { name: "localizations" },
              rolesOrPermissions: ["localizations_view"]
            },
            {
              name: "Large Localizations",
              to: { name: "large-localizations" },
              rolesOrPermissions: ["large_localizations_view"]
            }
          ]
        },
        {
          name: "Collection",
          icon: ["far", "clipboard-list"],
          items: [
            {
              name: "Questions",
              to: { name: "questions" },
              rolesOrPermissions: ["questions_view"]
            },
            {
              name: "Subjects",
              to: { name: "subjects" },
              rolesOrPermissions: ["subjects_view"]
            },
            {
              name: "Forms",
              to: { name: "forms" },
              rolesOrPermissions: ["forms_view"]
            }
          ]
        },
        {
          name: "Processing",
          icon: ["far", "sliders-v"],
          items: [
            {
              name: "Rejection Reasons",
              to: { name: "reject-reasons" },
              rolesOrPermissions: ["reject_reasons_view"]
            },
            {
              name: "Offer Rejection Reasons",
              to: { name: "offer-rejection-reasons" },
              rolesOrPermissions: ["offer_rejection_reasons_view"]
            },
            {
              name: "Refinement Rules",
              to: { name: "refinement-rules" },
              rolesOrPermissions: ["refinement_rules_view"]
            },
            {
              name: "Validation Rules",
              to: { name: "validation-rules" },
              rolesOrPermissions: ["validation_rules_view"]
            },
            {
              name: "Separation Rules",
              to: { name: "separation-rules" },
              rolesOrPermissions: ["separation_rules_view"]
            },
            {
              name: "Delivery Rules",
              to: { name: "delivery-rules" },
              rolesOrPermissions: ["delivery_rules_view"]
            },
            {
              name: "Categories",
              to: { name: "categories" },
              rolesOrPermissions: ["categories_view"]
            }
          ]
        },
        {
          name: "Finance",
          icon: ["far", "wallet"],
          items: [
            {
              name: "Invoices",
              to: { name: "invoices" },
              rolesOrPermissions: ["invoices_view"]
            }
          ]
        },
        {
          name: "Metrics",
          icon: ["far", "analytics"],
          items: [
            {
              name: "Website Timetable",
              to: { name: "statistics.websites-timetable" },
              rolesOrPermissions: ["statistics_websites_timetable_view"]
            },
            {
              name: "Website Monthly Metrics",
              to: { name: "statistics.websites-monthly-metrics" },
              rolesOrPermissions: ["statistics_metrics_websites_monthly_view"]
            },
            {
              name: "Articles Timetable",
              to: { name: "statistics.articles-timetable" },
              rolesOrPermissions: ["statistics_articles_timetable_view"]
            },
            {
              name: "Articles Conversion Report",
              to: { name: "statistics.articles-conversions-report" },
              rolesOrPermissions: ["statistics_articles_metrics_view"]
            },
            {
              name: "Subject Performance Report",
              to: { name: "statistics.subject-performance-report" },
              rolesOrPermissions: ["statistics_subject_performance_view"]
            },
            {
              name: "Subject Sales Performance Report",
              to: { name: "statistics.subject-sales-performance-report" },
              rolesOrPermissions: ["statistics_subject_sales_performance_view"]
            }
          ]
        },
        {
          name: "Settings",
          icon: ["far", "cog"],
          items: [
            {
              name: "Employees",
              to: { name: "employees" },
              rolesOrPermissions: ["employees_view"]
            },
            {
              name: "Tags",
              to: { name: "tags" },
              rolesOrPermissions: ["tags_view"]
            },
            {
              name: "Tag Sub Types",
              to: { name: "tag-sub-types" },
              rolesOrPermissions: ["tag_sub_types_view"]
            },
            {
              name: "Annotations",
              to: { name: "annotations" },
              rolesOrPermissions: ["annotations_view"]
            },
            {
              name: "Annotation Types",
              to: { name: "annotation-types" },
              rolesOrPermissions: ["annotation_types_view"]
            },
            {
              name: "Annotation Sub Types",
              to: { name: "annotation-sub-types" },
              rolesOrPermissions: ["annotation_sub_types_view"]
            },
            {
              name: "Roles",
              to: { name: "roles" },
              rolesOrPermissions: ["roles_view"]
            },
            {
              name: "Permissions",
              to: { name: "permissions" },
              rolesOrPermissions: ["permissions_view"]
            },
            {
              name: "Telephone Numbers",
              to: { name: "telephone-numbers" },
              rolesOrPermissions: ["telephone_numbers_view"]
            }
          ]
        }
      ],
      ...(["production", "staging"].includes(process.env.NODE_ENV)
        ? []
        : [
            {
              name: "Development",
              icon: ["far", "brackets-curly"],
              items: [
                {
                  name: "Main",
                  to: { name: "development" },
                  rolesOrPermissions: ["developer"]
                },
                {
                  name: "Selects",
                  to: { name: "development.selects" },
                  rolesOrPermissions: ["developer"]
                },
                {
                  name: "Layouts",
                  to: { name: "development.layouts" },
                  rolesOrPermissions: ["developer"]
                }
              ]
            }
          ])
    ],
    profileItems: [
      [
        { name: "View profile" },
        { name: "Settings" },
        { name: "Notifications" }
      ],
      [{ name: "Get desktop app" }, { name: "Support" }],
      [
        {
          name: "Logout",
          form: {
            action: `${process.env.VUE_APP_AUTH_PORTAL}/uitloggen`,
            method: "POST"
          }
        }
      ]
    ],
    isMobileSidebarOpen: false
  }),
  computed: {
    ...mapGetters(["isAuthenticated"])
  }
};
</script>
