<template>
  <router-link
    :to="item.to"
    v-if="!item.icon"
    href="#"
    :class="[
      ...[
        'tw-group',
        'tw-w-full',
        'tw-flex',
        'tw-items-center',
        'tw-pl-11',
        'tw-pr-2',
        'tw-py-2',
        'tw-text-sm',
        'tw-leading-5',
        'tw-font-medium',
        'tw-rounded-md',
        'focus:tw-outline-none',
        'tw-transition',
        'tw-ease-in-out',
        'tw-duration-150'
      ],
      ...textClass,
      ...backgroundClass
    ]"
  >
    {{ item.name }}
  </router-link>
  <router-link
    :to="item.to"
    v-else
    href="#"
    :class="[
      ...[
        'tw-group',
        'tw-w-full',
        'tw-flex',
        'tw-items-center',
        'tw-pl-2',
        'tw-py-2',
        'tw-text-sm',
        'tw-leading-5',
        'tw-font-medium',
        'tw-rounded-md',
        'focus:tw-outline-none',
        'tw-transition',
        'tw-ease-in-out',
        'tw-duration-150'
      ],
      ...textClass,
      ...backgroundClass
    ]"
  >
    <!-- Heroicon name: home -->
    <font-awesome-icon
      :icon="item.icon"
      class="tw-mr-3 tw-h-6 tw-w-6 tw-text-gray-400 group-hover:tw-text-gray-700 group-hover:tw-bg-gray-50 group-hover:tw-text-gray-700 group-focus:tw-bg-gray-50 group-focus:tw-text-gray-700 tw-transition tw-ease-in-out tw-duration-150 tw-text-xl"
      fixed-width
    />
    {{ item.name }}
  </router-link>
</template>
<script type="text/babel">
export default {
  name: "sidebar-item",
  props: {
    item: {
      type: Object,
      required: true
    },
    textClass: {
      type: String,
      default:
        "tw-text-gray-600 hover:tw-text-gray-900 active:tw-text-gray-900 focus:tw-text-gray-900"
    },
    backgroundClass: {
      type: String,
      default: "hover:tw-bg-gray-50 active:tw-bg-gray-50 focus:tw-bg-gray-200"
    }
  }
};
</script>
