export default [
  {
    path: "/backoffice/questions",
    name: "questions",
    component: () =>
      import(
        /* webpackChunkName: "question" */ "@/views/question/QuestionsView"
      ),
    meta: {
      title: "Questions"
    }
  }
  // {
  //     path: '/backoffice/questions/create',
  //     name: 'questions.create',
  //     component: QuestionCreateView,
  // },
  // {
  //     path: '/backoffice/questions/:questionId',
  //     name: 'questions.show',
  //     component: QuestionView,
  //     props: (route) => ({
  //         questionId: parseInt(route.params.questionId),
  //     }),
  // }
];
