import {
  isNil,
  map,
  cloneDeep,
  defaults,
  assignInWith,
  isObject
} from "lodash";
import { UNINIT } from "../../sync-states";

// state
export const state = {
  questionOption: {},
  questionOptionSync: UNINIT,
  questionOptionIndividualSync: {}
};

// getters
export const getters = {
  getQuestionOptionById: (state, getters) => ({ id }) => {
    let questionOption = cloneDeep(state.questionOption[id]);
    if (!isNil(questionOption) && !isNil(questionOption.parent)) {
      const parentQuestionOption = getters.getQuestionOptionById({
        id: questionOption.parent
      });
      assignInWith(
        questionOption,
        parentQuestionOption,
        (objValue, parentValue) => {
          if (isObject(objValue) || isObject(parentValue)) {
            // Double loop for localization fields
            assignInWith(
              objValue,
              parentValue,
              (secObjValue, secParentValue) => {
                return isNil(secObjValue) ? secParentValue : secObjValue;
              }
            );
          }
          return isNil(objValue) ? parentValue : objValue;
        }
      );
      questionOption = defaults(questionOption, parentQuestionOption);
    }
    if (isNil(questionOption)) {
      console.warn(
        `Tried to retrieve entity questionOption with id ${id} but received ${questionOption}`
      );
    }
    return questionOption;
  },
  getQuestionOptionsByIds: (state, getters) => ({ ids }) => {
    return map(ids, id => getters.getQuestionOptionById({ id: id }));
  }
};

// mutations
export const mutations = {};

// actions
export const actions = {};
