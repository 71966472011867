import Vue from "vue";
import {
  article as schema,
  normalize as internalNormalize
} from "@/store/schema";
import { createApiUrl } from "../url";
import annotation from "./annotation";
import analyticsMetric from "./analyticsMetric";
import submission from "./submission";
import stats from "./stats";
import pageTypeMetric from "./pageTypeMetric";
import sibling from "./sibling";
import highlanderArticle from "./highlanderArticle";

const resourceUrl = createApiUrl("api/v1/articles");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({
  filters,
  pagination,
  includes = fetchAllIncludes
} = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: includes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function update(articleId, article) {
  const url = `${resourceUrl}/${articleId}`;
  const { data } = await Vue.$http.put(url, {
    researchLink: article.researchLink,
    researchLinkEditable: article.researchLinkEditable,
    isSearchable: article.isSearchable
  });
  return normalize(data.data, schema);
}

async function search({ filters, includes } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: includes
  });
  const data = await Vue.$http.get(url);
  return data.data.data;
}

async function saveRemark(articleId, remark) {
  const url = `${resourceUrl}/${articleId}/remark`;
  const { data } = await Vue.$http.put(url, { remark: remark });
  return normalize(data.data, schema);
}

async function addTags(articleId, tags) {
  const url = `${resourceUrl}/${articleId}/addTags`;
  const { data } = await Vue.$http.put(url, tags);
  return normalize(data.data, schema);
}

async function saveArticleArticleData(articleSiblingId, remark, color) {
  const url = createApiUrl("api/v1/article-siblings");
  const { data } = await Vue.$http.put(`${url}/${articleSiblingId}`, {
    remark,
    color
  });
  return normalize(data.data, schema);
}

async function sync(articleId) {
  const { data } = await Vue.$http.put(`${resourceUrl}/${articleId}/sync`);
  return normalize(data.data, schema);
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = [
  "locales",
  "subjects",
  "tags",
  "cluster.articleSiblings.article.website.locales",
  "website.locales",
  "website.cluster",
  "website.siblings.locales",
  "cluster.articleSiblings.article"
];

const fetchAllIncludes = [
  "website",
  "locales",
  "subjects",
  "last30DaysOfSubmissions",
  "last30DaysOfSubmissionsByReferrer",
  "last30DaysOfAnalytics",
  "timeframeMetric"
];

export default {
  normalize,
  fetch,
  fetchAll,
  search,
  update,
  annotation,
  analyticsMetric,
  submission,
  saveRemark,
  sync,
  addTags,
  saveArticleArticleData,
  pageTypeMetric,
  stats,
  sibling,
  highlanderArticle
};
