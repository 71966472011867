import affiliate from "./affiliate";
import city from "./city";
import country from "./country";
import language from "./language";
import locale from "./locale";
import province from "./province";
import question from "./question";
import subject from "./subject";

export default {
  affiliate,
  city,
  country,
  locale,
  language,
  province,
  question,
  subject
};
