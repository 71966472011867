import Vue from "vue";
import {
  normalize as internalNormalize,
  refinementRule as schema
} from "@/store/schema";
import submission from "./submission";
import { createApiUrl } from "../url";

const resourceUrl = createApiUrl("/api/v1/refinement-rules");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function create(refinementRule) {
  const { data } = await Vue.$http.post(resourceUrl, refinementRule);
  return normalize(data.data, schema);
}

async function update(refinementRuleId, refinementRule) {
  const url = `${resourceUrl}/${refinementRuleId}`;
  const { data } = await Vue.$http.put(url, refinementRule);
  return normalize(data.data, schema);
}

async function matches(refinementRuleId, submissionId) {
  const url = createApiUrl(
    `${resourceUrl}/${refinementRuleId}/matches/${submissionId}`
  );
  const { data } = await Vue.$http.get(url);
  debugger;
  return data.data;
}

async function test(refinementRuleId, value) {
  const url = createApiUrl(`${resourceUrl}/${refinementRuleId}/test`, {
    queryParams: { value }
  });
  const { data } = await Vue.$http.get(url);
  return data.data;
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = [
  "triggerGroups.triggers.subjects",
  "triggerGroups.triggers.countries",
  "triggerGroups.triggers.languages",
  "triggerGroups.triggers.triggerQuestions.question.label",
  "triggerGroups.triggers.triggerQuestions.question.options.label",
  "triggerGroups.triggers.triggerQuestions.options"
];
const fetchAllIncludes = [];

export default {
  normalize,
  fetch,
  fetchAll,
  create,
  update,
  matches,
  test,
  submission
};
