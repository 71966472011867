import crypto from "crypto";
import store from "@/store";

export default [
  {
    path: "/login",
    component: () => import(/* webpackChunkName: "auth" */ "@/views/Empty"),
    name: "login",
    meta: { auth: false },
    async beforeEnter() {
      // Helper method for base64 encoding and cleaning up the result.
      const base64Encode = str =>
        str
          .toString("base64")
          .replace(/\+/g, "-")
          .replace(/\//g, "_")
          .replace(/=/g, "");

      // Helper method for sha hash.
      const sha256Hash = str =>
        crypto
          .createHash("sha256")
          .update(str)
          .digest();

      // Create random string as state and store it in local storage.
      const stateBuffer = await crypto.randomBytes(32);
      const state = stateBuffer.toString("hex");
      localStorage.setItem("oauth-state", state);

      // Create longer random string as verifier and store it in local storage.
      const verifierBuffer = await crypto.randomBytes(64);
      const verifier = verifierBuffer.toString("hex");
      localStorage.setItem("oauth-verifier", verifier);

      // Create a challenge by applying a sha256 hash.
      const challenge = base64Encode(sha256Hash(verifier));

      // Retrieve the auth client id.
      const clientId = process.env.VUE_APP_AUTH_CLIENT_ID;
      if (clientId === undefined) {
        throw new Error("Missing build variable: 'VUE_APP_AUTH_CLIENT_ID'");
      }

      // Retrieve the auth client id.
      const rootUrl = process.env.VUE_APP_URL;
      if (rootUrl === undefined) {
        throw new Error("Missing build variable: 'VUE_APP_URL'");
      }

      // Create a params object containing the needed query params.
      const params = new URLSearchParams([
        ["client_id", clientId],
        ["redirect_uri", `${rootUrl}/auth/callback`],
        ["response_type", "code"],
        ["scope", ""],
        ["state", state],
        ["code_challenge", challenge],
        ["code_challenge_method", "S256"]
      ]);

      // Redirect to oauth provider
      window.location.href = `${process.env.VUE_APP_AUTH_SERVER}/oauth/authorize?${params}`;
    }
  },
  {
    path: "/auth/callback",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/auth/CallbackView"),
    name: "auth.callback",
    meta: { auth: false },
    async beforeEnter(to, from, next) {
      // Retrieve & remove the state from local storage.
      const state = localStorage.getItem("oauth-state");
      localStorage.removeItem("oauth-state");

      // Retrieve & remove the verifier from local storage.
      const verifier = localStorage.getItem("oauth-verifier");
      localStorage.removeItem("oauth-verifier");

      // If state mismatches, throw an exception.
      if (
        state &&
        to.query.state &&
        to.query.code &&
        state === to.query.state &&
        (await store.dispatch("auth/fetchAccessToken", {
          code: to.query.code,
          verifier: verifier
        }))
      ) {
        next(store.getters["auth/intended"] || "/");
      } else {
        // Continue to fallback view.
        next();
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () =>
      import(/* webpackChunkName: "auth" */ "@/views/auth/LogoutView")
  },
  {
    path: "/password/request-reset",
    name: "reset",
    component: () =>
      import(
        /* webpackChunkName: "auth" */ "@buldit/fe-common/src/components/RequestPasswordReset"
      ),
    meta: { auth: false }
  },
  {
    path: "/password/reset",
    name: "reset-password",
    props: route => ({ email: route.query.email, token: route.query.token }),
    beforeEnter(to, from, next) {
      if (to.query.email && to.query.token) {
        next();
      } else {
        next("/");
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "auth" */ "@buldit/fe-common/src/components/PasswordReset"
      ),
    meta: { auth: false }
  }
];
