import tableMutations from "../../templates/table/mutations";
import {
  SET_SELECTED_LOCALES,
  SET_USER_LOCALE,
  REMOVE_SELECTED_LOCALE,
  REMOVE_SELECTED_LOCALE_BY_CODE
} from "./mutation-types";

export default {
  ...tableMutations,
  [SET_USER_LOCALE](state, userLocale) {
    state.userLocale = userLocale;
  },
  [SET_SELECTED_LOCALES](state, selectedLocales) {
    state.selectedLocales = selectedLocales;
  },
  [REMOVE_SELECTED_LOCALE](state, { locale }) {
    const index = state.selectedLocales.indexOf(locale.id);
    state.selectedLocales.splice(index, 1);
  },
  [REMOVE_SELECTED_LOCALE_BY_CODE](state, localeCode) {
    const locale = Object.values(state.entity).find(
      locale => locale.code === localeCode
    );
    const index = state.selectedLocales.indexOf(locale.id);
    state.selectedLocales.splice(index, 1);
  }
};
