import { FAILURE, IN_SYNC, LOADING, UNINIT, UPDATING } from "../../sync-states";
import { contentPlan as schema } from "@/store/schema";
import { localization as schemaLocalization } from "@/store/schema";
import { normalize } from "normalizr";
import { createApiUrl } from "@/api/url";
import Vue from "vue";
import { isNil } from "lodash";

const resourceUrl = createApiUrl("api/v1/content-plans");
const name = "contentPlan";

// state
export const state = {
  contentPlan: {},
  contentPlanSync: UNINIT,
  contentPlanIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchContentPlan(context, { id, includes, cb }) {
    try {
      const url = createApiUrl(`${resourceUrl}/${id}`, { includes });
      context.commit("setSync", { name: name, id: id, sync: LOADING });
      const { data } = await Vue.$http.get(url);
      let normalizedData = normalize(data.data, isNil(id) ? [schema] : schema);
      context.commit("updateEntities", {
        entities: normalizedData.entities,
        reactiveName: name,
        reactiveId: normalizedData.result
      });
      context.commit("setSync", { name: name, id: id, sync: IN_SYNC });

      if (cb) cb();
      return normalizedData.result;
    } catch (error) {
      context.commit("setSync", { name: name, id: id, sync: FAILURE });
      console.error(error);
    }
  },
  // async updateContentPlan(context, { entity, includes, cb }) {
  //     await context.dispatch('update', { route: 'api.content-plans.index', name: 'contentPlan', schema: schema, entity: entity, includes: includes, cb: cb });
  // },
  async storeContentPlan(context, { entity, includes, cb }) {
    const url = createApiUrl(resourceUrl, { includes });
    const { data } = await Vue.$http.post(url, entity);
    let normalizedData = normalize(data.data, schema);
    context.commit("updateEntities", { entities: normalizedData.entities });
    if (cb) cb(normalizedData.result);
    return normalizedData.result;
  },
  async destroyContentPlan(context, { id, cb }) {
    try {
      //@TODO Make sync state work for delete
      context.commit("setSync", { name: name, id: id, sync: UPDATING });
      const { data } = await Vue.$http.delete(resourceUrl);

      //@TODO make the commit work to delete website without page refresh
      if (data.success) {
        context.commit("deleteEntity", { name: name, id: id });
        if (cb) cb();
      }
    } catch (error) {
      context.commit("setSync", { name: name, id: id, sync: FAILURE });
      console.error(error);
    }
  },
  async updateContentPlan(context, { contentPlanId, entity }) {
    //const url = "/" + window.Ziggy.namedRoutes["api.content-plans.index"].uri;
    const url = createApiUrl(`${resourceUrl}/${contentPlanId}`);
    try {
      const { data } = await Vue.$http.put(url, entity);
      let normalizedData = normalize(data.data, schema);
      let normalizedDataLocalization = normalize(
        data.data.localization,
        schemaLocalization
      );
      context.commit("updateEntities", { entities: normalizedData.entities });
      context.commit("updateEntities", {
        entities: normalizedDataLocalization.entities
      });
      context.commit("setSync", {
        name: "contentPlan",
        id: normalizedData.result,
        sync: IN_SYNC
      });
      return normalizedData.result;
    } catch (error) {
      console.error(error);
    }
  }
};
