<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    nudge-top="-20"
    min-width="300"
    offset-y
    offset-x
  >
    <v-btn slot="activator" icon large>
      <v-avatar size="32px" tile>
        <img
          :src="pictureUrl ? pictureUrl : require('@/assets/avatar.png')"
          alt="Avatar"
        />
      </v-avatar>
    </v-btn>
    <v-card>
      <v-list>
        <v-list-tile avatar>
          <v-list-tile-avatar @click.stop="debug">
            <img
              :src="pictureUrl ? pictureUrl : require('@/assets/avatar.png')"
              alt="Avatar"
            />
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title
              >{{ firstName }}&nbsp;{{ lastName }}
            </v-list-tile-title>
            <v-list-tile-sub-title
              v-if="roles.length > 0"
              class="text-uppercase grey--text text--lighten-2"
            >
              {{ roleNames.join(", ") }}
            </v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>

      <v-divider />

      <v-card-actions>
        <v-btn flat disabled @click="menu = false">
          Profile
        </v-btn>
        <v-spacer />
        <v-btn
          flat
          :loading="isLoggingOut"
          :disabled="isLoggingOut"
          :to="{ name: 'logout' }"
        >
          Logout
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";

const { mapGetters, mapActions } = createNamespacedHelpers("auth");

export default {
  name: "profile-menu",
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isLoggingOut: false
  }),
  computed: {
    ...mapGetters(["user", "firstName", "lastName", "roles", "pictureUrl"]),
    roleNames() {
      return this.roles.map(role => role.name);
    },
    menu: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    ...mapActions({
      dispatchLogout: "logout"
    }),
    async logout() {
      this.$refs["logout-form"].submit();
    },
    debug() {
      debugger;
    }
  }
};
</script>
