import { UNINIT } from "../../sync-states";
import { customer as schema } from "@/store/schema";

const name = "customer";
const route = "api.customers.index";

// state
export const state = {
  customer: {},
  customerSync: UNINIT,
  customerIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchCustomer(context, { id, includes, filters, cb }) {
    return await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes,
      filters,
      cb
    });
  },
  async updateCustomer(context, { entity, includes, cb }) {
    return await context.dispatch("update", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async storeCustomer(context, { entity, includes, cb }) {
    return await context.dispatch("store", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async destroyCustomer(context, { id, cb }) {
    await context.dispatch("destroy", { route, name, id, cb });
  }
};
