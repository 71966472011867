import axios from "axios";
import { parseThrowableForErrorMessages } from "@buldit/common-fe";

export default store => ({
  onFulfilled: response => response,

  onRejected: error => {
    const {
      response: { status, data }
    } = error;

    // If failed because of cancellation, exit early.
    if (axios.isCancel(error)) {
      return Promise.reject(error);
    }

    // Otherwise if status code 403, notify the user of his shortcomings and exit early.
    if (status === 403) {
      store.dispatch("snackbar/addError", {
        text: "You lack the required permission for this action."
      });
      return Promise.reject(error);
    }

    // Otherwise notify the user of the error.
    store.dispatch("snackbar/addError", {
      text: parseThrowableForErrorMessages(data)[0] || "Unspecified exception"
    });

    return Promise.reject(data);
  }
});
