import Vue from "vue";
import { isArray, isNil, isString } from "lodash";
import { setEntities } from "../../templates/entities";
import {
  CLEAR_FILTER,
  SET_ERROR,
  SET_FILTER,
  SET_FILTERS,
  SET_ITEMS,
  SET_LOADING,
  SET_PAGINATION,
  UPDATE_ITEM
} from "./mutation-types";

export default {
  [SET_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },
  [SET_ERROR](state, isError) {
    state.isError = isError;
  },
  [SET_FILTERS](state, { filters }) {
    state.filters = filters;
  },
  [SET_FILTER](state, { prop, value }) {
    if (isNil(value) || value === "") {
      Vue.delete(state.filters, prop);
    } else {
      if (isArray(state.filters[prop])) {
        if (isString(value)) {
          value = value
            .split(",")
            .map(singleValueAsString => parseInt(singleValueAsString));
        } else if (!isArray(value)) {
          value = [value];
        }
      }
      Vue.set(state.filters, prop, value);
    }
  },
  [CLEAR_FILTER](state) {
    Vue.set(state, "filters", {});
  },
  [SET_PAGINATION](state, { pagination }) {
    state.pagination = pagination;
  },
  [SET_ITEMS](state, { ids, entities, meta }) {
    if (!isNil(meta)) {
      Object.assign(state.pagination, {
        totalItems: meta.total,
        page: meta.current_page,
        lastPage: meta.last_page,
        rowsPerPage: parseInt(meta.per_page)
      });
    } else {
      // @TODO find good implementation for store that do client side filtering/pagination
      Object.assign(state.pagination, {
        totalItems: ids.length,
        page: 1,
        rowsPerPage: 20
      });
    }
    if (!isNil(entities.entity)) {
      setEntities(state, entities, true);
    }
    state.ids = ids;
  },
  [UPDATE_ITEM](state, { item, entities }) {
    Vue.set(state.entity, item, entities.entity[item]);
  }
};
