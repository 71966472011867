import Vue from "vue";
import moment from "moment";
import VueMoment from "vue-moment";
import { isNil } from "lodash";

Vue.prototype.moment = moment;
// @TODO remove VueMoment
Vue.use(VueMoment);

Vue.filter("momentFromNow", function(
  timestamp,
  locale = null,
  withoutSuffix = false
) {
  // Create moment
  const m = moment(timestamp);
  // Set locale if specified
  if (!isNil(locale)) {
    m.locale(locale);
  }
  // Return fromNow string, optionally without suffix
  return m.fromNow(withoutSuffix);
});

Vue.filter("momentTimestamp", function(timestamp, locale = null) {
  // Create moment
  const m = moment(timestamp);
  // Set locale if specified
  if (!isNil(locale)) {
    m.locale(locale);
  }
  // Return formatted datetime string.
  return m.format("L LT");
});

Vue.filter("momentDate", function(timestamp, locale = null) {
  // Create moment
  const m = moment(timestamp);
  // Set locale if specified
  if (!isNil(locale)) {
    m.locale(locale);
  }
  // Return formatted datetime string.
  return m.format("L");
});
