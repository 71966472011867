import Vue from "vue";
import { categoryCompany as schema, normalize } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const companyResourceUrl = createApiUrl("api/v1/companies");

async function fetchAll(companyId, { filters, pagination } = {}) {
  const resourceUrl = createApiUrl(`api/v1/companies/${companyId}/categories`);
  filters.company = companyId;
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function create({ companyId, category }) {
  const { data } = await Vue.$http.post(
    `${companyResourceUrl}/${companyId}/category`,
    category
  );
  return normalize(data.data, schema);
}

async function remove({ companyId, categoryId }) {
  const { data } = await Vue.$http.delete(
    `${companyResourceUrl}/${companyId}/categories/${categoryId}`
  );
  return data.status === 200;
}

const fetchAllIncludes = ["category"];

export default {
  fetchAll,
  create,
  remove
};
