import Vue from "vue";
import {
  assignInWith,
  cloneDeep,
  defaults,
  filter,
  includes,
  isNil,
  isObject,
  map
} from "lodash";
import { question as schema } from "@/store/schema";
import { FAILURE, IN_SYNC, LOADING, UNINIT } from "../../sync-states";

const route = "api.questions.index";
const name = "question";
const detailsIncludes = [
  "label",
  "placeholder",
  "infoHover",
  "options.subject.subjectable",
  "options.remark",

  "parentQuestion.label",
  "parentQuestion.placeholder",
  "parentQuestion.infoHover",
  "parentQuestion.options.subject.subjectable",
  "parentQuestion.options.remark",

  "parentQuestion.parentQuestion.label",
  "parentQuestion.parentQuestion.placeholder",
  "parentQuestion.parentQuestion.infoHover",
  "parentQuestion.parentQuestion.options.subject.subjectable",
  "parentQuestion.parentQuestion.options.remark",

  "parentQuestion.parentQuestion.parentQuestion.label",
  "parentQuestion.parentQuestion.parentQuestion.placeholder",
  "parentQuestion.parentQuestion.parentQuestion.infoHover",
  "parentQuestion.parentQuestion.parentQuestion.options.subject.subjectable",
  "parentQuestion.parentQuestion.parentQuestion.options.remark"
];

// state
export const state = {
  blank: {
    id: null,
    name: null,
    type: "INPUT_TEXT",
    isFilterable: true,
    isAlwaysVisible: false,
    isContact: false,
    isPriceRelated: false,
    label: null,
    placeholder: null,
    infoHover: null,
    isExcluded: false,
    isRequired: false,
    isSemiRequired: false,
    isDisabled: false,
    isHidden: false,
    isMultiple: false,
    isGrid: false,
    isDoubleColumn: false,
    isInfoHover: false,
    options: []
  },
  question: {},
  questionSync: UNINIT,
  questionIndividualSync: {},
  alwaysVisibleQuestions: [],
  questionAwaysVisibleSync: UNINIT
};

// getters
export const getters = {
  getBlankQuestion: (state, getters, rootState, rootGetters) => () => {
    const blank = cloneDeep(state.blank);
    blank.label = rootGetters["entities/getBlankLocalization"]();
    blank.placeholder = rootGetters["entities/getBlankLocalization"]();
    blank.infoHover = rootGetters["entities/getBlankLocalization"]();

    return blank;
  },
  getQuestionById: (state, getters) => ({ id }) => {
    let question = cloneDeep(state.question[id]);
    if (!isNil(question) && !isNil(question.parent)) {
      const parentQuestion = getters.getQuestionById({ id: question.parent });
      assignInWith(question, parentQuestion, (objValue, parentValue) => {
        if (isObject(objValue) || isObject(parentValue)) {
          // Double loop for localization fields
          assignInWith(objValue, parentValue, (secObjValue, secParentValue) => {
            return isNil(secObjValue) ? secParentValue : secObjValue;
          });
        }
        return isNil(objValue) ? parentValue : objValue;
      });

      question = defaults(question, parentQuestion);
    }
    return question;
  },
  getQuestionsByIds: (state, getters) => ({
    ids,
    types,
    isExcludedIncluded,
    includeAlwaysVisible
  }) => {
    if (includeAlwaysVisible) {
      ids = state.alwaysVisibleQuestions.concat(ids);
    }
    let questions = map(ids, id => getters.getQuestionById({ id: id }));
    if (types) {
      questions = filter(questions, question => includes(types, question.type));
    }
    return isExcludedIncluded
      ? questions
      : filter(questions, question => !question.isExcluded);
  }
};

// mutations
export const mutations = {
  setAlwaysVisibleSync(state, { sync, ids }) {
    Vue.set(state, `questionAwaysVisibleSyncSync`, sync);
    if (sync === IN_SYNC) {
      Vue.set(state, "alwaysVisibleQuestions", ids);
    }
  }
};

// actions
export const actions = {
  async fetchQuestionDetails(context, { id }) {
    await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes: detailsIncludes
    });
  },
  async fetchAlwaysVisibleQuestions({ state, dispatch, commit }) {
    if (state.questionAwaysVisibleSync === UNINIT) {
      commit("setAlwaysVisibleSync", { sync: LOADING });
      try {
        const alwaysVisibleQuestionIds = await dispatch("fetch", {
          route,
          name,
          schema,
          filters: { alwaysVisible: true },
          includes: ["label", "options.label"]
        });
        commit("setAlwaysVisibleSync", {
          sync: IN_SYNC,
          ids: alwaysVisibleQuestionIds
        });
        return alwaysVisibleQuestionIds;
      } catch (err) {
        commit("setAlwaysVisibleSync", { sync: FAILURE });
      }
    } else {
      return state.alwaysVisibleQuestions;
    }
  }
};
