import Vue from "vue";
import { normalize } from "normalizr";
import { IN_SYNC, LOADING, UNINIT } from "@/store/sync-states";
import { city as schema } from "@/store/schema";
import { filter } from "lodash";

const route = "api.cities.index";
const name = "city";

// state
export const state = {
  city: {},
  citySync: UNINIT,
  cityIndividualSync: {},
  cityByCountry: {},
  cityByCountrySync: {}
};

// getters
export const getters = {
  getMunicipalitiesByCountry: state => ({ country }) => {
    return filter(
      state.city,
      city => city.isMunicipality && city.country === country
    );
  },
  getCitiesByCountry: (state, getters) => ({ country }) => {
    return getters.getEntities({
      name: "city",
      ids: state.cityByCountry[country]
    });
  },
  isCitiesByCountryInitialized: state => ({ country }) => {
    return state.cityByCountrySync[country] !== UNINIT;
  },
  isCitiesByCountryLoading: state => ({ country }) => {
    return state.cityByCountrySync[country] === LOADING;
  }
};

// mutations
export const mutations = {
  setCityByCountrySync(state, { country, sync }) {
    Vue.set(state.cityByCountrySync, country, sync);
  },
  setCitiesByCountry(state, { country, cities }) {
    Vue.set(state.cityByCountry, country, cities);
    // state.cityByCountry[country] = cities;
  }
};

// actions
export const actions = {
  async fetchCity(context, { id, includes, filters, cb }) {
    await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes,
      filters,
      cb
    });
  },
  async updateCity(context, { entity, includes, cb }) {
    await context.dispatch("update", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async storeCity(context, { entity, includes, cb }) {
    await context.dispatch("store", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async destroyCity(context, { id, cb }) {
    await context.dispatch("destroy", { route, name, id, cb });
  },
  async fetchCitiesByCountry(context, { country }) {
    try {
      context.commit("setCityByCountrySync", { country, sync: LOADING });
      const { data } = await Vue.$http.get(
        `/${window.Ziggy.namedRoutes["api.countries.cities.index"].uri.replace(
          "{country}",
          country
        )}`
      );
      let normalizedData = normalize(data.data, [schema]);
      context.commit("updateEntities", { entities: normalizedData.entities });
      context.commit("setCitiesByCountry", {
        country,
        cities: normalizedData.result
      });
      context.commit("setCityByCountrySync", { country, sync: IN_SYNC });
      return normalizedData.result;
    } catch (error) {
      console.error(error);
    }
  }
};
