import { map } from "lodash";

export default {
  notifications: state => {
    return map(state.notifications, item => {
      return item;
    });
  },
  hasNotifications: (state, getters) => getters["notifications"].length > 0
};
