export default [
  {
    path: "/backoffice/roles",
    name: "roles",
    component: () =>
      import(/* webpackChunkName: "roles" */ "@/views/roles/RolesView"),
    meta: {
      title: "Roles"
    }
  },
  {
    path: "/backoffice/roles/create",
    name: "roles.create",
    component: () =>
      import(/* webpackChunkName: "roles" */ "@/views/roles/RoleCreateView"),
    meta: {
      title: "Roles"
    }
  },
  {
    path: "/backoffice/roles/:roleId",
    name: "roles.show",
    component: () =>
      import(/* webpackChunkName: "roles" */ "@/views/roles/RoleView"),
    props: route => ({
      roleId: parseInt(route.params.roleId)
    }),
    meta: {
      title: "Roles"
    }
  }
];
