import Vue from "vue";
import { createApiUrl } from "@/api/url";

async function fetchTimeSeries(articleId, filters) {
  const url = createApiUrl(`api/v1/stats/articles/${articleId}/time-series`, {
    filters
  });
  const { data } = await Vue.$http.get(url, filters);
  return data.data;
}

async function fetchDeviceTimeSeries(articleId, filters) {
  const url = createApiUrl(
    `api/v1/stats/articles/${articleId}/device-time-series`,
    {
      filters
    }
  );
  const { data } = await Vue.$http.get(url, filters);
  return data.data;
}

async function fetchAverages(articleId, filters) {
  const url = createApiUrl(`api/v1/stats/articles/${articleId}/averages`, {
    filters
  });
  const { data } = await Vue.$http.get(url, filters);
  return data.data;
}

async function fetchSubjectMap(articleId, filters) {
  const url = createApiUrl(`api/v1/stats/articles/${articleId}/subject-map`, {
    filters
  });
  const { data } = await Vue.$http.get(url, filters);
  return data.data;
}

export default {
  fetchTimeSeries,
  fetchAverages,
  fetchDeviceTimeSeries,
  fetchSubjectMap
};
