import store from "@/store";
export default {
  async updateUser({ commit }) {
    const notifications = store.getters["auth/notifications"];
    const user = store.getters["auth/user"];
    commit("updateUser", { notifications });
    window.Echo.connector.pusher.config.auth.headers["Authorization"] =
      "Bearer " + store.getters["auth/accessToken"];
    window.Echo.private(`App.User.${user.id}`).notification(notification => {
      commit("addNotification", notification);
    });
  },
  async removeNotification({ commit }, id) {
    commit("removeNotification", id);
  },
  async removeNotifications({ commit }, id) {
    commit("removeNotifications", id);
  }
};
