import Vue from "vue";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/test");

async function test() {
  const response = await Vue.$http.get(resourceUrl);
  return response;
}

export default {
  test
};
