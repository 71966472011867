import Vue from "vue";
import {
  normalize as internalNormalize,
  website as schema
} from "@/store/schema";
import { createApiUrl } from "../url";
import article from "./article";
import annotation from "./annotation";
import analyticsMetric from "./analyticsMetric";
import stats from "./stats";
import submission from "./submission";
import siblings from "./siblings";

const resourceUrl = createApiUrl("api/v1/websites");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function create(website) {
  const { data } = await Vue.$http.post(resourceUrl, website);
  return normalize(data.data, schema);
}

async function update(websiteId, website) {
  const url = `${resourceUrl}/${websiteId}`;
  const { data } = await Vue.$http.put(url, {
    googleTrackingId: website.googleTrackingId,
    googleViewId: website.googleViewId,
    siteplanLink: website.siteplanLink,
    siteplanLinkEditable: website.siteplanLinkEditable,
    locales: website.locales,
    isSearchable: website.isSearchable,
    protocol: website.protocol
  });
  return normalize(data.data, schema);
}

async function saveNote(websiteId, note) {
  const url = `${resourceUrl}/${websiteId}/note`;
  const { data } = await Vue.$http.put(url, { note: note });
  return normalize(data.data, schema);
}

async function addTags(websiteId, tags) {
  const url = `${resourceUrl}/${websiteId}/addTags`;
  const { data } = await Vue.$http.put(url, tags);
  return normalize(data.data, schema);
}

async function saveArticleArticleData(articleSiblingId, remark, color) {
  const url = createApiUrl("api/v1/article-siblings");
  const { data } = await Vue.$http.put(`${url}/${articleSiblingId}`, {
    remark,
    color
  });
  return normalize(data.data, schema);
}

async function sync(websiteId) {
  const { data } = await Vue.$http.put(`${resourceUrl}/${websiteId}/sync`);
  return normalize(data.data, schema);
}

async function search({ filters } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters
  });
  const data = await Vue.$http.get(url);
  return data.data.data;
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = ["tags", "locales", "cluster", "siblings.locales"];
const fetchAllIncludes = ["locales", "timeframeMetric"];

export default {
  normalize,
  fetch,
  fetchAll,
  create,
  update,
  sync,
  article,
  annotation,
  analyticsMetric,
  saveNote,
  saveArticleArticleData,
  search,
  addTags,
  stats,
  submission,
  siblings
};
