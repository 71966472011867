import { get } from "lodash";
import Vue from "vue";
import { store, router, i18n } from "./plugins";
import App from "./App.vue";

import "./styles/backoffice.scss";
//@TODO remove
/*---------------------------louis wysiwyg shizzle-----------------------------------------*/
/* Client-side Quill Editor plugin with Markdown Shortcuts */
import Quill from "quill";
import moment from "moment";
import VueQuillEditor from "vue-quill-editor";

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import MarkdownShortcuts from "quill-markdown-shortcuts-for-vue-quill-editor";

if (process.env.VUE_APP_ENV === "local") {
  window.config.isDebugable = true;
  Vue.config.devtools = true;
}

Quill.register("modules/markdownShortcuts", MarkdownShortcuts);

Vue.use(VueQuillEditor);
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});
/*---------------------------louis wysiwyg shizzle-----------------------------------------*/

// @TODO remove this workaround
Vue.prototype.trans = key => {
  return get(window.trans, key, key);
};
Vue.config.performance = true;

new Vue({
  i18n,
  router,
  store,
  render: h =>
    h(App, {
      ref: "root"
    })
}).$mount("#app");
