export default [
  {
    path: "/backoffice/offer-rejection-reasons",
    name: "offer-rejection-reasons",
    component: () =>
      import(
        /* webpackChunkName: "offer-rejection-reasons" */ "@/views/offer-rejection-reasons/OfferRejectionReasonsView"
      ),
    meta: {
      title: "Offer Rejection Reasons"
    }
  },
  {
    path: "/backoffice/offer-rejection-reasons/:offerRejectionReasonId",
    component: () =>
      import(
        /* webpackChunkName: "offer-rejection-reasons" */ "@/views/offer-rejection-reasons/OfferRejectionReasonView"
      ),
    meta: {
      title: "Offer Rejection Reasons"
    },
    children: [
      {
        path: "",
        name: "offer-rejection-reasons.show",
        props: route => ({ id: parseInt(route.params.offerRejectionReasonId) }),
        component: () =>
          import(
            /* webpackChunkName: "offer-rejection-reasons" */ "@/views/offer-rejection-reasons/OfferRejectionReasonDetailView"
          ),
        meta: {
          title: "Offer Rejection Reason"
        }
      },
      {
        path: "offers",
        name: "offer-rejection-reasons.offers",
        component: () =>
          import(
            /* webpackChunkName: "offer-rejection-reasons" */ "@/views/offer-rejection-reasons/OfferRejectionReasonOffersView"
          ),
        meta: {
          title: "Offer Rejection Reason"
        }
      }
    ]
  }
];
