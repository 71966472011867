import Vue from "vue";
import { filter as schema, normalize } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/filters");

async function fetchAll(companyId, { filters } = {}) {
  // Hardcode company filter
  filters.company = companyId;
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);
  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

const fetchAllIncludes = [
  "filterCategories.category",
  "country",
  "deliveryMethods",
  "deliveryMethods.recipients"
];

export default {
  fetchAll
};
