export default [
  {
    path: "/submissions",
    name: "submissions",
    // route level code-splitting
    // this generates a separate chunk (submissions.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "submissions" */ "@/views/submissions/SubmissionsView"
      ),
    children: [
      {
        path: "/submissions/:submissionId",
        name: "submissions.show",
        // route level code-splitting
        // this generates a separate chunk (submissions.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "submissions" */ "@/views/submissions/SubmissionView"
          )
      }
    ]
  }
];
