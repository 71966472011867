import Vue from "vue";
import VeeValidate from "vee-validate";
import dictionary from "./dictionary";
import telephoneRule from "./validators/telephone";
import minSelectionRule from "./validators/minSelection";
import maxSelectionRule from "./validators/maxSelection";

VeeValidate.Validator.extend("telephone", telephoneRule);
VeeValidate.Validator.extend("min_select", minSelectionRule);
VeeValidate.Validator.extend("max_select", maxSelectionRule);
Vue.use(VeeValidate, { events: "blur", inject: false });
VeeValidate.Validator.localize(dictionary);

window.veeValidate = VeeValidate;

export default VeeValidate;
