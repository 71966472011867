import Vue from "vue";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/stats/articles");

async function fetch(articleId, { filters, pagination } = {}) {
  const url = createApiUrl(`${resourceUrl}/${articleId}`, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  return data.data;
}

async function timeframes(articleId) {
  const url = `${resourceUrl}/${articleId}/timeframes`;
  const { data } = await Vue.$http.get(url);
  return data.data;
}

async function siblingTimeframes(articleId) {
  const url = `${resourceUrl}/${articleId}/siblingTimeframes`;
  const { data } = await Vue.$http.get(url);
  return data.data;
}

const fetchAllIncludes = [];

export default {
  fetch,
  timeframes,
  siblingTimeframes
};
