import Vue from "vue";
import {
  normalize as internalNormalize,
  telephoneNumber as schema
} from "@/store/schema";
import { createApiUrl } from "../url";

const resourceUrl = createApiUrl("api/v1/telephone-numbers");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function create(telephoneNumber) {
  const { data } = await Vue.$http.post(resourceUrl, telephoneNumber);
  return normalize(data.data, schema);
}

async function update(telephoneNumberId, telephoneNumber) {
  const url = `${resourceUrl}/${telephoneNumberId}`;
  const { data } = await Vue.$http.put(url, telephoneNumber);
  return normalize(data.data, schema);
}

async function remove(telephoneNumberId) {
  const url = `${resourceUrl}/${telephoneNumberId}`;
  const { data } = await Vue.$http.delete(url);
  return normalize(data.data, schema);
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = ["buddies"];
const fetchAllIncludes = [];

export default {
  normalize,
  fetch,
  fetchAll,
  update,
  remove,
  create
};
