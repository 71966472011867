import Vue from "vue";
import { contactPerson as schema, normalize } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const contactPersonResourceUrl = createApiUrl("api/v1/contact-persons");

async function search(companyId, { filters } = {}) {
  filters.company = companyId;
  const url = createApiUrl(contactPersonResourceUrl, {
    filters
  });
  const data = await Vue.$http.get(url);
  return data.data.data;
}

async function fetchAll(companyId, { filters, pagination } = {}) {
  const resourceUrl = createApiUrl(`${contactPersonResourceUrl}`);
  filters.company = companyId;
  const url = createApiUrl(resourceUrl, {
    filters,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function handleArray(contactPersons) {
  const { data } = await Vue.$http.post(
    `${contactPersonResourceUrl}/handle-array`,
    contactPersons
  );
  return normalize(data.data, schema);
}

async function remove(id) {
  const { data } = await Vue.$http.delete(`${contactPersonResourceUrl}/${id}`);
  return data.status === 200;
}

export default {
  search,
  fetchAll,
  handleArray,
  remove
};
