import {
  normalize as internalNormalize,
  website as schema
} from "@/store/schema";
import { cancellableRequest, createApiUrl } from "../../url";
import Vue from "vue";

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl("api/v1/stats/websites/timetable", {
    filters,
    pagination
  });
  const { data } = await cancellableRequest(
    "get",
    url,
    "api/v1/stats/websites/timetable"
  );
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function fetchMonthlyVisitorsTimeSeries(filters) {
  const url = createApiUrl(
    `api/v1/stats/websites/monthly-visitors-time-series`,
    {
      filters
    }
  );
  const { data } = await Vue.$http.get(url, filters);
  return data.data;
}

async function fetchDailyVisitorsTimeSeries(filters) {
  const url = createApiUrl(`api/v1/stats/websites/daily-visitors-time-series`, {
    filters
  });
  const { data } = await Vue.$http.get(url, filters);
  return data.data;
}

async function fetchMonthlyMetrics({ filters, pagination } = {}) {
  const url = createApiUrl("api/v1/stats/websites/monthly-metrics", {
    filters,
    pagination
  });
  const { data } = await cancellableRequest(
    "get",
    url,
    "api/v1/stats/websites/monthly-metrics"
  );
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

export default {
  normalize,
  fetchAll,
  fetchMonthlyVisitorsTimeSeries,
  fetchDailyVisitorsTimeSeries,
  fetchMonthlyMetrics
};
