<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    nudge-top="-20"
    min-width="400"
    max-width="500"
    offset-y
  >
    <v-btn slot="activator" icon>
      <v-icon :color="hasNotifications ? 'accent' : 'white'">
        far fa-fw fa-bell
      </v-icon>
    </v-btn>
    <v-card>
      <v-list two-line dense>
        <template v-for="(notification, index) in notifications">
          <span :key="notification.id"
            ><v-subheader v-if="index === 0" class="text-uppercase"
              >Notifications</v-subheader
            >
            <v-list-tile>
              <v-list-tile-content>
                <v-list-tile-title>{{
                  $t(notification.data.title)
                }}</v-list-tile-title>
                <a v-on:click="markAsRead(notification.id)">{{
                  $t("mark_as_read")
                }}</a>
              </v-list-tile-content>
            </v-list-tile>
            <v-divider />
          </span>
        </template>
      </v-list>
      <v-card-actions>
        <v-spacer />
        <v-btn flat>
          <router-link v-on:click="menu = false" :to="{ name: 'notifications' }"
            >See all ...</router-link
          >
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-menu>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("user-notifications");

export default {
  name: "notification-menu",
  computed: {
    ...mapGetters({
      hasNotifications: "hasNotifications",
      notifications: "notifications"
    }),
    menu: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    markAsRead(notificationId) {
      this.$store.dispatch("notifications/markAsRead", notificationId);
    }
  }
};
</script>
