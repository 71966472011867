<template>
  <div v-show="value" class="lg:tw-hidden">
    <div class="tw-fixed tw-inset-0 tw-flex tw-z-40">
      <!--
        Off-canvas menu overlay, show/hide based on off-canvas menu state.

        Entering: "tw-transition-opacity tw-ease-linear tw-duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "tw-transition-opacity tw-ease-linear tw-duration-300"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <transition
        enter-active-class="tw-transition-opacity tw-ease-linear tw-duration-300"
        enter-class="tw-opacity-0"
        enter-to-class="tw-opacity-100"
        leave-active-class="tw-transition-opacity tw-ease-linear tw-duration-300"
        leave-class="tw-opacity-100"
        leave-to-class="tw-opacity-0"
      >
        <div v-show="value" class="tw-fixed tw-inset-0">
          <div
            class="tw-absolute tw-inset-0 tw-bg-gray-600 tw-opacity-75"
          ></div>
        </div>
      </transition>
      <!--
        Off-canvas menu, show/hide based on off-canvas menu state.

        Entering: "tw-transition tw-ease-in-out tw-duration-300 tw-transform"
          From: "-translate-x-full"
          To: "translate-x-0"
        Leaving: "transition ease-in-out duration-300 transform"
          From: "translate-x-0"
          To: "-translate-x-full"
      -->
      <transition
        enter-active-class="tw-transition tw-ease-in-out tw-duration-300 tw-transform"
        enter-class="tw-translate-x-full"
        enter-to-class="tw-translate-x-0"
        leave-active-class="tw-transition tw-ease-in-out tw-duration-300 tw-transform"
        leave-class="tw-translate-x-0"
        leave-to-class="tw--translate-x-full"
      >
        <div
          v-show="value"
          class="tw-relative tw-flex-1 tw-flex tw-flex-col tw-max-w-xs tw-w-full tw-pt-5 tw-pb-4 tw-bg-white"
        >
          <div class="tw-absolute tw-top-0 tw-right-0 tw--mr-14 tw-p-1">
            <button
              class="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-12 tw-rounded-full focus:tw-outline-none focus:tw-bg-gray-600"
              aria-label="Close sidebar"
              @click="$emit('input', !value)"
            >
              <svg
                class="tw-h-6 tw-w-6 tw-text-white"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div class="tw-flex-shrink-0 tw-flex tw-items-center tw-px-4">
            <!-- :src="require('../assets/logo/blue.svg')" -->
            <img
              class="tw-h-8 tw-w-auto pr-3"
              :src="require('@/assets/logo/icon.svg')"
              alt="Buldit"
            />
            <span
              class="tw-text-primary tw-font-bold tw-uppercase tw-text-xl tw-leading-1"
              >Backoffice</span
            >
          </div>
          <div class="tw-mt-5 tw-flex-1 tw-h-0 tw-overflow-y-auto">
            <nav class="tw-px-2">
              <div class="tw-space-y-1">
                <template v-for="(item, index) in items">
                  <sidebar-group
                    v-if="item.items && item.items.length > 0"
                    :key="`${index}-group`"
                    :group="item"
                    text-class="tw-text-gray-600 hover:tw-text-gray-900"
                    background-class="hover:tw-bg-gray-50 focus:tw-bg-gray-200"
                  />
                  <sidebar-item
                    v-else
                    :key="`${index}`"
                    :item="item"
                    text-class="tw-text-gray-600 hover:tw-text-gray-900"
                    background-class="hover:tw-bg-gray-50 focus:tw-bg-gray-200"
                  />
                </template>
              </div>
            </nav>
          </div>
        </div>
      </transition>
      <div class="tw-flex-shrink-0 tw-w-14">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </div>
  </div>
</template>
<script type="text/babel">
import SidebarItem from "./SidebarItem.vue";
import SidebarGroup from "./SidebarGroup.vue";

export default {
  name: "tailwind-sidebar-mobile",
  components: {
    SidebarItem,
    SidebarGroup
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      required: true
    }
  }
};
</script>
