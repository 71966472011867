import Vue from "vue";
import { normalize, submissionValidation as schema } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/validation-rule-submissions");

async function fetchAll(validationRuleId, { filters, pagination } = {}) {
  // Hardcode validation rule filter
  filters.validationRule = validationRuleId;
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

const fetchAllIncludes = [
  "submission",
  "submission.form",
  "submission.website",
  "submission.country",
  "submission.language",
  "submission.subjects",
  "submission.leads.defaultSubject",
  "submission.leads.subjects",
  "submission.leads.offers.company"
];

export default {
  fetchAll
};
