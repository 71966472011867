import { UNINIT } from "../../sync-states";
import { contactMessage as schema } from "@/store/schema";

// state
export const state = {
  contactMessage: {},
  contactMessageSync: UNINIT,
  contactMessageIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchContactMessage(context, { id, includes, cb }) {
    await context.dispatch("fetch", {
      route: "api.contact-messages.index",
      name: "contactMessage",
      schema: schema,
      id: id,
      includes: includes,
      cb: cb
    });
  },
  async updateContactMessage(context, { entity, includes, cb }) {
    await context.dispatch("update", {
      route: "api.contact-messages.index",
      name: "contactMessage",
      schema: schema,
      entity: entity,
      includes: includes,
      cb: cb
    });
  },
  async storeContactMessage(context, { entity, includes, cb }) {
    await context.dispatch("store", {
      route: "api.contact-messages.index",
      name: "contactMessage",
      schema: schema,
      entity: entity,
      includes: includes,
      cb: cb
    });
  },
  async destroyContactMessage(context, { id, cb }) {
    await context.dispatch("destroy", {
      route: "api.contact-messages.index",
      name: "contactMessage",
      id: id,
      cb: cb
    });
  }
};
