export default (filters = {}) => ({
  ids: [],
  isLoading: false,
  isError: false,
  filters,
  pagination: {
    page: 1,
    lastPage: 1,
    rowsPerPage: 20,
    sortBy: undefined,
    descending: false,
    totalItems: 0
  },
  joins: [],
  entity: {}
});
