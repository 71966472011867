import { UNINIT } from "../../sync-states";
import { log as schema } from "../../schema";

// state
export const state = {
  log: {},
  logSync: UNINIT,
  logIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchLog(context, { id, includes, filters, cb }) {
    return await context.dispatch("fetch", {
      route: "api.log.index",
      name: "log",
      schema,
      id,
      includes,
      filters,
      cb
    });
  },
  async updateLog(context, { entity, includes, cb }) {
    return await context.dispatch("update", {
      route: "api.log.index",
      name: "log",
      schema,
      entity,
      includes,
      cb
    });
  },
  async storeLog(context, { entity, includes, cb }) {
    return await context.dispatch("store", {
      route: "api.log.index",
      name: "log",
      schema,
      entity,
      includes,
      cb
    });
  },
  async destroyLog(context, { id, cb }) {
    await context.dispatch("destroy", {
      route: "api.log.index",
      name: "log",
      id,
      cb
    });
  }
};
