import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";

Vue.use(Vuex);

const isDevelopment = !["production", "staging"].includes(process.env.NODE_ENV);

export default new Vuex.Store({
  strict: true,
  plugins: isDevelopment ? [createLogger()] : []
});
