import Vue from "vue";
import { normalize, submission as schema } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/submissions");

async function fetchAll(articleId, { filters, pagination } = {}) {
  if (!filters.article) {
    filters.article = { type: "TOTAL", articleId: articleId };
  }
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

const fetchAllIncludes = [
  "form",
  "website",
  "country",
  "language",
  "subjects",
  "leads.defaultSubject",
  "leads.subjects",
  "leads.offers.company"
];

export default {
  fetchAll
};
