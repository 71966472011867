import Vue from "vue";
import { normalize, offer as schema } from "@/store/schema";
import { createApiUrl } from "@/api/url";
const resourceUrl = createApiUrl("api/v1/offers");
const invoiceItemOfferResourceUrl = createApiUrl("api/v1/invoice-item-offers");

async function fetchAll({ filters } = {}) {
  const url = createApiUrl(invoiceItemOfferResourceUrl, {
    filters,
    includes: fetchAllIncludes
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function fetchInvoiceableOffers(company, distributedUntill) {
  const filters = {};
  filters.invoiceable = {
    company: company,
    distributedUntill: distributedUntill
  };
  const url = createApiUrl(invoiceItemOfferResourceUrl, {
    filters,
    includes: fetchAllIncludes
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function reject(offerId, reason, comment) {
  const url = `${resourceUrl}/${offerId}/reject`;
  const { data } = await Vue.$http.put(url, {
    reason,
    comment
  });
  return normalize(data.data, schema);
}

const fetchAllIncludes = ["lead.submission.address"];

export default {
  fetchAll,
  fetchInvoiceableOffers,
  reject
};
