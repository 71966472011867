import Vue from "vue";
import {
  invoice as schema,
  normalize as internalNormalize
} from "@/store/schema";
import { createApiUrl } from "../url";
import itemOffer from "./item-offer";
import { isNil } from "lodash";

const fileNameRegex = /attachment; filename=([^.]*).(\w*)/;
const resourceUrl = createApiUrl("api/v1/invoices");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function create(invoice) {
  const { data } = await Vue.$http.post(resourceUrl, invoice);
  return normalize(data.data, schema);
}

async function confirm(id, force) {
  const url = createApiUrl(`${resourceUrl}/${id}/confirm`, {
    includes: fetchIncludes
  });
  const { data } = await Vue.$http.post(url, { force: force });
  return normalize(data.data, schema);
}

async function pay(id) {
  const url = createApiUrl(`${resourceUrl}/${id}/pay`, {
    includes: fetchIncludes
  });
  const { data } = await Vue.$http.post(url);
  return normalize(data.data, schema);
}

async function destroy(id) {
  const url = `${resourceUrl}/${id}`;
  return await Vue.$http.delete(url);
}

async function pdf(payload) {
  const response = await Vue.$http({
    url: `${resourceUrl}/${payload.number}/download/${payload.locale}?type=${payload.type}`,
    method: "GET",
    responseType: "blob"
  });
  /*eslint-disable*/
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const matches = response.headers["content-disposition"].match(fileNameRegex);
  const [contentDispositionHeader, fileName, extension] = matches;
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.${extension}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return true;
}

async function downloadZip(payload) {
  let filters = payload.filters;
  filters.state = ["OPEN", "PAID"];
  const requestUrl = createApiUrl(`${resourceUrl}/download-zip`, {
    filters: filters
  });
  const response = await Vue.$http({
    url: requestUrl,
    method: "GET",
    responseType: "blob"
  });
  /*eslint-disable*/
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const matches = response.headers["content-disposition"].match(fileNameRegex);
  const [contentDispositionHeader, fileName, extension] = matches;
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.${extension}`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return true;
}

async function rejectAndRemoveFromInvoice(
  invoiceId,
  invoiceItemId,
  offerId,
  reason,
  comment
) {
  const url = createApiUrl(
    `${resourceUrl}/${invoiceId}/${invoiceItemId}/reject-and-remove-offer`
  );
  const { data } = await Vue.$http.put(url, {
    offerId,
    reason,
    comment
  });
  if (!isNil(data.message)) {
    return data.message;
  } else {
    return normalize(data.data, schema);
  }
}

async function updateItem(invoiceId, item) {
  const url = createApiUrl(
    `${resourceUrl}/${invoiceId}/${item.id}/update-item`
  );
  const { data } = await Vue.$http.put(url, {
    item
  });
  return normalize(data.data, schema);
}

async function addItems(invoiceId, items) {
  const url = createApiUrl(`${resourceUrl}/${invoiceId}/add-items`);
  const { data } = await Vue.$http.put(url, {
    items
  });
  return normalize(data.data, schema);
}

async function deleteItem(invoiceId, itemId) {
  const url = createApiUrl(`${resourceUrl}/${invoiceId}/${itemId}/delete-item`);
  const { data } = await Vue.$http.put(url);
  if (!isNil(data.message)) {
    return data.message;
  } else {
    return normalize(data.data, schema);
  }
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = [];
const fetchAllIncludes = ["offers", "company", "items", "creditNotes"];

export default {
  normalize,
  fetch,
  fetchAll,
  create,
  destroy,
  confirm,
  pdf,
  pay,
  downloadZip,
  itemOffer,
  rejectAndRemoveFromInvoice,
  updateItem,
  deleteItem,
  addItems
};
