import Vue from "vue";
import {
  buddy as schema,
  normalize as internalNormalize
} from "@/store/schema";
import { createApiUrl } from "../url";

const resourceUrl = createApiUrl("api/v1/buddies");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function create(buddy) {
  const { data } = await Vue.$http.post(resourceUrl, buddy);
  return normalize(data.data, schema);
}

async function update(buddyId, buddy) {
  const { data } = await Vue.$http.put(`${resourceUrl}/${buddyId}`, buddy);
  return normalize(data.data, schema);
}

async function resetPassword(buddyId) {
  console.warn(
    `Tried to reset password for buddy ${buddyId}, but this has not been implemented yet.`
  );
  return null;
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = ["user"];
const fetchAllIncludes = ["user"];

export default {
  normalize,
  fetch,
  fetchAll,
  create,
  update,
  resetPassword
};
