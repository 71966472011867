import { permission as schema } from "@/store/schema";
import { UNINIT } from "../../sync-states";

const route = "api.permissions.index";
const name = "permission";

// state
export const state = {
  permission: {},
  permissionSync: UNINIT,
  permissionIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchPermission(context, { id, includes, filters, cb }) {
    return await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes,
      filters,
      cb
    });
  },
  async updatePermission(context, { entity, includes, cb }) {
    return await context.dispatch("update", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async storePermission(context, { entity, includes, cb }) {
    const result = await context.dispatch("store", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
    context.dispatch(
      "snackbar/addItem",
      { text: "successfully saved permission", color: "success" },
      { root: true }
    );
    return result;
  },
  async destroyPermission(context, { id, cb }) {
    await context.dispatch("destroy", { route, name, id, cb });
  }
};
