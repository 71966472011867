import { UNINIT } from "../../sync-states";
import { localization as schema } from "@/store/schema";
import { clone } from "lodash";

const route = "api.localizations.index";
const name = "localization";

// state
export const state = {
  blank: {
    nl_be: null,
    nl_nl: null,
    fr_be: null,
    fr_fr: null,
    en_gb: null,
    de_de: null
  },
  localization: {},
  localizationSync: UNINIT,
  localizationIndividualSync: {}
};

// getters
export const getters = {
  getBlankLocalization: state => () => {
    return clone(state.blank);
  }
};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchLocalization(context, { id, includes, filters, cb }) {
    return await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes,
      filters,
      cb
    });
  },
  async updateLocalization(context, { entity, includes, cb }) {
    return await context.dispatch("update", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async storeLocalization(context, { entity, includes, cb }) {
    return await context.dispatch("store", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async destroyLocalization(context, { id, cb }) {
    await context.dispatch("destroy", { route, name, id, cb });
  }
};
