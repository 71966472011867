<template>
  <div>
    <div class="bg-pattern" />
    <v-navigation-drawer
      :value="drawer && isAuthenticated"
      style="z-index: 100"
      fixed
      app
      :clipped="$vuetify.breakpoint.lgAndUp"
    >
      <sidebar />
    </v-navigation-drawer>
    <v-toolbar
      color="primary"
      style="z-index: 100"
      dark
      app
      fixed
      :clipped-left="$vuetify.breakpoint.lgAndUp"
    >
      <v-toolbar-title>
        <v-toolbar-side-icon
          v-if="isAuthenticated"
          @click.stop="drawer = !drawer"
        />
        <span class="hidden-sm-and-down">
          Buldit
        </span>
      </v-toolbar-title>
      <searchbar v-if="isAuthenticated" />
      <v-spacer />
      <locales-menu v-model="locales" />
      <notification-menu />
      <profile-menu v-if="isAuthenticated" v-model="profile" />
    </v-toolbar>
    <v-content>
      <v-container
        fluid
        v-bind="{ [`grid-list-${$vuetify.breakpoint.name}`]: true }"
      >
        <router-view :key="$route.path" />
      </v-container>
    </v-content>
    <main-snackbar />
  </div>
</template>
<script>
import { createNamespacedHelpers } from "vuex";
import Sidebar from "@/components/sidebar/Sidebar";
import Searchbar from "@/components/legacy/navbar/Searchbar";
import MainSnackbar from "@/components/legacy/generic/snackbar/Snackbar";
import NotificationMenu from "@/components/notification/NotificationsMenu";
import ProfileMenu from "@/components/profile/ProfileMenu";
import LocalesMenu from "@/components/locale/LocalesMenu";

const { mapGetters } = createNamespacedHelpers("auth");

export default {
  name: "layouts-backoffice",
  components: {
    LocalesMenu,
    ProfileMenu,
    NotificationMenu,
    MainSnackbar,
    Sidebar,
    Searchbar
  },
  data: () => ({
    drawer: true,
    locales: false,
    notifications: false,
    profile: false
  }),
  computed: {
    ...mapGetters(["isAuthenticated"])
  }
};
</script>
