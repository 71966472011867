import Vue from "vue";

// Legacy way
import "@fortawesome/fontawesome-pro/css/all.css";

// New way
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircle as fasCircle,
  faDotCircle as fasDotCircle
} from "@fortawesome/pro-solid-svg-icons";
import {
  faAnalytics as farAnalytics,
  faBars as farBars,
  faBracketsCurly as farBracketsCurly,
  faCheck as farCheck,
  faClipboardCheck as farClipboardCheck,
  faClipboardList as farClipboardList,
  faCog as farCog,
  faDotCircle as farDotCircle,
  faExclamationCircle as farExclamationCircle,
  faFilter as farFilter,
  faHomeAlt as farHomeAlt,
  faLayerGroup as farLayerGroup,
  faLineColumns as farLineColumns,
  faPauseCircle as farPauseCircle,
  faQuoteLeft as farQuoteLeft,
  faQuoteRight as farQuoteRight,
  faSlidersV as farSlidersV,
  faSquareRoot as farSquareRoot,
  faSquareRootAlt as farSquareRootAlt,
  faTachometerAltFast as farTachometerAltFast,
  faTypewriter as farTypewriter,
  faWallet as farWallet
} from "@fortawesome/pro-regular-svg-icons";
import { faSort as falSort } from "@fortawesome/pro-light-svg-icons";
import {
  faCheckSquare as fadCheckSquare,
  faSpinner as fadSpinner,
  faTimesSquare as fadTimesSquare
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(falSort);

library.add(fasCircle);
library.add(fasDotCircle);

library.add(fadSpinner);
library.add(fadTimesSquare);
library.add(fadCheckSquare);

library.add(farAnalytics);
library.add(farBars);
library.add(farBracketsCurly);
library.add(farCog);
library.add(farDotCircle);
library.add(farCheck);
library.add(farClipboardCheck);
library.add(farClipboardList);
library.add(farExclamationCircle);
library.add(farFilter);
library.add(farHomeAlt);
library.add(farLayerGroup);
library.add(farLineColumns);
library.add(farPauseCircle);
library.add(farSlidersV);
library.add(farSquareRoot);
library.add(farSquareRootAlt);
library.add(farTachometerAltFast);
library.add(farTypewriter);
library.add(farWallet);
library.add(farQuoteLeft);
library.add(farQuoteRight);

Vue.component("font-awesome-icon", FontAwesomeIcon);
