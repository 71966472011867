export default [
  {
    path: "/backoffice/notifications",
    name: "notifications",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "@/views/notifications/NotificationsView"
      )
  }
];
