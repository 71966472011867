export default [
  {
    path: "/development",
    name: "development",
    component: () =>
      import(
        /* webpackChunkName: "development" */ "@/views/development/DevelopmentView"
      ),
    meta: {
      title: "Development"
    }
  },
  {
    path: "/development-layouts",
    name: "development.layouts",
    component: () =>
      import(
        /* webpackChunkName: "development" */ "@/views/development/DevelopmentLayoutView"
      ),
    meta: {
      title: "Development: Layouts"
    }
  },
  {
    path: "/development-selects",
    name: "development.selects",
    component: () =>
      import(
        /* webpackChunkName: "development" */ "@/views/development/DevelopmentSelectView"
      ),
    meta: {
      title: "Development: Selects"
    }
  }
];
