import { FAILURE, IN_SYNC, UNINIT, UPDATING } from "../../sync-states";
import { company as schema } from "@/store/schema";
import { createUrl } from "../../url";
import { normalize } from "normalizr";
import Vue from "vue";

const route = "api.companies.index";
const name = "company";

// state
export const state = {
  company: {},
  companySync: UNINIT,
  companyIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchCompany(context, { id, includes, filters }) {
    return await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes,
      filters
    });
  },
  async updateCompany(context, { entity, includes, cb }) {
    return await context.dispatch("update", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async storeCompany(context, { entity, includes, cb }) {
    return await context.dispatch("store", {
      route,
      name,
      schema,
      entity,
      includes,
      cb
    });
  },
  async destroyCompany(context, { id, cb }) {
    await context.dispatch("destroy", { route, name, id, cb });
  },
  async addCompanyToCustomer(context, { id, customerId, email }) {
    const includes = ["customer.user"];
    const url = window.Ziggy.namedRoutes["api.companies.customer"].uri.replace(
      "{company}",
      id
    );
    try {
      context.commit("setSync", { name, id, sync: UPDATING });
      const { data } = await Vue.$http.post(createUrl(url, { includes }), {
        customerId,
        email
      });
      let normalizedData = normalize(data.data, schema);
      context.commit("updateEntities", { entities: normalizedData.entities });
      context.commit("setSync", { name, id, sync: IN_SYNC });
      return normalizedData.result;
    } catch (error) {
      context.commit("setSync", { name, id, sync: FAILURE });
      console.error(error);
    }
  },
  async addContactPerson(context, { id, contactPerson }) {
    const url =
      "/" +
      window.Ziggy.namedRoutes["api.companies.contactPerson"].uri.replace(
        "{company}",
        id
      );
    try {
      context.commit("setSync", { name: "company", id, sync: UPDATING });
      const { data } = await Vue.$http.post(url, contactPerson);
      let normalizedData = normalize(data.data, schema);
      context.commit("updateEntities", { entities: normalizedData.entities });
      context.commit("setSync", { name: "company", id, sync: IN_SYNC });
      return normalizedData.result;
    } catch (error) {
      context.commit("setSync", { name: "company", id, sync: FAILURE });
      console.error(error);
    }
  },
  async storeCompanyFilter(context, { companyId, filter }) {
    const url = `/${window.Ziggy.namedRoutes[
      "api.companies.filters.store"
    ].uri.replace("{company}", companyId)}`;
    try {
      const { data } = await Vue.$http.post(url, filter);
      let normalizedData = normalize(data.data, schema);
      context.commit("updateEntities", { entities: normalizedData.entities });
      return normalizedData.result;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  },
  async addLogToCompany(context, { id, log }) {
    const url =
      "/" +
      window.Ziggy.namedRoutes["api.companies.log"].uri.replace(
        "{company}",
        id
      );
    try {
      context.commit("setSync", { name: "company", id, sync: UPDATING });
      const { data } = await Vue.$http.post(url, log);
      let normalizedData = normalize(data.data, schema);
      context.commit("updateEntities", { entities: normalizedData.entities });
      context.commit("setSync", { name: "company", id, sync: IN_SYNC });
      return normalizedData.result;
    } catch (error) {
      context.commit("setSync", { name: "company", id, sync: FAILURE });
      console.error(error);
    }
  },
  async updateCompanyCategory(context, { category }) {
    const url =
      "/" +
      window.Ziggy.namedRoutes["api.companies.categoryUpdate"].uri.replace(
        "{company}"
      );
    try {
      const { data } = await Vue.$http.post(url, category);
      let normalizedData = normalize(data.data, schema);
      context.commit("updateEntities", { entities: normalizedData.entities });
      return normalizedData.result;
    } catch (error) {
      console.error(error);
    }
  },
  async addNewCategory(context, { companyId, category }) {
    const url =
      "/" +
      window.Ziggy.namedRoutes["api.companies.category"].uri.replace(
        "{company}",
        companyId
      );
    try {
      context.commit("setSync", { name: "company", companyId, sync: UPDATING });
      const { data } = await Vue.$http.post(url, category);
      let normalizedData = normalize(data.data, schema);
      context.commit("updateEntities", { entities: normalizedData.entities });
      context.commit("setSync", { name: "company", companyId, sync: IN_SYNC });
      return normalizedData.result;
    } catch (error) {
      context.commit("setSync", { name: "company", companyId, sync: FAILURE });
      console.error(error);
    }
  }
};
