<template>
  <div class="tw-px-3 tw-mt-6 tw-relative tw-inline-block tw-text-left">
    <!-- Dropdown menu toggle, controlling the show/hide state of dropdown menu. -->
    <div>
      <button
        type="button"
        class="tw-group tw-w-full tw-rounded-md tw-px-3.5 tw-py-2 tw-text-sm tw-leading-5 tw-font-medium tw-text-gray-700 hover:tw-bg-gray-50 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-bg-gray-200 focus:tw-border-blue-300 active:tw-bg-gray-50 active:tw-text-gray-800 tw-transition tw-ease-in-out tw-duration-150"
        id="options-menu"
        aria-haspopup="true"
        :aria-expanded="isOpen.toString()"
        @click="() => (isOpen = !isOpen)"
      >
        <div class="tw-flex tw-w-full tw-justify-between tw-items-center">
          <div
            class="tw-flex tw-min-w-0 tw-items-center tw-justify-between tw-space-x-3"
          >
            <img
              class="tw-w-10 tw-h-10 tw-bg-gray-300 tw-rounded-full tw-flex-shrink-0"
              :src="pictureUrl ? pictureUrl : require('@/assets/avatar.png')"
              alt=""
            />
            <div class="tw-flex-1 tw-min-w-0">
              <h2
                class="tw-text-gray-900 tw-text-sm tw-leading-5 tw-font-medium tw-truncate"
              >
                {{ firstName }} {{ lastName }}
              </h2>
              <p
                class="tw-text-gray-500 tw-text-sm tw-leading-5 tw-truncate"
                style="margin-bottom: 0;"
              >
                {{ role }}
              </p>
            </div>
          </div>
          <!-- Heroicon name: selector -->
          <svg
            class="tw-flex-shrink-0 tw-h-5 tw-w-5 tw-text-gray-400 group-hover:tw-text-gray-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </button>
    </div>
    <!--
      Dropdown panel, show/hide based on dropdown state.

      Entering: "tw-transition tw-ease-out tw-duration-100"
        From: "transform opacity-0 scale-95"
        To: "transform opacity-100 scale-100"
      Leaving: "tw-transition tw-ease-in tw-duration-75"
        From: "transform opacity-100 scale-100"
        To: "transform opacity-0 scale-95"
    -->
    <transition
      enter-active-class="tw-transition tw-ease-out tw-duration-100"
      enter-class="tw-transform tw-opacity-0 tw-scale-95"
      enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-active-class="tw-transition tw-ease-in tw-duration-75"
      leave-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
    >
      <div
        v-if="isOpen"
        class="tw-z-10 tw-mx-3 tw-origin-top tw-absolute tw-right-0 tw-left-0 tw-mt-1 tw-rounded-md tw-shadow-lg"
      >
        <div
          class="tw-rounded-md tw-bg-white tw-shadow-xs"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <template v-for="(group, groupIndex) in items">
            <div
              v-if="groupIndex !== 0"
              :key="`${groupIndex}-divider`"
              class="tw-border-t tw-border-gray-100"
            ></div>
            <div class="tw-py-1" :key="groupIndex">
              <template v-for="(item, index) in group">
                <form
                  v-if="item.form"
                  :method="item.form.method"
                  :action="item.form.action"
                  :key="`${groupIndex}-${index}`"
                >
                  <button
                    class="tw-w-full tw-text-left tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-900"
                    role="menuitem"
                  >
                    {{ item.name }}
                  </button>
                </form>
                <a
                  v-else
                  :key="`${groupIndex}-${index}`"
                  href="#"
                  class="tw-block tw-px-4 tw-py-2 tw-text-sm tw-leading-5 tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 focus:tw-outline-none focus:tw-bg-gray-100 focus:tw-text-gray-900"
                  role="menuitem"
                  >{{ item.name }}</a
                >
              </template>
            </div>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";

const { mapGetters } = createNamespacedHelpers("auth");

export default {
  name: "b-profile-dropdown",
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  components: {},
  data: () => ({
    isOpen: false
  }),
  computed: {
    ...mapGetters(["firstName", "lastName", "roles", "pictureUrl"]),
    role() {
      return this.roles.map(role => role.name)[0] ?? "Guest";
    }
  },
  methods: {
    logout() {}
  }
};
</script>
