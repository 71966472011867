import Vue from "vue";
import { createApiUrl } from "@/api/url";

const submissionResourceUrl = createApiUrl("api/v1/submissions");

async function check(submissionId, triggers) {
  const url = createApiUrl(
    `${submissionResourceUrl}/${submissionId}/triggers/check`,
    { queryParams: { triggers } }
  );
  const { data } = await Vue.$http.get(url);
  return data.data;
}

export default {
  check
};
