<template>
  <div class="data-provider--entity">
    <slot :data="data" :is-loading="isLoading">
      FEED ME COMPONENTS!!!
    </slot>
  </div>
</template>
<script>
import { isNil } from "lodash";

export default {
  name: "entity-data-provider",
  props: {
    entityName: {
      type: String,
      required: true
    },
    entityId: {
      type: Number || String,
      required: false,
      validator: value => {
        return !isNaN(value);
      }
    },
    getter: String,
    includes: {
      type: Array,
      default() {
        return [];
      }
    },
    filters: {
      type: Object,
      required: false
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isInitialized() {
      return this.$store.getters["entities/isInitialized"]({
        name: this.entityName,
        id: this.entityId
      });
    },
    isLoading() {
      return this.$store.getters["entities/isLoading"]({
        name: this.entityName,
        id: this.entityId
      });
    },
    data() {
      // If still loading, don't show anything, otherwise we might display partial results
      if (this.isLoading) return [];
      // If a getter is provided, use the getter.
      if (!isNil(this.getter))
        return this.$store.getters[`entities/${this.getter}`];
      // Otherwise, either return all of or a single entity.
      return isNil(this.entityId)
        ? Object.values(this.$store.state.entities[this.entityName])
        : this.$store.state.entities[this.entityName][this.entityId];
    }
  },
  mounted() {
    this.getDataFromApi();
  },
  methods: {
    async getDataFromApi() {
      if (!this.isInitialized && !this.lazy) {
        await this.$store.dispatch(
          `entities/fetch${this.entityName
            .charAt(0)
            .toUpperCase()}${this.entityName.slice(1)}`,
          { includes: this.includes, filters: this.filters }
        );
      }
    }
  }
};
</script>
