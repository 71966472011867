export default [
  {
    path: "/invoices",
    name: "invoices",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/invoices/InvoicesView"
      ),
    meta: {
      title: "Invoices"
    }
  },
  {
    path: "/invoices/create",
    name: "invoices.create",
    component: () =>
      import(
        /* webpackChunkName: "invoices" */ "@/views/invoices/InvoiceCreateView"
      ),
    meta: {
      title: "Invoices"
    }
  },
  {
    path: "/invoices/:invoiceId",
    name: "invoices.show",
    component: () =>
      import(/* webpackChunkName: "invoices" */ "@/views/invoices/InvoiceView"),
    props: route => ({
      invoiceId: parseInt(route.params.invoiceId)
    }),
    meta: {
      title: "Invoices"
    },
    children: [
      {
        path: "credit-notes/create",
        name: "invoices.credit-notes.create",
        component: () =>
          import(
            /* webpackChunkName: "invoices" */ "@/views/invoices/credit-notes/CreditNoteCreateView.vue"
          ),
        meta: {
          title: "Credit note"
        }
      },
      {
        path: "credit-notes/:creditNoteId",
        name: "invoices.credit-notes.show",
        component: () =>
          import(
            /* webpackChunkName: "invoices" */ "@/views/invoices/credit-notes/CreditNoteView.vue"
          ),
        props: route => ({
          creditNoteId: parseInt(route.params.creditNoteId)
        }),
        meta: {
          title: "Credit note"
        }
      }
    ]
  }
];
