import { UNINIT } from "../../sync-states";

// state
export const state = {
  offer: {},
  offerSync: UNINIT,
  offerIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {};
