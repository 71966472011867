import { cloneDeep } from "lodash";
import {
  CLEAR_FILTER as DISPATCH_CLEAR_FILTER,
  FETCH as DISPATCH_FETCH,
  SET_PAGINATION as DISPATCH_SET_PAGINATION
} from "./action-types";
import {
  CLEAR_FILTER as COMMIT_CLEAR_FILTER,
  SET_ERROR as COMMIT_SET_ERROR,
  SET_ITEMS as COMMIT_SET_ITEMS,
  SET_LOADING as COMMIT_SET_LOADING,
  SET_PAGINATION as COMMIT_SET_PAGINATION
} from "./mutation-types";
import axios from "axios";

export default {
  async [DISPATCH_FETCH]({ state, commit }, { fetchCall }) {
    // Clear error state, set load state.
    commit(COMMIT_SET_LOADING, true);
    commit(COMMIT_SET_ERROR, false);
    try {
      // Use cloneDeep on filters because some filter properties can be arrays.
      const filters = cloneDeep(state.filters);
      // Object assign is enough for pagination as it's properties can never be arrays.
      const pagination = Object.assign({}, state.pagination);
      // Make the provided api call.
      const { entities, result, meta } = await fetchCall({
        filters,
        pagination
      });
      // Set items & clear load state on success
      commit(COMMIT_SET_ITEMS, { ids: result, entities, meta });
      commit(COMMIT_SET_LOADING, false);
      return result;
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.error("Failed to retrieve table module data:", e);
        // Clear load state and set error state on failure
        commit(COMMIT_SET_LOADING, false);
        commit(COMMIT_SET_ERROR, true);
        console.error(e);
      }
    }
  },
  async [DISPATCH_SET_PAGINATION]({ commit, dispatch }, { pagination }) {
    // Set pagination state and fetch data.
    commit(COMMIT_SET_PAGINATION, { pagination });
    dispatch(DISPATCH_FETCH);
  },
  [DISPATCH_CLEAR_FILTER]({ commit, dispatch }) {
    // Clear filter state and fetch data.
    commit(COMMIT_CLEAR_FILTER);
    dispatch(DISPATCH_FETCH);
  }
};
