import Vue from "vue";
import { createApiUrl } from "@/api/url";
import { normalize } from "normalizr";
import { offer as schema } from "@/store/schema";

const resourceUrl = createApiUrl("api/v1/offers");
const leadResourceUrl = createApiUrl("api/v1/leads");

async function getPrice(lead, filter, subject) {
  const url = `${leadResourceUrl}/${lead}/filters/${filter}/subjects/${subject}/price`;
  const { data } = await Vue.$http.get(url);
  return data.price;
}

async function remove(offerId) {
  const url = `${resourceUrl}/${offerId}`;
  const { status } = await Vue.$http.delete(url);
  return status === 200;
}

async function update(offerId, value) {
  const url = createApiUrl(`${resourceUrl}/${offerId}`);
  const { data } = await Vue.$http.put(url, { value });
  return normalize(data.data, schema);
}

async function distribute(offerId) {
  const url = `${resourceUrl}/${offerId}/distribute`;
  const { data } = await Vue.$http.put(url);
  const normalizedData = normalize(data.data, schema);
  normalizedData.metas = data.metas;
  return normalizedData;
}

async function reject(offerId, reason, comment) {
  const url = `${resourceUrl}/${offerId}/reject`;
  const { data } = await Vue.$http.put(url, {
    reason,
    comment
  });
  return normalize(data.data, schema);
}

async function denyRejection(offerId, comment) {
  const url = `${resourceUrl}/${offerId}/deny-rejection`;
  const { data } = await Vue.$http.put(url, {
    comment
  });
  return normalize(data.data, schema);
}

export default {
  getPrice,
  remove,
  distribute,
  reject,
  denyRejection,
  update
};
