import store from "@/store";

function detailPropsFn(route) {
  return {
    id: parseInt(route.params.id)
  };
}

function beforeEnterFn(to, from, next) {
  store.dispatch("entities/fetchAlwaysVisibleQuestions");
  store.dispatch("entities/fetchCategory", {
    id: to.params.id,
    includes: [
      "children",
      "parents",
      "categorySubjects.filterQuestionOptions",
      "categorySubjects.subject.questions.options.parentOption.parentOption.parentOption",
      "categorySubjects.subject.questions.parentQuestion.parentQuestion.parentQuestion"
    ],
    cb: next
  });
}

export default [
  {
    path: "/backoffice/distribution/categories",
    name: "distribution.categories",
    component: () =>
      import(
        /* webpackChunkName: "category" */ "@/views/distribution/category/CategoriesOverview"
      ),
    meta: { title: "categories" },
    props(route) {
      return {
        create: route.name === "distribution.categories.create",
        details: route.name === "distribution.categories.show",
        focused: route.name === "distribution.categories"
      };
    },
    children: [
      {
        path: "create",
        name: "distribution.categories.create",
        component: () =>
          import(
            /* webpackChunkName: "category" */ "@/views/distribution/category/CategoryDetails"
          ),
        props: { create: true },
        meta: { title: "categories" }
      },
      {
        path: ":id",
        name: "distribution.categories.show",
        component: () =>
          import(
            /* webpackChunkName: "category" */ "@/views/distribution/category/CategoryDetails"
          ),
        props: detailPropsFn,
        beforeEnter: beforeEnterFn,
        meta: { title: "categories" }
      }
    ]
  }
];
