export default [
  {
    path: "/backoffice/permissions",
    name: "permissions",
    component: () =>
      import(
        /* webpackChunkName: "permissions" */ "@/views/permissions/PermissionsView"
      ),
    meta: {
      title: "Permissions"
    }
  },
  {
    path: "/backoffice/permissions/create",
    name: "permissions.create",
    component: () =>
      import(
        /* webpackChunkName: "permissions" */ "@/views/permissions/PermissionCreateView"
      ),
    meta: {
      title: "Permissions"
    }
  },
  {
    path: "/backoffice/permissions/:permissionId",
    name: "permissions.show",
    component: () =>
      import(
        /* webpackChunkName: "permissions" */ "@/views/permissions/PermissionView"
      ),
    props: route => ({
      permissionId: parseInt(route.params.permissionId)
    }),
    meta: {
      title: "Permissions"
    }
  }
];
