import Vue from "vue";
import { createApiUrl } from "@/api/url";
import {
  customer as schema,
  normalize as internalNormalize
} from "@/store/schema";

const resourceUrl = createApiUrl("api/v1/customer");

async function magicLink(customerId) {
  const url = createApiUrl(`${resourceUrl}/${customerId}/magiclink`);
  const { data } = await Vue.$http.get(url);
  return data;
}

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchAllIncludes = [];

export default {
  magicLink,
  fetchAll
};
