import { isNil } from "lodash";
import Vue from "vue";
import {
  employee as schema,
  normalize as internalNormalize
} from "@/store/schema";
import { createApiUrl } from "../url";

const resourceUrl = createApiUrl("api/v1/employees");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function create(employee) {
  const { data } = await Vue.$http.post(resourceUrl, employee);
  return normalize(data.data, schema);
}

async function update(
  employeeId,
  { firstName, lastName, user, color, picture }
) {
  const formData = new FormData();
  formData.append("_method", "PUT");
  formData.append("firstName", firstName);
  formData.append("lastName", lastName);
  formData.append("user[email]", user.email);
  formData.append("user[roles]", user.roles);
  formData.append("user[permissions]", user.permissions);
  formData.append("color", color);
  if (!isNil(picture)) {
    const extension = picture.type.replace("image/", "");
    formData.append(
      "picture",
      picture,
      `employee_${lastName}_${firstName}.${extension}`
    );
  }

  const { data } = await Vue.$http.post(
    `${resourceUrl}/${employeeId}`,
    formData,
    {
      headers: { "content-type": "multipart/form-data" }
    }
  );
  return normalize(data.data, schema);
}

async function resetPassword(employeeId) {
  const url = `${resourceUrl}/${employeeId}/password`;
  const { data } = await Vue.$http.put(url);
  return data.status === 200;
}

async function remove(employeeId) {
  const url = `${resourceUrl}/${employeeId}`;
  const { data } = await Vue.$http.delete(url);
  return normalize(data.data, schema);
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = ["user.roles", "user.permissions"];
const fetchAllIncludes = ["user.roles", "user.permissions"];

export default {
  normalize,
  fetch,
  fetchAll,
  create,
  update,
  resetPassword,
  remove
};
