import { UNINIT } from "../../sync-states";
import { refinementRule as schema } from "@/store/schema";

// state
export const state = {
  refinementRule: {},
  refinementRuleSync: UNINIT,
  refinementRuleIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchRefinementRuleDetails(context, { id }) {
    return await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes
    });
  },
  async fetchRefinementRule(context, { id, includes, cb }) {
    return await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes,
      cb
    });
  },
  async updateRefinementRule(context, { refinementRule, includes, cb }) {
    return await context.dispatch("update", {
      route,
      name,
      schema,
      entity: refinementRule,
      includes,
      cb
    });
  },
  async storeRefinementRule(context, { refinementRule, includes, cb }) {
    return await context.dispatch("store", {
      route,
      name,
      schema,
      entity: refinementRule,
      includes,
      cb
    });
  },
  async destroyRefinementRule(context, { id, cb }) {
    return await context.dispatch("destroy", { route, name, id, cb });
  }
};

const name = "refinementRule";
const route = "api.refinement-rules.index";
const includes = [
  "triggers.subjects",
  "triggers.countries",
  "triggers.languages",
  "triggers.triggerQuestions.question",
  "triggers.triggerQuestions.options",
  "triggers.children.subjects",
  "triggers.children.countries",
  "triggers.children.languages",
  "triggers.children.triggerQuestions.question",
  "triggers.children.triggerQuestions.options"
];
