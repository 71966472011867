<template>
  <v-list-tile :to="item.to" :href="item.href">
    <v-list-tile-action>
      <v-icon v-if="item.icon && !item.stackedIcon">
        {{ item.icon }}
      </v-icon>
      <span
        v-else
        class="v-icon fa-fw fa-stack theme--light"
        style="vertical-align: top;"
      >
        <i :class="item.stackedIcon.back" />
        <i :class="item.stackedIcon.front" />
      </span>
    </v-list-tile-action>
    <v-list-tile-content>
      <v-list-tile-title>
        {{ item.text }}
      </v-list-tile-title>
    </v-list-tile-content>
  </v-list-tile>
</template>
<script type="text/babel">
export default {
  name: "sidebar-menu-item",
  props: {
    item: {
      type: Object,
      required: true
    }
  }
};
</script>
<style scoped lang="scss">
.v-icon.fa-fw.fa-stack {
  width: 1.25em;
}

.v-icon.fa-stack {
  .fa-stack-sm {
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    line-height: inherit;
  }
}
</style>
