var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.item.icon)?_c('router-link',{class:[
      'tw-group',
      'tw-w-full',
      'tw-flex',
      'tw-items-center',
      'tw-pl-11',
      'tw-pr-2',
      'tw-py-2',
      'tw-text-sm',
      'tw-leading-5',
      'tw-font-medium',
      'tw-rounded-md',
      'focus:tw-outline-none',
      'tw-transition',
      'tw-ease-in-out',
      'tw-duration-150'
    ].concat( _vm.textClass,
    _vm.backgroundClass
  ),attrs:{"to":_vm.item.to,"href":"#"}},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]):_c('router-link',{class:[
      'tw-group',
      'tw-w-full',
      'tw-flex',
      'tw-items-center',
      'tw-pl-2',
      'tw-py-2',
      'tw-text-sm',
      'tw-leading-5',
      'tw-font-medium',
      'tw-rounded-md',
      'focus:tw-outline-none',
      'tw-transition',
      'tw-ease-in-out',
      'tw-duration-150'
    ].concat( _vm.textClass,
    _vm.backgroundClass
  ),attrs:{"to":_vm.item.to,"href":"#"}},[_c('font-awesome-icon',{staticClass:"tw-mr-3 tw-h-6 tw-w-6 tw-text-gray-400 group-hover:tw-text-gray-700 group-hover:tw-bg-gray-50 group-hover:tw-text-gray-700 group-focus:tw-bg-gray-50 group-focus:tw-text-gray-700 tw-transition tw-ease-in-out tw-duration-150 tw-text-xl",attrs:{"icon":_vm.item.icon,"fixed-width":""}}),_vm._v(" "+_vm._s(_vm.item.name)+" ")],1)}
var staticRenderFns = []

export { render, staticRenderFns }