<template>
  <v-list dense>
    <template v-for="item in filterAllowedRoutes(items)">
      <v-list-group
        v-if="item.children && filterAllowedRoutes(item.children).length > 0"
        :key="item.text"
        v-model="item.isOpen"
        :prepend-icon="item.isOpen ? item.icon : item.iconAlternative"
        append-icon=""
      >
        <v-list-tile slot="activator">
          <v-list-tile-content>
            <v-list-tile-title>
              {{ item.text }}
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
        <sidebar-menu-item
          v-for="child in filterAllowedRoutes(item.children)"
          :key="child.text"
          :item="child"
        />
      </v-list-group>
      <sidebar-menu-item
        v-else-if="!item.children"
        :key="item.text"
        :item="item"
      />
    </template>
  </v-list>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import SidebarMenuItem from "./SidebarMenuItem.vue";
import RolesAndPermissionsMixin from "@/components/mixins/RolesAndPermissionsMixin";

const { mapGetters } = createNamespacedHelpers("auth");

export default {
  name: "sidebar",
  components: {
    SidebarMenuItem
  },
  mixins: [RolesAndPermissionsMixin],
  data: () => ({
    items: [
      ...[
        {
          icon: "far fa-fw fa-tachometer",
          text: "Dashboard",
          to: { name: "dashboard" },
          rolesOrPermissions: ["dashboard_view"]
        },
        {
          icon: "far fa-fw fa-clipboard-check",
          text: "Submissions",
          to: { name: "submissions" },
          rolesOrPermissions: ["submissions_view"]
        },
        {
          icon: "far fa-fw fa-filter",
          text: "Filters",
          to: { name: "filters" },
          rolesOrPermissions: ["filters_view"]
        },
        {
          icon: "far fa-fw fa-envelope-square",
          text: "Contact Messages",
          to: { name: "contact-messages" },
          rolesOrPermissions: ["contact_messages_view"]
        },
        {
          icon: "far fa-fw fa-exclamation",
          text: "Notifications",
          to: { name: "notifications" },
          rolesOrPermissions: ["notifications_view"]
        },
        {
          icon: "far fa-fw fa-list",
          text: "Subscriptions",
          to: { name: "subscriptions" },
          rolesOrPermissions: ["subscriptions_view"]
        },
        {
          icon: "far fa-fw fa-angle-up",
          iconAlternative: "far fa-angle-down",
          text: "Management",
          isOpen: true,
          children: [
            {
              icon: "far fa-fw fa-building",
              text: "Companies",
              to: { name: "companies" },
              rolesOrPermissions: ["companies_view"]
            },
            {
              icon: "far fa-fw fa-user-friends",
              text: "Buddies",
              to: { name: "buddies" },
              rolesOrPermissions: ["buddies_view"]
            },
            {
              icon: "fas fa-fw fa-file-invoice",
              text: "Invoices",
              to: { name: "invoices" },
              rolesOrPermissions: ["invoices_view"]
            }
          ]
        },
        {
          icon: "far fa-angle-up fa-fw",
          iconAlternative: "far fa-angle-down",
          text: "Content",
          isOpen: true,
          children: [
            {
              icon: "far fa-fw fa-globe",
              text: "Websites",
              to: { name: "websites" },
              rolesOrPermissions: ["websites_view"]
            },
            {
              stackedIcon: {
                back: "far fa-globe fa-stack-1x",
                front: "fas fa-globe fa-stack-1x"
              },
              text: "Articles",
              to: { name: "articles" },
              rolesOrPermissions: ["articles_view"]
            },
            {
              icon: "far fa-fw fa-columns",
              text: "Content Plans",
              to: { name: "content.content-plans" },
              rolesOrPermissions: ["content_plans_view"]
            },
            {
              icon: "far fa-fw fa-list",
              text: "Keywords",
              to: { name: "content.keywords" },
              rolesOrPermissions: ["keywords_view"]
            },
            {
              icon: "far fa-fw fa-language",
              text: "Localizations",
              to: { name: "localizations" },
              rolesOrPermissions: ["localizations_view"]
            },
            {
              icon: "far fa-fw fa-language",
              text: "Large Localizations",
              to: { name: "large-localizations" },
              rolesOrPermissions: ["large_localizations_view"]
            }
          ]
        },
        {
          icon: "far fa-fw fa-angle-up",
          iconAlternative: "far fa-fw fa-angle-down",
          text: "Collection",
          isOpen: true,
          children: [
            {
              icon: "far fa-clipboard fa-fw",
              text: "Forms",
              to: { name: "forms" },
              rolesOrPermissions: ["forms_view"]
            },
            {
              icon: "far fa-fw fa-dot-circle",
              text: "Subjects",
              to: { name: "subjects" },
              rolesOrPermissions: ["subjects_view"]
            },
            {
              icon: "far fa-fw fa-question-circle",
              text: "Questions",
              to: { name: "questions" },
              rolesOrPermissions: ["questions_view"]
            }
          ]
        },
        {
          icon: "far fa-fw fa-angle-up",
          iconAlternative: "far fa-fw fa-angle-down",
          text: "Statistics",
          isOpen: true,
          children: [
            {
              icon: "far fa-fw fa-globe",
              text: "Website Timetable",
              to: { name: "statistics.websites-timetable" },
              rolesOrPermissions: ["statistics_websites_timetable_view"]
            },
            {
              icon: "far fa-fw fa-globe",
              text: "Website Monthly Metrics",
              to: { name: "statistics.websites-monthly-metrics" },
              rolesOrPermissions: ["statistics_metrics_websites_monthly_view"]
            },
            {
              icon: "far fa-fw fa-globe",
              text: "Articles Timetable",
              to: { name: "statistics.articles-timetable" },
              rolesOrPermissions: ["statistics_articles_timetable_view"]
            },
            {
              icon: "fas fa-chart-bar",
              text: "Articles Conversion Report",
              to: { name: "statistics.articles-conversions-report" },
              rolesOrPermissions: ["statistics_articles_metrics_view"]
            },
            {
              icon: "fas fa-chart-bar",
              text: "Subject Performance Report",
              to: { name: "statistics.subject-performance-report" },
              rolesOrPermissions: ["statistics_subject_performance_view"]
            },
            {
              icon: "fas fa-chart-bar",
              text: "Subject Sales Performance Report",
              to: { name: "statistics.subject-sales-performance-report" },
              rolesOrPermissions: ["statistics_subject_sales_performance_view"]
            }
          ]
        },
        {
          icon: "far fa-fw fa-angle-up",
          iconAlternative: "far fa-fw fa-angle-down",
          text: "Distribution",
          isOpen: true,
          children: [
            {
              icon: "far fa-fw fa-tags",
              text: "Categories",
              to: { name: "categories" },
              rolesOrPermissions: ["categories_view"]
            }
          ]
        },
        {
          icon: "far fa-fw fa-angle-up",
          iconAlternative: "far fa-fw fa-angle-down",
          text: "Validation",
          isOpen: true,
          children: [
            {
              icon: "x",
              text: "Reject Reasons",
              to: { name: "reject-reasons" },
              rolesOrPermissions: ["reject_reasons_view"]
            },
            {
              icon: "x",
              text: "Offer Rejection Reasons",
              to: { name: "offer-rejection-reasons" },
              rolesOrPermissions: ["offer_rejection_reasons_view"]
            },
            {
              icon: "x",
              text: "Refinement Rules",
              to: { name: "refinement-rules" },
              rolesOrPermissions: ["refinement_rules_view"]
            },
            {
              icon: "x",
              text: "Validation Rules",
              to: { name: "validation-rules" },
              rolesOrPermissions: ["validation_rules_view"]
            },
            {
              icon: "x",
              text: "Separation Rules",
              to: { name: "separation-rules" },
              rolesOrPermissions: ["separation_rules_view"]
            },
            {
              icon: "x",
              text: "Delivery Rules",
              to: { name: "delivery-rules" },
              rolesOrPermissions: ["delivery_rules_view"]
            }
          ]
        },
        {
          icon: "far fa-fw fa-angle-up",
          iconAlternative: "far fa-fw fa-angle-down",
          text: "Settings",
          isOpen: true,
          children: [
            {
              icon: "x",
              text: "Employees",
              to: { name: "employees" },
              rolesOrPermissions: ["employees_view"]
            },
            {
              icon: "far fa-th-large",
              text: "Tags",
              to: { name: "tags" },
              rolesOrPermissions: ["tags_view"]
            },
            {
              icon: "far fa-th-large",
              text: "Tag Sub Types",
              to: { name: "tag-sub-types" },
              rolesOrPermissions: ["tag_sub_types_view"]
            },
            {
              icon: "far fa-edit",
              text: "Annotations",
              to: { name: "annotations" },
              rolesOrPermissions: ["annotations_view"]
            },
            {
              icon: "far fa-edit",
              text: "Annotations Types",
              to: { name: "annotation-types" },
              rolesOrPermissions: ["annotation_types_view"]
            },
            {
              icon: "far fa-edit",
              text: "Annotations Sub Types",
              to: { name: "annotation-sub-types" },
              rolesOrPermissions: ["annotation_sub_types_view"]
            },
            {
              icon: "x",
              text: "Roles",
              to: { name: "roles" },
              rolesOrPermissions: ["roles_view"]
            },
            {
              icon: "x",
              text: "Permissions",
              to: { name: "permissions" },
              rolesOrPermissions: ["permissions_view"]
            },
            {
              icon: "x",
              text: "Telephone Numbers",
              to: { name: "telephone-numbers" },
              rolesOrPermissions: ["telephone_numbers_view"]
            }
          ]
        }
      ],
      ...(["production", "staging"].includes(process.env.NODE_ENV)
        ? []
        : [
            {
              icon: "far fa-fw fa-angle-up",
              iconAlternative: "far fa-fw fa-angle-down",
              text: "Develop",
              isOpen: true,
              children: [
                {
                  icon: "fas fa-code",
                  text: "Main",
                  to: { name: "development" },
                  rolesOrPermissions: ["developer"]
                },
                {
                  icon: "fas fa-code",
                  text: "Selects",
                  to: { name: "development.selects" },
                  rolesOrPermissions: ["developer"]
                },
                {
                  icon: "fas fa-code",
                  text: "Layouts",
                  to: { name: "development.layouts" },
                  rolesOrPermissions: ["developer"]
                }
              ]
            }
          ])
    ]
  }),
  computed: {
    ...mapGetters(["hasRolesOrPermissions"])
  },
  methods: {
    filterAllowedRoutes(items) {
      return items.filter(item => {
        return (
          item.rolesOrPermissions === undefined ||
          this.hasRolesOrPermissions(item.rolesOrPermissions)
        );
      });
    }
  }
};
</script>
