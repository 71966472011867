export default [
  {
    path: "/categories",
    name: "categories",
    // route level code-splitting
    // this generates a separate chunk (categories.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "categories" */ "@/views/categories/CategoriesView.vue"
      ),
    children: [
      {
        path: "create",
        name: "categories.create",
        // route level code-splitting
        // this generates a separate chunk (categories.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "categories" */ "@/views/categories/CategoryCreateView.vue"
          )
      },
      {
        path: ":categoryId",
        // route level code-splitting
        // this generates a separate chunk (categories.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "categories" */ "@/views/categories/CategoryView.vue"
          ),
        children: [
          {
            path: "",
            name: "categories.show",
            props: route => ({ id: parseInt(route.params.categoryId) }),
            // route level code-splitting
            // this generates a separate chunk (categories.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "categories" */ "@/views/categories/CategoryDetailView.vue"
              )
          },
          {
            path: "subjects",
            name: "categories.subjects",
            // route level code-splitting
            // this generates a separate chunk (categories.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "categories" */ "@/views/categories/CategorySubjectsView.vue"
              )
          }
        ]
      }
    ]
  }
];
