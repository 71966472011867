/**
 * THIS SHIT IS ALL @deprecated!!! If you run into it, change it to use api/url instead of store/url
 */

import { each, forOwn, isArray, isEmpty, isNil, isPlainObject } from "lodash";

/**
 * @deprecated
 */
export function createUrlFromRoute(
  route,
  id,
  includes,
  filters,
  pagination,
  joins
) {
  console.warn("Trying to create url from route", route);
  return createUrl(window.Ziggy.namedRoutes[route].uri, {
    id,
    includes,
    filters,
    pagination,
    joins
  });
}

/**
 * @deprecated
 */
export function createUrl(
  url,
  { id, includes, filters, pagination, additionalParams, joins }
) {
  const prefix = `${location.protocol}//${location.host}/`;
  if (!url.startsWith(prefix)) {
    url = `${prefix}${url}`;
  }
  if (!isNil(id)) {
    url = `${url}/${id}`;
  }
  url = new URL(url);
  const params = new URLSearchParams(url.search);
  applyIncludesToUrlParams(params, includes);
  applyJoinsToUrlParams(params, joins);
  applyFiltersToUrlParams(params, filters);
  applyPaginationToUrlParams(params, pagination);
  applySortToUrlParams(params, pagination);
  applyAdditionalParamsToUrlParams(params, additionalParams);
  return params.toString() === "" ? `${url}` : `${url}?${params}`;
}

/**
 * @deprecated
 */
function applyFiltersToUrlParams(params, filters) {
  if (!isNil(filters)) {
    forOwn(filters, (value, property) => {
      let isEmptyArray = isArray(value) && isEmpty(value);
      if (!(isNil(value) || isEmptyArray)) {
        if (isPlainObject(value)) {
          let hasValues = false;
          forOwn(value, innerValue => {
            if (!isNil(innerValue)) {
              hasValues = true;
            }
          });
          if (hasValues) {
            forOwn(value, (innerValue, innerProperty) => {
              params.set(`filter[${property}][${innerProperty}]`, innerValue);
            });
          }
        } else {
          if (value !== false) {
            params.set(`filter[${property}]`, value);
          }
        }
      }
    });
  }
}

/**
 * @deprecated
 */
function applyPaginationToUrlParams(params, pagination) {
  if (!isNil(pagination)) {
    if (!isNil(pagination.page) && pagination.page !== 1) {
      params.set("page", pagination.page);
    }
    params.set("perPage", pagination.rowsPerPage);
  }
}

/**
 * @deprecated
 */
function applySortToUrlParams(params, pagination) {
  if (!isNil(pagination)) {
    if (!isNil(pagination.sortBy)) {
      params.set(
        "sort",
        `${pagination.descending ? "" : "-"}${pagination.sortBy}`
      );
    }
  }
}

/**
 * @deprecated
 */
function applyIncludesToUrlParams(params, includes) {
  if (isArray(includes) && includes.length > 0) {
    each(includes, o => {
      params.append("includes[]", o);
    });
  }
}

/**
 * @deprecated
 */
function applyJoinsToUrlParams(params, joins) {
  if (isArray(joins) && joins.length > 0) {
    each(joins, o => {
      params.append("joins[]", o);
    });
  }
}

/**
 * @deprecated
 */
function applyAdditionalParamsToUrlParams(params, additionalParams) {
  if (!isNil(additionalParams) && isPlainObject(additionalParams)) {
    forOwn(additionalParams, (value, property) => {
      if (!isNil(value)) {
        params.append(property, value);
      }
    });
  }
}
