export default [
  {
    path: "/backoffice/subscriptions",
    name: "subscriptions",
    component: () =>
      import(
        /* webpackChunkName: "subscriptions" */ "@/views/subscriptions/SubscriptionsView"
      )
  }
];
