import Vue from "vue";
import { createApiUrl } from "@/api/url";

async function fetchTimeSeries(websiteId, filters) {
  const url = createApiUrl(`api/v1/stats/websites/${websiteId}/time-series`, {
    filters
  });
  const { data } = await Vue.$http.get(url, filters);
  return data.data;
}

async function fetchDeviceTimeSeries(websiteId, filters) {
  const url = createApiUrl(
    `api/v1/stats/websites/${websiteId}/device-time-series`,
    {
      filters
    }
  );
  const { data } = await Vue.$http.get(url, filters);
  return data.data;
}

async function fetchAverages(websiteId, filters) {
  const url = createApiUrl(`api/v1/stats/websites/${websiteId}/averages`, {
    filters
  });
  const { data } = await Vue.$http.get(url, filters);
  return data.data;
}

async function fetchSubjectMap(websiteId, filters) {
  const url = createApiUrl(`api/v1/stats/websites/${websiteId}/subject-map`, {
    filters
  });
  const { data } = await Vue.$http.get(url, filters);
  return data.data;
}

export default {
  fetchTimeSeries,
  fetchAverages,
  fetchDeviceTimeSeries,
  fetchSubjectMap
};
