import Vue from "vue";
import {
  keyword as schema,
  normalize as internalNormalize
} from "@/store/schema";
import { createApiUrl } from "../url";

const resourceUrl = createApiUrl("api/v1/keywords");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    joins: fetchAllJoins,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function create(keyword) {
  const { data } = await Vue.$http.post(resourceUrl, keyword);
  return normalize(data.data, schema);
}

async function update(keywordId, keyword) {
  const { data } = await Vue.$http.put(`${resourceUrl}/${keywordId}`, keyword);
  return normalize(data.data, schema);
}

async function detach(keywordId) {
  const { data } = await Vue.$http.put(`${resourceUrl}/${keywordId}/detach`);
  return normalize(data.data, schema);
}

async function setPrioritySettings({ keywordLocaleIds, priorityLevel }) {
  const url = createApiUrl("api/v1/keyword-locales");
  await Vue.$http.post(`${url}/set-priority-settings`, {
    keywordLocaleIds,
    priorityLevel
  });
}

async function deleteKeywordLocales({ keywordLocaleIds }) {
  const url = createApiUrl("api/v1/keyword-locales");
  await Vue.$http.post(`${url}/delete-keyword-locales`, {
    keywordLocaleIds
  });
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = [];
const fetchAllIncludes = ["annotations", "locales", "contentPlan"];

const fetchAllJoins = ["locales"];

export default {
  normalize,
  fetch,
  fetchAll,
  create,
  update,
  detach,
  setPrioritySettings,
  deleteKeywordLocales
};
