import { isPlainObject } from "lodash";

export const isChanged = (state, initialState) => {
  const check = createComparison(state);
  const initial = createComparison(initialState);
  return JSON.stringify(check) !== JSON.stringify(initial);
};

function createComparison(obj) {
  if (isPlainObject(obj)) {
    const comparisonObject = {};
    Object.entries(obj).forEach(([property, value]) => {
      if (value === "") {
        value = null;
      }
      comparisonObject[property] = value;
    });
    return comparisonObject;
  }
  return obj;
}
