import store from "@/store";
import { isNaN } from "lodash";

function overviewPropsFn(route) {
  return {
    create: route.name === "settings.employees.create",
    details:
      route.name !== "content.keywords.create" &&
      route.matched[0].name === "content.keywords" &&
      route.matched.length > 1
  };
}

function detailPropsFn(route) {
  let locale = parseInt(route.params.selectedLocale);
  if (isNaN(locale)) {
    locale = 3;
  }
  return {
    id: parseInt(route.params.id),
    selectedLocale: locale
  };
}

function beforeEnterFn(to, from, next) {
  store.dispatch("entities/fetchKeyword", {
    id: to.params.id,
    cb: next
  });
}

export default [
  {
    path: "/backoffice/content/keywords",
    name: "content.keywords",
    component: () =>
      import(
        /* webpackChunkName: "keywords" */ "@/views/keywords/KeywordsView"
      ),
    props: overviewPropsFn,
    meta: {
      title: "Keywords"
    },
    children: [
      {
        path: ":id",
        component: () =>
          import(
            /* webpackChunkName: "keywords" */ "@/components/keyword/KeywordDetailsDialog"
          ),
        name: "content.keywords.show",
        props: detailPropsFn,
        beforeEnter: beforeEnterFn,
        meta: {
          title: "Keywords"
        }
      }
    ]
  }
];
