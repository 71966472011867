import { FAILURE, IN_SYNC, UNINIT, UPDATING } from "../../sync-states";
import { validationRule as schema } from "@/store/schema";
import api from "@/api";

// state
export const state = {
  validationRule: {},
  validationRuleSync: UNINIT,
  validationRuleIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchValidationRuleDetails(context, { id }) {
    return await context.dispatch("fetch", {
      route,
      name,
      schema,
      id,
      includes
    });
  },
  async fetchValidationRule(context, { id, includes, cb }) {
    return await context.dispatch("fetch", {
      route,
      name,
      schema: schema,
      id: id,
      includes: includes,
      cb: cb
    });
  },
  async updateValidationRule(context, { validationRule }) {
    try {
      context.commit("setSync", {
        name: name,
        id: validationRule.id,
        sync: UPDATING
      });
      const { entities, result } = await api.validationRule.update(
        validationRule.id,
        validationRule
      );
      context.commit("updateEntities", {
        entities,
        reactiveName: "validationRule",
        reactiveId: result
      });
      context.commit("setSync", {
        name: name,
        id: validationRule.id,
        sync: IN_SYNC
      });
      return result;
    } catch (error) {
      context.commit("setSync", {
        name: name,
        id: validationRule.id,
        sync: FAILURE
      });
      throw error;
    }
  },
  async storeValidationRule(context, { validationRule, includes, cb }) {
    return await context.dispatch("store", {
      route,
      name,
      schema: schema,
      entity: validationRule,
      includes: includes,
      cb: cb
    });
  },
  async destroyValidationRule(context, { id, cb }) {
    await context.dispatch("destroy", { route, name, id: id, cb: cb });
  }
};

const name = "validationRule";
const route = "api.validation-rules.index";
const includes = [
  "rejectReason",
  "triggers.subjects",
  "triggers.countries",
  "triggers.languages",
  "triggers.triggerQuestions.question",
  "triggers.triggerQuestions.options",
  "triggers.children.subjects",
  "triggers.children.countries",
  "triggers.children.languages",
  "triggers.children.triggerQuestions.question",
  "triggers.children.triggerQuestions.options"
];
