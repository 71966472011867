export default {
  en: {
    messages: {
      _default: field => `${field} is not valid.`,
      required: field => `${field} is required.`,
      email: field => `${field} is not a valid email address.`,
      min: (field, [min]) => `${field} must have at least ${min} characters.`,
      max: (field, [max]) => `${field} can't have more than ${max} characters.`,
      between: (field, [min, max]) =>
        `${field} must be between ${min} and ${max}.`,
      numeric: field => `${field} may only contain numeric characters.`,
      telephone: field => `${field} is not a valid telephone number.`
    },
    attributes: {
      email: "Email"
    }
  },
  nl: {
    messages: {
      _default: field => `${field} is niet geldig.`,
      required: field => `${field} is verplicht.`,
      email: field => `${field} is geen geldig e-mailadres.`,
      min: (field, [min]) =>
        `${field} moet minimaal ${min} karakters bevatten.`,
      max: (field, [max]) =>
        `${field} mag niet meer dan ${max} karakters bevatten.`,
      between: (field, [min, max]) =>
        `${field} moet tussen ${min} en ${max} liggen.`,
      numeric: field => `${field} mag alleen numerieke karakters bevatten.`,
      telephone: field => `${field} is geen geldig telefoonnummer.`
    },
    attributes: {
      email: "Email"
    }
  },
  fr: {
    messages: {
      _default: field => `${field} n'est pas valide.`,
      required: field => `${field} est obligatoire.`,
      email: () => `Cette adresse email n'est pas valide.`,
      min: (field, [min]) =>
        `${field} doit contenir au moins ${min} caractères.`,
      max: (field, [max]) =>
        `${field} ne peut pas contenir plus de ${max} caractères.`,
      between: (field, [min, max]) =>
        `${field} doit être compris entre ${min} et ${max}.`,
      numeric: field =>
        `${field} peut seulement contenir des caractères numériques.`,
      telephone: () => `Ce numéro de téléphone n'est pas valide.`
    },
    attributes: {
      email: "Email"
    }
  },
  de: {
    messages: {
      _default: field => `${field} ist nicht gültig.`,
      required: field => `${field} ist erforderlich.`,
      email: () => "Diese E-Mail-Adresse ist nicht gültig.",
      min: (field, [min]) =>
        `${field} muss mindestens ${min} Zeichen enthalten.`,
      max: (field, [max]) =>
        `${field} darf nicht mehr als ${max} Zeichen enthalten.`,
      between: (field, [min, max]) =>
        `${field} muss zwischen ${min} und ${max} liegen.`,
      numeric: field => `${field} darf nur numerische Zeichen enthalten.`,
      telephone: () => `Diese Telefonnummer ist nicht gültig.`
    },
    attributes: {
      email: "Email"
    }
  }
};
