import categoryRoutes from "./category/routes";
import leadsRoutes from "./leads/routes";
import offersRoutes from "./offers/routes";
import filterRoutes from "./filters/routes";

export default [].concat(
  categoryRoutes,
  leadsRoutes,
  offersRoutes,
  filterRoutes
);
