import Vue from "vue";
import { filterLog as schema, normalize } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/logs");

async function fetchAll(companyId, { filters, pagination } = {}) {
  filters.company = companyId;
  filters.type = "FILTER";

  const url = createApiUrl(resourceUrl, {
    filters,
    includes: ["filters"],
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

export default {
  fetchAll
};
