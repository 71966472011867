import v2 from "./v2";
import auth from "./auth";
import geocode from "./geocode";
import article from "./article";
import submission from "./submission";
import lead from "./lead";
import separationRule from "./separation-rule";
import validationRule from "./validation-rule";
import deliveryRule from "./delivery-rule";
import contentPlan from "./content-plan";
import keyword from "./keyword";
import website from "./website";
import locale from "./locale";
import rejectReason from "./reject-reason";
import contactMessage from "./contact-message";
import subject from "./subject";
import employee from "./employee";
import role from "./role";
import permission from "./permission";
import tag from "./tag";
import tagSubType from "./tag-sub-type";
import tagType from "./tag-type";
import form from "./form";
import country from "./country";
import language from "./language";
import buddy from "./buddy";
import localization from "./localization";
import largeLocalization from "./large-localization";
import annotationSubType from "./annotation-sub-type";
import annotationType from "./annotation-type";
import annotation from "./annotation";
import telephoneNumber from "./telephone-number";
import offerRejectionReason from "./offer-rejection-reason";
import search from "./search";
import customer from "./customer";
import category from "./category";
import question from "./question";
import company from "./company";
import refinementRule from "./refinement-rule";
import filter from "./filter";
import offer from "./offer";
import offerField from "./offer-field";
import log from "./log";
import test from "./test";
import websitesStats from "./statistics/websites";
import articlesStats from "./statistics/articles";
import submissionsStats from "./statistics/submissions";
import subjectsStats from "./statistics/subjects";
import invoice from "./invoice";
import creditNote from "./credit-note";

export default {
  v2,
  auth,
  geocode,
  search,
  article,
  company,
  submission,
  lead,
  separationRule,
  validationRule,
  deliveryRule,
  contentPlan,
  keyword,
  customer,
  website,
  locale,
  rejectReason,
  subject,
  employee,
  role,
  permission,
  tag,
  tagSubType,
  tagType,
  form,
  contactMessage,
  country,
  language,
  buddy,
  localization,
  largeLocalization,
  telephoneNumber,
  annotationSubType,
  annotationType,
  annotation,
  offerRejectionReason,
  refinementRule,
  category,
  question,
  filter,
  offer,
  offerField,
  log,
  websitesStats,
  articlesStats,
  submissionsStats,
  subjectsStats,
  invoice,
  creditNote,
  test
};
