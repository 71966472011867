export default [
  {
    path: "/backoffice/distribution/leads",
    name: "distribution.leads",
    component: () =>
      import(
        /* webpackChunkName: "leads" */ "@/views/distribution/leads/LeadsOverview"
      ),
    meta: { title: "Leads" }
  }
];
