import Vue from "vue";
import { cloneDeep, isNil, merge } from "lodash";

export const singleSetEntities = (state, entities) => {
  entities = Object.freeze(entities);

  Vue.set(state, "entities", merge(cloneDeep(state.entities), entities));
};

export const setEntities = (state, entities, isGroupedSet = false) => {
  const t0 = performance.now();
  Object.entries(entities).forEach(([entityName, entities]) => {
    const entityState = state[entityName];
    if (!isNil(entityState)) {
      const entityGroup = {};
      Object.entries(entities).forEach(([entityId, entity]) => {
        // Retrieve the old entity, or use a blank object if there is no old entity.
        const oldEntity = cloneDeep(entityState[entityId]) || {};
        // Merge the new entity in the old entity and freeze it for performance
        // entityState[entityId] = Object.freeze(Object.assign(oldEntity, entity));
        // entityState[entityId] = Object.assign(oldEntity, entity);
        if (!isGroupedSet) {
          Vue.set(
            state[entityName],
            entityId,
            Object.assign(oldEntity, entity)
          );
        } else {
          entityGroup[entityId] = Object.assign(oldEntity, entity);
        }
      });

      if (isGroupedSet) {
        Vue.set(state, entityName, entityGroup);
      }
    }
  });
  const t1 = performance.now();
  console.debug(`Set entities in ${(t1 - t0) / 1000} seconds`);
};

export const updateEntities = (state, entities) => {
  Object.entries(entities).forEach(([entityName, entities]) => {
    const entityState = state[entityName];
    if (!isNil(entityState)) {
      Object.entries(entities).forEach(([entityId, entity]) => {
        // Retrieve the old entity, or use a blank object if there is no old entity.
        const oldEntity = cloneDeep(entityState[entityId]) || {};
        // Merge the new entity in the old entity and freeze it for performance
        // entityState[entityId] = Object.freeze(Object.assign(oldEntity, entity));
        // entityState[entityId] = Object.assign(oldEntity, entity);
        Vue.set(state[entityName], entityId, Object.assign(oldEntity, entity));
      });

      // Vue.set(state, entityName, entityState);
    }
  });
};
