import { Ripple } from "vuetify/lib/directives";

export default {
  options: {
    customProperties: true
  },
  iconfont: "fa",
  icons: {
    dropdown: "far fa-fw fa-caret-down",
    prev: "far fa-fw fa-caret-left",
    next: "far fa-fw fa-caret-right",
    clear: "far fa-fw fa-times"
  },
  theme: {
    primary: "#0653A6",
    secondary: "#2E6FB4",
    accent: "#FFCD00"
  },
  directives: {
    Ripple
  }
};
