import Vue from "vue";
import { lead as schema, normalize } from "@/store/schema";
import leadField from "./lead-field";
import offer from "./offer";
import filter from "./filter";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/leads");

async function autoclaim(leadId) {
  const url = `${resourceUrl}/${leadId}/autoclaim`;
  const { data } = await Vue.$http.put(url);
  return normalize(data.data, schema);
}

async function updateSubjects(leadId, subjects) {
  const url = `${resourceUrl}/${leadId}/subjects`;
  const { data } = await Vue.$http.put(url, {
    subjects
  });
  return normalize(data.data, schema);
}

async function remove(leadId) {
  const { status } = await Vue.$http.delete(`${resourceUrl}/${leadId}`);
  return status === 200;
}

export default {
  autoclaim,
  updateSubjects,
  remove,

  filter,
  leadField,
  offer
};
