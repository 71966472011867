import Vue from "vue";
import { normalize, submission as schema } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const submissionResourceUrl = createApiUrl("api/v1/submissions");

async function ignore(submissionId, validationRuleId) {
  const url = `${submissionResourceUrl}/${submissionId}/validations/${validationRuleId}/ignore`;
  const { data } = await Vue.$http.put(url);
  return normalize(data.data, schema);
}

async function unignore(submissionId, validationRuleId) {
  const url = `${submissionResourceUrl}/${submissionId}/validations/${validationRuleId}/unignore`;
  const { data } = await Vue.$http.put(url);
  return normalize(data.data, schema);
}

export default {
  ignore,
  unignore
};
