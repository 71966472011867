import { FETCH as DISPATCH_FETCH } from "@buldit/fe-common/src/store/templates/table/action-types";
import tableActions from "@buldit/fe-common/src/store/templates/table/actions";
import api from "@/api/incidents";
import { UPDATE_ITEM as COMMIT_UPDATE_ITEM } from "../../templates/table/mutation-types";

export default {
  ...tableActions,
  async [DISPATCH_FETCH](context) {
    return await tableActions.fetch(context, { fetchCall: api.fetchAll });
  },
  async acknowledge({ commit }, incidentId) {
    const { entities, result } = await api.acknowledge(incidentId);
    commit(COMMIT_UPDATE_ITEM, { item: result, entities });
  },
  async acknowledgeAll({ dispatch }) {
    await api.acknowledgeAll();
    dispatch(DISPATCH_FETCH);
  }
};
