import { isNil } from "lodash";
import {
  FAILURE,
  IN_SYNC,
  LOADING,
  UNINIT,
  UPDATING
} from "@/store/sync-states";
import { deliveryMethod as schema } from "@/store/schema";
import { normalize } from "normalizr";
import { createApiUrl } from "@/api/url";
import Vue from "vue";

const companyResourceUrl = createApiUrl("api/v1/companies");
const route = "api.companies.delivery-methods.index";
const name = "deliveryMethod";

// state
export const state = {
  deliveryMethod: {},
  deliveryMethodSync: UNINIT,
  deliveryMethodIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchDeliveryMethod(context, { companyId, id, includes, filters }) {
    const url = createApiUrl(
      `${companyResourceUrl}/${companyId}/delivery-methods/${id}`,
      {
        includes,
        filters
      }
    );

    try {
      context.commit("setSync", { name: name, id: id, sync: LOADING });
      const { data } = await Vue.$http.get(url);
      let normalizedData = normalize(data.data, isNil(id) ? [schema] : schema);
      context.commit("updateEntities", {
        entities: normalizedData.entities,
        reactiveName: name,
        reactiveId: normalizedData.result
      });
      context.commit("setSync", { name: name, id: id, sync: IN_SYNC });
      return normalizedData.result;
    } catch (error) {
      context.commit("setSync", { name: name, id: id, sync: FAILURE });
      console.error(error);
    }
  },
  async updateDeliveryMethod(context, { companyId, deliveryMethod, includes }) {
    const url = createApiUrl(
      `${companyResourceUrl}/${companyId}/delivery-methods/${deliveryMethod.id}`,
      { includes }
    );

    try {
      context.commit("setSync", {
        name: name,
        id: deliveryMethod.id,
        sync: UPDATING
      });
      const { data } = await Vue.$http.put(url, deliveryMethod);
      let normalizedData = normalize(data.data, schema);
      context.commit("updateEntities", { entities: normalizedData.entities });
      context.commit("setSync", {
        name: name,
        id: deliveryMethod.id,
        sync: IN_SYNC
      });
      return normalizedData.result;
    } catch (error) {
      context.commit("setSync", {
        name: name,
        id: deliveryMethod.id,
        sync: FAILURE
      });
      console.error(error);
    }
  },
  async storeDeliveryMethod(context, { companyId, deliveryMethod, includes }) {
    const url = createApiUrl(
      `${companyResourceUrl}/${companyId}/delivery-methods`,
      { includes }
    );
    try {
      const { data } = await Vue.$http.post(url, deliveryMethod);
      let normalizedData = normalize(data.data, schema);
      context.commit("updateEntities", { entities: normalizedData.entities });
      context.commit("setSync", {
        name: name,
        id: normalizedData.result,
        sync: IN_SYNC
      });
      return normalizedData.result;
    } catch (error) {
      console.error(error);
    }
  },
  async destroyDeliveryMethod(context, { id, cb }) {
    await context.dispatch("destroy", { route, name, id, cb });
  }
};
