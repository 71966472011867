import Vue from "vue";
import { isNil } from "lodash";
import {
  annotation as schema,
  normalize as internalNormalize
} from "@/store/schema";
import { createApiUrl } from "../url";
import FileSaver from "file-saver";

const resourceUrl = createApiUrl("/api/v1/annotations");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function create(annotation) {
  const progressCb = null;
  const formData = new FormData();
  if (!isNil(annotation.file)) {
    formData.append("file", annotation.file, annotation.file.name);
  }
  if (!isNil(annotation.title)) {
    formData.append("title", annotation.title);
  }
  if (!isNil(annotation.body)) {
    formData.append("body", annotation.body);
  }
  if (!isNil(annotation.date)) {
    formData.append("date", annotation.date);
  }
  if (!isNil(annotation.articles)) {
    formData.append("articles[]", annotation.articles);
  }
  if (!isNil(annotation.keywords)) {
    formData.append("keywords[]", annotation.keywords);
  }
  if (!isNil(annotation.subTypes)) {
    formData.append("subTypes[]", annotation.subTypes);
  }
  if (!isNil(annotation.subjects)) {
    formData.append("subjects[]", annotation.subjects);
  }
  if (!isNil(annotation.form)) {
    formData.append("forms[]", annotation.form);
  }
  if (!isNil(annotation.type)) {
    formData.append("type", annotation.type);
  }
  if (!isNil(annotation.websites)) {
    formData.append("websites[]", annotation.websites);
  }
  const { data } = await Vue.$http.post(resourceUrl, formData, {
    headers: { "Content-Type": "multipart/form-data" },
    onUploadProgress: progressCb
  });
  return normalize(data.data, schema);
}

async function update(annotationId, annotation) {
  const progressCb = null;
  const formData = new FormData();
  if (!isNil(annotation.file)) {
    formData.append("file", annotation.file, annotation.file.name);
  }
  if (!isNil(annotation.title)) {
    formData.append("title", annotation.title);
  }
  if (!isNil(annotation.body)) {
    formData.append("body", annotation.body);
  }
  if (!isNil(annotation.date)) {
    formData.append("date", annotation.date);
  }
  if (!isNil(annotation.articles)) {
    formData.append("articles[]", annotation.articles);
  }
  if (!isNil(annotation.keywords)) {
    formData.append("keywords[]", annotation.keywords);
  }
  if (!isNil(annotation.form)) {
    formData.append("forms[]", annotation.form);
  }
  if (!isNil(annotation.subTypes)) {
    formData.append("subTypes[]", annotation.subTypes);
  }
  if (!isNil(annotation.subjects)) {
    formData.append("subjects[]", annotation.subjects);
  }
  if (!isNil(annotation.type)) {
    formData.append("type", annotation.type);
  }
  if (!isNil(annotation.websites)) {
    formData.append("websites[]", annotation.websites);
  }
  formData.append("_method", "PUT");
  const { data } = await Vue.$http.post(
    `${resourceUrl}/${annotationId}`,
    formData,
    {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: progressCb
    }
  );
  return normalize(data.data, schema);
}

async function downloadFile(fileId) {
  await Vue.$http
    .request({
      responseType: "blob",
      url: `${resourceUrl}/download-file/${fileId}`,
      method: "get"
    })
    .then(response => {
      const matches = response.headers["content-disposition"].match(
        /attachment; filename=([^.]*).(\w*)/
      );
      /*eslint-disable*/
      const [contentDispositionHeader, fileName, extension] = matches;
      FileSaver.saveAs(response.data, `${fileName}.${extension}`);
    });

  return "success";
}

async function deleteImage(annotationId, imageId) {
  const url = `${resourceUrl}/${annotationId}/delete-image/${imageId}`;
  const { data } = await Vue.$http.put(url);
  return normalize(data, schema);
}

async function search() {
  const data = await Vue.$http.get(resourceUrl);
  return data.data.data;
}

async function remove(annotationId) {
  const { data } = await Vue.$http.delete(`${resourceUrl}/${annotationId}`);
  return normalize(data.data, schema);
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = [
  "author",
  "edit",
  "type",
  "subTypes",
  "websites",
  "articles",
  "subjects",
  "keywords",
  "files"
];
const fetchAllIncludes = [
  "author",
  "edit",
  "type",
  "subTypes",
  "websites",
  "articles",
  "subjects",
  "keywords"
];

export default {
  normalize,
  fetch,
  fetchAll,
  create,
  update,
  search,
  remove,
  downloadFile,
  deleteImage
};
