import Vue from "vue";
export default {
  updateUser(state, { notifications }) {
    state.notifications = notifications;
  },
  addNotification(state, notification) {
    state.notifications[notification.id] = notification;
  },
  removeNotification(state, id) {
    Vue.delete(state.notifications, id);
  },
  removeNotifications(state) {
    state.notifications = {};
  }
};
