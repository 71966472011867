import Vue from "vue";
import { includes, isArray, isNil, map } from "lodash";
import { normalize } from "normalizr";
import { createUrlFromRoute } from "@/store/url";
import {
  FAILURE,
  IN_SYNC,
  LOADED,
  LOADING,
  UNINIT,
  UPDATING
} from "../../sync-states";

const entityState = {};
const entityGetters = {};
const entityMutations = {};
const entityActions = {};

// Load modules dynamically.
const entityModules = require.context(".", true, /.*\.js$/);
const keys = entityModules.keys().filter(key => key !== "./index.js");

keys.forEach(key => {
  const entityFile = entityModules(key);
  Object.assign(entityState, entityFile.state);
  Object.assign(entityGetters, entityFile.getters);
  Object.assign(entityMutations, entityFile.mutations);
  Object.assign(entityActions, entityFile.actions);
});
console.debug(
  "[VUEX] Entities loaded: " + keys.map(key => key.substring(2)).join(", ")
);

export const namespaced = true;

// state
/**
 * @deprecated
 */
export const state = {
  ...entityState,
  categorySubject: {},
  categorySubjectSync: UNINIT,
  categorySubjectIndividualSync: {},
  contentPlan: {},
  contentPlanSync: UNINIT,
  contentPlanIndividualSync: {},
  subjectTopic: {},
  subjectTopicSync: UNINIT,
  subjectTopicIndividualSync: {},
  user: {},
  userSync: UNINIT,
  userIndividualSync: {},
  article: {},
  articleSync: UNINIT,
  articleIndividualSync: {}
};

// getters
/**
 * @deprecated
 */
export const getters = {
  ...entityGetters,
  isInitialized: (state, getters) => ({ name, id }) => {
    return getters.isSync({
      name: name,
      id: id,
      sync: [FAILURE, LOADING, LOADED, IN_SYNC, UPDATING]
    });
  },
  isFailure: (state, getters) => ({ name, id }) => {
    return getters.isSync({ name: name, id: id, sync: FAILURE });
  },
  isLoading: (state, getters) => ({ name, id }) => {
    return getters.isSync({ name: name, id: id, sync: LOADING });
  },
  isLoaded: (state, getters) => ({ name, id }) => {
    return getters.isSync({
      name: name,
      id: id,
      sync: [IN_SYNC, UPDATING, FAILURE]
    });
  },
  isLoadedAndIncludes: state => ({ name, id, includes = [] }) => {
    const entity = state[name][id];
    if (isNil(entity)) return false;
    return includes.every(include => {
      return !isNil(entity[include]);
    });
  },
  isInSync: (state, getters) => ({ name, id }) => {
    return getters.isSync({ name: name, id: id, sync: IN_SYNC });
  },
  isUpdating: (state, getters) => ({ name, id }) => {
    return getters.isSync({ name: name, id: id, sync: UPDATING });
  },
  isSync: state => ({ name, id, sync }) => {
    let syncState = isNil(id)
      ? state[`${name}Sync`]
      : state[`${name}IndividualSync`][id];
    if (isArray(sync)) {
      return includes(sync, syncState);
    }
    return syncState === sync;
  },
  getEntity: state => ({ name, id }) => {
    const entity = state[name][id];
    if (isNil(entity)) {
      console.warn(
        `Tried to retrieve entity ${name} with id ${id} but received ${entity}`
      );
    }
    return entity;
  },
  getEntities: (state, getters) => ({ name, ids }) => {
    return isNil(ids)
      ? Object.values(state[name])
      : map(ids, id => getters.getEntity({ name: name, id: id }));
  }
};

// mutations
/**
 * @deprecated
 */
export const mutations = {
  ...entityMutations,
  updateEntities(state, { entities }) {
    // Loop over all kinds of entities we received
    for (let entityName in entities) {
      const entityState = state[entityName];
      const entitySyncState = state[`${entityName}IndividualSync`];

      for (let entityId in entities[entityName]) {
        if (entityName !== "entity") {
          if (isNil(state[entityName])) {
            console.warn(
              `Tried to set entity ${entityId} of ${entityName}, but this entity group does not exists.`
            );
          }
          const oldObj = state[entityName][entityId] || {};
          // Merge the new data in the old object
          const newObj = Object.assign(oldObj, entities[entityName][entityId]);

          entityState[entityId] = newObj;
          entitySyncState[entityId] = IN_SYNC;
        }
      }
      Vue.set(state, entityName, { ...entityState });
      Vue.set(state, `${entityName}IndividualSync`, { ...entitySyncState });
    }
  },
  deleteEntity(state, { name, id }) {
    Vue.delete(state[name], id);
    Vue.delete(state[`${name}IndividualSync`], id);
  },
  setSync(state, { name, id, sync }) {
    if (isNil(id)) {
      if (isNil(state[`${name}Sync`])) {
        console.warn(`State ${name}Sync not defined.`);
      }
      Vue.set(state, `${name}Sync`, sync);
    } else {
      console.warn(`State ${name}Sync not defined.`);
      Vue.set(state[`${name}IndividualSync`], id, sync);
    }
  }
};

// actions
/**
 * @deprecated
 */
export const actions = {
  ...entityActions,
  async verifyArticles(context, { id }) {
    const url = `/${window.Ziggy.namedRoutes[
      "api.websites.verifyArticles"
    ].uri.replace("{websiteId}", id)}`;
    try {
      await Vue.$http.put(url);
    } catch (error) {
      console.error(error);
    }
  },
  async fetch(
    context,
    { route, name, schema, id, includes, filters, joins, pagination, cb }
  ) {
    try {
      context.commit("setSync", { name: name, id: id, sync: LOADING });
      const { data } = await Vue.$http.get(
        createUrlFromRoute(route, id, includes, filters, pagination, joins)
      );
      let normalizedData = normalize(data.data, isNil(id) ? [schema] : schema);
      context.commit("updateEntities", {
        entities: normalizedData.entities,
        reactiveName: name,
        reactiveId: normalizedData.result
      });
      // Vue.nextTick(function () {
      //     context.commit('updateEntities', { entities: normalizedData.entities, reactiveName: name, reactiveId: normalizedData.result } );
      // })
      context.commit("setSync", { name: name, id: id, sync: IN_SYNC });

      if (cb) cb();
      return normalizedData.result;
    } catch (error) {
      context.commit("setSync", { name: name, id: id, sync: FAILURE });
      console.error(error);
    }
  },
  async update(context, { route, name, schema, entity, includes, cb }) {
    try {
      context.commit("setSync", { name: name, id: entity.id, sync: UPDATING });
      const { data } = await Vue.$http.put(
        createUrlFromRoute(route, entity.id, includes),
        entity
      );
      let normalizedData = normalize(data.data, schema);
      context.commit("updateEntities", { entities: normalizedData.entities });
      context.commit("setSync", { name: name, id: entity.id, sync: IN_SYNC });
      if (cb) cb();
      return normalizedData.result;
    } catch (error) {
      context.commit("setSync", { name: name, id: entity.id, sync: FAILURE });
      throw error;
    }
  },
  async store(context, { route, schema, entity, includes, cb }) {
    //@TODO Make sync state work for store
    // context.commit('setSync', { name: entityName, id: entity.id, sync: UPDATING });
    const { data } = await Vue.$http.post(
      createUrlFromRoute(route, null, includes),
      entity
    );
    let normalizedData = normalize(data.data, schema);
    context.commit("updateEntities", { entities: normalizedData.entities });
    if (cb) cb(normalizedData.result);
    return normalizedData.result;
  },
  async destroy(context, { route, name, id, cb }) {
    try {
      //@TODO Make sync state work for delete
      context.commit("setSync", { name: name, id: id, sync: UPDATING });
      const { data } = await Vue.$http.delete(
        createUrlFromRoute(route, id, includes)
      );

      //@TODO make the commit work to delete website without page refresh
      if (data.success) {
        context.commit("deleteEntity", { name: name, id: id });
        if (cb) cb();
      }
    } catch (error) {
      context.commit("setSync", { name: name, id: id, sync: FAILURE });
      console.error(error);
    }
  }
};
