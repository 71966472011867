import store from "@/store";

function overviewPropsFn(route) {
  return {
    create: route.name === "content.content-plans.upload",
    details:
      route.name !== "content.content-plans.upload" &&
      route.matched[0].name === "content.content-plans" &&
      route.matched.length > 1
  };
}

function detailPropsFn(route) {
  return {
    id: parseInt(route.params.id)
  };
}

function beforeEnterFn(to, from, next) {
  store.dispatch("entities/fetchContentPlan", {
    id: to.params.id,
    includes: [
      "subjectTopics.keywordClusters.keywords",
      "subjectTopics.localization"
    ],
    cb: next
  });
}

export default [
  {
    path: "/backoffice/content/content-plans",
    name: "content.content-plans",
    component: () =>
      import(
        /* webpackChunkName: "content-plans" */ "@/views/content-plans/ContentPlansOverview"
      ),
    props: overviewPropsFn,
    meta: {
      title: "Content-plans"
    },
    children: [
      {
        path: "upload",
        name: "content.content-plans.upload",
        component: () =>
          import(
            /* webpackChunkName: "content-plans" */ "@/components/content-plan/ContentPlansOverviewUploader"
          ),
        props: { create: true },
        meta: {
          title: "Content-plans"
        }
      },
      {
        path: "actions",
        name: "content.content-plans.actions",
        component: () =>
          import(
            /* webpackChunkName: "content-plans" */ "@/components/content-plan/ContentPlanActions"
          ),
        props: { create: true },
        meta: {
          title: "Content-plans"
        }
      }
    ]
  },
  {
    path: "/backoffice/content/content-plans/:id",
    name: "content.content-plans.show",
    component: () =>
      import(
        /* webpackChunkName: "content-plans" */ "@/components/content-plan/ContentPlanDetails"
      ),
    props: detailPropsFn,
    beforeEnter: beforeEnterFn,
    meta: {
      title: "Content-plans"
    }
  }
];
