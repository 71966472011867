export default [
  {
    path: "/backoffice/localizations",
    name: "localizations",
    component: () =>
      import(
        /* webpackChunkName: "localizations" */ "@/views/localizations/LocalizationsView"
      ),
    meta: {
      title: "Localizations"
    }
  },
  {
    path: "/backoffice/localizations/:localizationId",
    name: "localizations.show",
    component: () =>
      import(
        /* webpackChunkName: "localizations" */ "@/views/localizations/LocalizationView"
      ),
    props: route => ({
      employeeId: parseInt(route.params.localizationId)
    }),
    meta: {
      title: "Localizations"
    }
  }
];
