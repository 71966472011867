import Vue from "vue";
import { normalize, submission as schema } from "@/store/schema";
import lead from "./lead";
import validationRule from "./validation-rule";
import submissionTrigger from "./submission-trigger";
import submissionField from "./submission-field";
import subject from "./subject";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/submissions");

async function fetch(submissionId, { includes = INCLUDES } = {}) {
  const url = createApiUrl(`${resourceUrl}/${submissionId}`, { includes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll(
  { filters, includes = ALL_INCLUDES, pagination } = {},
  $shouldNormalize = true
) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes,
    pagination
  });
  const { data } = await Vue.$http.get(url);

  if ($shouldNormalize) {
    const { entities, result } = normalize(data.data, [schema]);

    return {
      result,
      entities,
      meta: data.meta,
      links: data.links
    };
  } else {
    return data.data;
  }
}

async function geocode(submissionId, geocodeAddress) {
  const url = createApiUrl(`${resourceUrl}/${submissionId}/geocode`);
  const { data } = await Vue.$http.put(url, geocodeAddress);
  return normalize(data.data, schema);
}

async function process(submissionId) {
  const url = `${resourceUrl}/${submissionId}/process`;
  const { data } = await Vue.$http.put(url);
  return normalize(data.data, schema);
}

async function revalidate(submissionId) {
  const url = `${resourceUrl}/${submissionId}/revalidate`;
  const { data } = await Vue.$http.put(url);
  return normalize(data.data, schema);
}

/**
 * Rejects a submission. Takes a required reject reason id and optional rejectComment
 * field and boolean indicating whether this is a soft reject.
 *
 * @param {number} submissionId id of submission we're rejecting
 * @param rejectForm
 * @returns {Promise<*>}
 */
async function reject(submissionId, rejectForm) {
  const url = `${resourceUrl}/${submissionId}/reject`;
  const { data } = await Vue.$http.put(url, rejectForm);
  return normalize(data.data, schema);
}

/**
 * Cancels the rejection of a rejected submission.
 * @param {number} submissionId
 * @returns {Promise<*>}
 */
async function cancelReject(submissionId) {
  const url = `${resourceUrl}/${submissionId}/reject`;
  const { data } = await Vue.$http.delete(url);
  return normalize(data.data, schema);
}

async function updateContactDetails(
  submissionId,
  contactDetails,
  isForce = false
) {
  const url = `${resourceUrl}/${submissionId}/contact-details`;
  if (isForce) {
    contactDetails = Object.assign({ force: isForce }, contactDetails);
  }
  const { data } = await Vue.$http.put(url, contactDetails);
  return normalize(data.data, schema);
}

async function updateAddress(submissionId, address) {
  const url = `${resourceUrl}/${submissionId}/address`;
  const { data } = await Vue.$http.put(url, address);
  return normalize(data.data, schema);
}

async function resetAddress(submissionId) {
  const url = `${resourceUrl}/${submissionId}/address/reset`;
  const { data } = await Vue.$http.put(url);
  return normalize(data.data, schema);
}

async function markAsSpam(submissionId) {
  const url = `${resourceUrl}/${submissionId}/spam`;
  const { data } = await Vue.$http.delete(url);
  return data;
}

//stats
async function fetchStats({ filters }) {
  const url = createApiUrl("api/v1/submissions/stats", {
    filters: filters
  });
  const { data } = await Vue.$http.get(url);
  return data.data;
}

async function download({ filters, pagination, additionalParams }) {
  const url = createApiUrl(`${resourceUrl}/export`, {
    filters,
    pagination,
    queryParams: additionalParams
  });
  return await Vue.$http.get(url);
}

const INCLUDES = [
  "originalData",
  // THESE THREE
  "address.country",
  "address.province",
  "address.city",
  // ARE REPLACING THESE THERE
  "country",
  "province",
  "city",
  //
  "telephoneNumbers",
  "website",
  "article",
  "rejectReason",
  "form",
  "subjects",
  "originalSubjects",
  "refinementRules",
  "validationRules.rejectReason",
  // similar submissions
  "similars.leads.offers.company",
  "similars.leads.offers.subject",
  "similars.leads.subjects",
  // submission fields
  "fields.options",
  // leads
  "leads.subjects",
  "leads.categories",
  // lead fields
  "leads.fields.submissionField",
  "leads.fields.options.submissionFieldOption",
  "leads.fields.options.option",
  "leads.fields.options.inheritedOption",
  // potential offers
  "leads.potentialOffers.filter.company",
  "leads.potentialOffers.filter.filters.company",
  "leads.potentialOffers.subject",
  "leads.potentialOffers.offer",
  // offers
  "leads.offers.subject",
  "leads.offers.fields.leadField.submissionField",
  "leads.offers.fields.submissionField",
  "leads.offers.fields.options.leadFieldOption.submissionFieldOption",
  "leads.offers.fields.options.submissionFieldOption",
  "leads.offers.fields.options.option",
  "leads.offers.fields.options.inheritedOption",
  "leads.offers.rejectionReason.label",
  "leads.offers.rejectionAcceptedBy",
  "leads.offers.company",
  "leads.offers.filter",
  "leads.offers.bulditFilter",
  // buddy
  "buddy.submissions.leads",
  "buddy.submissions.leads.offers.company",
  "buddy.user",
  "buddy.address.city",
  "buddy.address.province",
  "buddy.address.country",
  "buddy.telephoneNumber"
];

const ALL_INCLUDES = [
  "form",
  "website",
  "country",
  "language",
  "subjects",
  "leads",
  "leads.subjects",
  "leads.offers.company",
  "leads.offers.subject"
];

export default {
  fetch,
  fetchAll,
  geocode,
  process,
  revalidate,
  reject,
  cancelReject,
  updateContactDetails,
  updateAddress,
  resetAddress,
  fetchStats,
  markAsSpam,
  lead,
  validationRule,
  submissionTrigger,
  submissionField,
  subject,
  export: download
};
