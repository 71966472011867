export default [
  {
    path: "/backoffice/annotation-types",
    name: "annotation-types",
    component: () =>
      import(
        /* webpackChunkName: "annotation-types" */ "@/views/annotationTypes/AnnotationTypesView"
      ),
    meta: {
      title: "Annotation Types"
    }
  },
  {
    path: "/backoffice/annotation-types/create",
    name: "annotation-types.create",
    component: () =>
      import(
        /* webpackChunkName: "annotation-types" */ "@/views/annotationTypes/AnnotationTypeCreateView"
      ),
    meta: {
      title: "Annotation Types"
    }
  },
  {
    path: "/backoffice/annotation-types/:annotationTypeId",
    name: "annotation-types.show",
    component: () =>
      import(
        /* webpackChunkName: "annotation-types" */ "@/views/annotationTypes/AnnotationTypeView"
      ),
    props: route => ({
      annotationTypeId: parseInt(route.params.annotationTypeId)
    }),
    meta: {
      title: "Annotation Type"
    }
  }
];
