import store from "@/store";
import { loadMessages } from "../plugins/i18n";

export default async (to, from, next) => {
  await loadMessages(store.getters["lang/locale"]);

  // Load our locales in memory
  // await store.dispatch('locale/' + LOCALE_ACTIONS.FETCH_LOCALES);
  // await store.dispatch('collection-subjects/fetchData');
  // await store.dispatch('language-service/READ');
  next();
};
