export default [
  {
    path: "/backoffice/distribution/offers",
    name: "distribution.offers",
    component: () =>
      import(
        /* webpackChunkName: "offers" */ "@/views/distribution/offers/OffersOverview"
      ),
    meta: { title: "offers" }
  }
];
