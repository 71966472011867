import {
  normalize as internalNormalize,
  website as schema
} from "@/store/schema";
import { createApiUrl } from "../../url";
import Vue from "vue";

async function fetchMonthlyTimeSeries(filters) {
  const url = createApiUrl(`api/v1/stats/submissions/monthly-time-series`, {
    filters
  });
  const { data } = await Vue.$http.get(url, filters);
  return data.data;
}

async function fetchFallbackTimeSeries(filters) {
  const url = createApiUrl(`api/v1/stats/submissions/fallback-time-series`, {
    filters
  });
  const { data } = await Vue.$http.get(url, filters);
  return data.data;
}

async function fetchDailyTimeSeries(filters) {
  const url = createApiUrl(`api/v1/stats/submissions/daily-time-series`, {
    filters
  });
  const { data } = await Vue.$http.get(url, filters);
  return data.data;
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

export default {
  normalize,
  fetchMonthlyTimeSeries,
  fetchDailyTimeSeries,
  fetchFallbackTimeSeries
};
