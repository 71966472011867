import { UNINIT } from "@/store/sync-states";
import { keywordLocale as schema } from "@/store/schema";

// state
export const state = {
  keywordLocale: {},
  keywordLocaleSync: UNINIT,
  keywordLocaleIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchContactPerson(context, { id, includes, filters, cb }) {
    return await context.dispatch("fetch", {
      route: "api.keywordLocales.index",
      name: "keywordLocale",
      schema,
      id,
      includes,
      filters,
      cb
    });
  },
  async updateContactPerson(context, { entity, includes, cb }) {
    return await context.dispatch("update", {
      route: "api.keywordLocales.index",
      name: "keywordLocale",
      schema,
      entity,
      includes,
      cb
    });
  },
  async storeContactPerson(context, { entity, includes, cb }) {
    return await context.dispatch("store", {
      route: "api.keywordLocales.index",
      name: "keywordLocale",
      schema,
      entity,
      includes,
      cb
    });
  },
  async destroyContactPerson(context, { id, cb }) {
    await context.dispatch("destroy", {
      route: "api.keywordLocales.index",
      name: "keywordLocale",
      id,
      cb
    });
  }
};
