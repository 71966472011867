// table.js
export const IS_LOADING = "isLoading";
export const IS_ERROR = "isError";
export const IDS = "ids";
export const ITEMS = "items";
export const ITEM = "item";
export const FIND_BY_ID = "findById";
export const FIND_BY_IDS = "findByIds";
export const FILTERS = "filters";
export const HAS_ACTIVE_FILTERS = "hasActiveFilters";
export const PAGINATION = "pagination";
