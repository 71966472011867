export default [
  {
    path: "/backoffice/contact-messages",
    name: "contact-messages",
    component: () =>
      import(
        /* webpackChunkName: "contact-message" */ "@/views/contact-messages/ContactMessagesView"
      ),
    meta: {
      title: "Contact-messages"
    }
  },
  {
    path: "/backoffice/contact-messages/:contactMessageId",
    name: "contact-messages.show",
    component: () =>
      import(
        /* webpackChunkName: "contact-message" */ "@/views/contact-messages/ContactMessageView"
      ),
    props: route => ({
      contactMessageId: parseInt(route.params.contactMessageId)
    }),
    meta: {
      title: "Contact-messages"
    }
  }
];
