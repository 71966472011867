import { isNil } from "lodash";
import Echo from "laravel-echo";
/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

const pusherKey = process.env.VUE_APP_PUSHER_APP_KEY;
const pusherCluster = process.env.VUE_APP_PUSHER_APP_CLUSTER;

if (!isNil(pusherKey)) {
  window.Pusher = require("pusher-js");

  // Enable pusher logging - don't include this in production
  window.Pusher.logToConsole = false;

  window.Echo = new Echo({
    authEndpoint: `/api/v1/broadcasting/auth`,
    broadcaster: "pusher",
    key: pusherKey,
    cluster: pusherCluster,
    auth: {
      headers: {
        Authorization: null
      }
    }
  });
}
