import { UNINIT } from "../../sync-states";

// state
export const state = {
  submissionTrigger: {},
  submissionTriggerSync: UNINIT,
  submissionTriggerIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {};
