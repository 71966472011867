import Vue from "vue";
import {
  deliveryRule as schema,
  normalize as internalNormalize
} from "@/store/schema";
import { createApiUrl } from "../url";

const resourceUrl = createApiUrl("/api/v1/delivery-rules");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function create(deliveryRule) {
  const { data } = await Vue.$http.post(resourceUrl, deliveryRule);
  return normalize(data.data, schema);
}

async function update(deliveryRuleId, deliveryRule) {
  const url = `${resourceUrl}/${deliveryRuleId}`;
  const { data } = await Vue.$http.put(url, deliveryRule);
  return normalize(data.data, schema);
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = [
  "triggerGroups.triggers.subjects",
  "triggerGroups.triggers.countries",
  "triggerGroups.triggers.languages",
  "triggerGroups.triggers.triggerQuestions.question.label",
  "triggerGroups.triggers.triggerQuestions.question.options.label",
  "triggerGroups.triggers.triggerQuestions.options"
];
const fetchAllIncludes = [];

export default {
  normalize,
  fetch,
  fetchAll,
  create,
  update
};
