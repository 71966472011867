export default [
  {
    path: "/backoffice/websites",
    name: "websites",
    // route level code-splitting
    // this generates a separate chunk (websites.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "websites" */ "@/views/websites/WebsitesView.vue"
      ),
    meta: {
      title: "Websites"
    },
    children: [
      {
        path: "create",
        name: "websites.create",
        // route level code-splitting
        // this generates a separate chunk (websites.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(
            /* webpackChunkName: "websites" */ "@/views/websites/WebsiteCreateView.vue"
          ),
        meta: {
          title: "Websites"
        }
      },
      {
        path: "/backoffice/websites/:websiteId",
        component: () =>
          import(
            /* webpackChunkName: "websites" */ "@/views/websites/WebsiteView.vue"
          ),
        meta: {
          title: "Websites"
        },
        children: [
          {
            path: "",
            name: "websites.show",
            props: route => ({ id: parseInt(route.params.websiteId) }),
            component: () =>
              import(
                /* webpackChunkName: "websites" */ "@/views/websites/WebsiteDashboardView.vue"
              ),
            meta: {
              title: "Websites"
            }
          },
          {
            path: "articles",
            name: "websites.articles",
            component: () =>
              import(
                /* webpackChunkName: "websites" */ "@/views/websites/WebsiteArticlesView.vue"
              ),
            meta: {
              title: "Websites"
            }
          },
          {
            path: "submissions",
            name: "websites.submissions",
            component: () =>
              import(
                /* webpackChunkName: "websites" */ "@/views/websites/WebsiteSubmissionsView.vue"
              ),
            meta: {
              title: "Websites"
            }
          },
          {
            path: "siteplan",
            name: "websites.siteplan",
            component: () =>
              import(
                /* webpackChunkName: "siteplan" */ "@/views/websites/WebsiteSiteplanView.vue"
              ),
            meta: {
              title: "Siteplan"
            }
          },
          {
            path: "settings",
            name: "websites.settings",
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "@/views/websites/WebsiteSettingsView.vue"
              ),
            meta: {
              title: "Settings"
            }
          },
          {
            path: "siblings",
            name: "websites.siblings",
            component: () =>
              import(
                /* webpackChunkName: "settings" */ "@/views/websites/WebsiteSiblingsView.vue"
              ),
            meta: {
              title: "Siblings"
            }
          }
        ]
      }
    ]
  }
];
