import { UNINIT } from "../../sync-states";
import { country as schema } from "@/store/schema";

// state
export const state = {
  country: {},
  countrySync: UNINIT,
  countryIndividualSync: {}
};

// getters
export const getters = {};

// mutations
export const mutations = {};

// actions
export const actions = {
  async fetchCountry(context, { id, includes, cb }) {
    await context.dispatch("fetch", {
      route: "api.countries.index",
      name: "country",
      schema: schema,
      id: id,
      includes: includes,
      cb: cb
    });
  },
  async updateCountry(context, { entity, includes, cb }) {
    await context.dispatch("update", {
      route: "api.countries.index",
      name: "country",
      schema: schema,
      entity: entity,
      includes: includes,
      cb: cb
    });
  },
  async storeCountry(context, { entity, includes, cb }) {
    await context.dispatch("store", {
      route: "api.countries.index",
      name: "country",
      schema: schema,
      entity: entity,
      includes: includes,
      cb: cb
    });
  },
  async destroyCountry(context, { id, cb }) {
    await context.dispatch("destroy", {
      route: "api.countries.index",
      name: "country",
      id: id,
      cb: cb
    });
  }
};
