export default [
  {
    path: "/backoffice/annotation-sub-types",
    name: "annotation-sub-types",
    component: () =>
      import(
        /* webpackChunkName: "annotation-sub-types" */ "@/views/annotationSubTypes/AnnotationSubTypesView"
      ),
    meta: {
      title: "Annotation sub types"
    }
  },
  {
    path: "/backoffice/annotation-sub-types/create",
    name: "annotation-sub-types.create",
    component: () =>
      import(
        /* webpackChunkName: "annotation-sub-types" */ "@/views/annotationSubTypes/AnnotationSubTypeCreateView"
      ),
    meta: {
      title: "Annotation sub types"
    }
  },
  {
    path: "/backoffice/annotation-sub-types/:annotationSubTypeId",
    name: "annotation-sub-types.show",
    component: () =>
      import(
        /* webpackChunkName: "annotation-sub-types" */ "@/views/annotationSubTypes/AnnotationSubTypeView"
      ),
    props: route => ({
      annotationSubTypeId: parseInt(route.params.annotationSubTypeId)
    }),
    meta: {
      title: "Annotation sub types"
    }
  }
];
