import Vue from "vue";
import { isNil } from "lodash";
import {
  normalize as internalNormalize,
  rejectReason as schema
} from "@/store/schema";
import { createApiUrl } from "../url";

const resourceUrl = createApiUrl("api/v1/reject-reasons");

async function fetch(id) {
  const url = createApiUrl(`${resourceUrl}/${id}`, { includes: fetchIncludes });
  const { data } = await Vue.$http.get(url);
  return normalize(data.data, schema);
}

async function fetchAll({ filters, pagination, cancelToken } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const config = {};
  if (!isNil(cancelToken)) {
    config.cancelToken = cancelToken;
  }

  const { data } = await Vue.$http.get(url, config);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function search() {
  const data = await Vue.$http.get(resourceUrl);
  return data.data.data;
}

async function create(website) {
  const { data } = await Vue.$http.post(resourceUrl, website);
  return normalize(data.data, schema);
}

async function update(rejectReasonId, rejectReason) {
  const url = `${resourceUrl}/${rejectReasonId}`;
  const { data } = await Vue.$http.put(url, rejectReason);
  return normalize(data.data, schema);
}

async function remove(rejectReasonId) {
  const url = `${resourceUrl}/${rejectReasonId}`;
  const { data } = await Vue.$http.delete(url);
  return normalize(data.data, schema);
}

//@TODO find elegant solution to have this method in every api module.
function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchIncludes = [];
const fetchAllIncludes = [];

export default {
  normalize,
  fetch,
  fetchAll,
  create,
  update,
  search,
  remove
};
