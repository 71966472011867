import Vue from "vue";
import { normalize, offer as schema } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/offers");

const fileNameRegex = /attachment; filename=([^.]*).(\w*)/;

async function fetchAll(companyId, { filters, pagination } = {}) {
  // Hardcode company filter
  filters.company = companyId;
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

async function exportLeads(
  companyId,
  { filters, pagination, additionalParams }
) {
  filters.company = companyId;
  const url = createApiUrl(`${resourceUrl}/export`, {
    filters,
    pagination,
    queryParams: additionalParams
  });
  const { data, headers } = await Vue.$http.get(url);
  const matches = headers["content-disposition"].match(fileNameRegex);
  /*eslint-disable*/
  const [contentDispositionHeader, fileName, extension] = matches;

  return {
    data: data,
    fileName: fileName,
    extension: extension
  };
}

const fetchAllIncludes = [
  "subject",
  "lead.submission",
  "lead.offers",
  "lead.offers.subject",
  "lead.offers.company"
];

export default {
  fetchAll,
  exportLeads
};
